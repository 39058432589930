import ScreenContainer from 'common/components/screenContainer/ScreenContainer';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import { useTranslation } from 'react-i18next';
import styles from './VehiclesGpsScreen.module.scss';
import { Col, Row } from 'react-flexbox-grid';
import { useEffect, useRef, useState } from 'react';
import { Circle, MapContainer, TileLayer } from 'react-leaflet';
import L, { LatLngLiteral, Marker } from 'leaflet';
import ScreenHeader from 'common/components/screenHeader/ScreenHeader';
import Box from 'common/components/box/Box';
import DrawTools, { LocationArea } from 'common/components/maps/DrawTools';
import Loading from 'common/services/Loading';
import { TrackingItemDto, TrackingPointItemDto } from 'api/tracking/models/TrackingItemDto';
import TrackingVehicleSearchCriteria from 'api/tracking/models/TrackingVehicleSearchCriteria';
import TrackingService from 'api/tracking/TrackingService';
import NoImage from 'assets/img/car.jpg';
import { LOGGER_LOG_TYPE, removeAccents } from 'Config';
import RoutingMachine from 'common/components/maps/RoutineMachine';
import { FaCircle, FaTrashAlt } from 'react-icons/fa';
import SelectController from 'common/components/select/SelectController';
import HotSpotModal from './components/hotSpotModal/HotSpotModal';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import { HotSpotDto } from 'api/hotSpots/models/HotSpotDto';
import HotSpotsService from 'api/hotSpots/HotSpotsService';
import { useForm } from 'react-hook-form';
import Logger from 'common/services/Logger';
import { useToasts } from 'react-toast-notifications';
import Button from 'common/components/button/Button';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import { UserProfile } from 'api/account/models/UserProfile';
import drawLocales, { Language } from 'leaflet-draw-locales';
import Label from 'common/components/label/Label';
import FilterList from 'common/components/filterList/FilterList';
import CurrentLocation from './components/currentLocation/CurrentLocation';
import MarkerPopup from './components/markerPopup/MarkerPopup';
import RecenterMap from './components/recenterMap/RecenterMap';
import { useHistory } from 'react-router-dom';
import VehicleGpsFiltersScreen, { Filters } from './components/filters/VehicleGpsFiltersScreen';
import ScreenHeaderButton from 'common/components/screenHeader/ScreenHeaderButton';
import Popover from 'common/components/popover/Popover';
import FiltersIcon from 'assets/svg/desktop_filtres.svg';
import colors from 'styles/export/colors.module.scss';
import AvatarWithText from 'common/components/avatar/AvatarWithText';
import ReactTooltip from 'react-tooltip';
import ZoomLevelControl from 'common/components/maps/ZoomLevelControl';
import InfiniteScroll from 'react-infinite-scroll-component';

interface Form {
    hotSpotId: string;
}



const VehiclesGpsScreen = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const [refresh, setRefresh] = useState<number>(1);
    const [area, setArea] = useState<LocationArea | null>(null);
    const [drawingArea, setDrawingArea] = useState<LocationArea | null>();
    const [points, setPoints] = useState<L.LatLng[]>([]);
    const [pointsInfo, setPointsInfo] = useState<TrackingItemDto[]>([]);
    const [pointsOnMapInfo, setPointsOnMapInfo] = useState<TrackingPointItemDto[]>([]);

    const [selectedPoint, setSelectedPoint] = useState<TrackingItemDto | null>();
    const [showSaveAreaModal, setShowSaveAreaModal] = useState(false);
    const imageCacheKey = useRef(new Date().getTime());
    const [hotSpot, setHotSpot] = useState<SelectValueLabel | null>(null);
    const [hotSpots, setHotSpots] = useState<SelectValueLabel[]>([]);
    const [currentPosition, setCurrentPosition] = useState<GeolocationPosition>();
    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const form = useForm<Form>({ shouldUnregister: false, shouldFocusError: true });
    const { setValue } = form;
    const { addToast } = useToasts();
    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
    const [itemToRemove, setItemToRemove] = useState<SelectValueLabel | null>(null);
    const [zoom, setZoom] = useState<number>(12);
    const [isDrawing, setIsDrawing] = useState<boolean>(false);
    const [loadedOnce, setLoadedOnce] = useState<boolean>(false);
    const [filters, setFilters] = useState<Filters>({});
    const [filtersTotal, setFiltersTotal] = useState(0);
    const [criteria, setCriteria] = useState<TrackingVehicleSearchCriteria>({ page: 1, itemsPerPage: 7 });
    const markers = useRef<{ marker: Marker, vehicleId: string }[]>([]);
    const [totalItems, setTotalItems] = useState(0);

    let isSearching = false;

    useEffect(() => {
        loadHotSpots(true);
        loadFilters();
        navigator.geolocation.getCurrentPosition((position: GeolocationPosition) => setCurrentPosition(position));
    }, []);

    useEffect(() => {
        if (!area && refresh === 1) {
            return;
        }
        if (area) {
            calculateZoom(area.radius * 2);
        }
        setSelectedPoint(null);
        setPointsInfo([])
        search(1).catch(console.error);
    }, [area]);

    useEffect(() => {
        if (loadedOnce) {
            if(criteria.page == 1)
                search();
            else 
                searchPage()
        }
    }, [criteria]);

    useEffect(() => {
        if (loadedOnce || points.length) {
            setRefresh(refresh + 1);
        }
    }, [points, pointsOnMapInfo]);

    useEffect(() => {
        if (hotSpot) {
            loadHotSpot();
            saveFiltersOnCache({ ...filters, ...{ hotSpotId: hotSpot.value } });
        }
    }, [hotSpot]);

    useEffect(() => {
        if (userProfile) {
            drawLocales(userProfile?.languageCode as Language);
        }
    }, [userProfile]);


    const loadHotSpots = async (loading: boolean, selectHostSpot?: HotSpotDto) => {
        const _hotSpots = await HotSpotsService.getMyHotSpots();
        setHotSpots(_hotSpots);
        if (!_hotSpots?.length) {
            return;
        }

        if (loading) {
            setLoadedOnce(true);
            const selectedHotSpot = _hotSpots.find(x => x.selected);
            if (selectedHotSpot) {
                setValue('hotSpotId', selectedHotSpot.value);
                setHotSpot(selectedHotSpot);
            }
            else {
                loadFilters();
            }
        }
        else if (selectHostSpot) {
            const h = _hotSpots.find(x => x.value == selectHostSpot.id);
            if (h) {
                setValue('hotSpotId', h.value);
                setHotSpot(h);
            }
        }
    }

    const loadHotSpot = async () => {
        try {
            Loading.show();
            if (hotSpot) {
                const _hotSpot = await HotSpotsService.getById(hotSpot.value);
                await HotSpotsService.select(_hotSpot);
                const area: LocationArea = { latitude: _hotSpot.latitude, longitude: _hotSpot.longitude, mRadius: _hotSpot.radius, radius: _hotSpot.radius };
                setArea(area);
            } else {
                setArea(null);
            }
            setRefresh(refresh + 1);
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get hotspot model list', error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        }
    }

    const search = async (page?: number) => {
        if (isSearching) {
            return;
        }
        isSearching = true;

        try {
            Loading.show();
            const _pointsInfo: TrackingItemDto[] = [];
            const _points: L.LatLng[] = [];

            const [pointsInfo,pointsInfoMap] = await Promise.all([
                TrackingService.getAggregatePositions({
                    ...criteria,
                    ...{
                        latitude: area?.latitude ?? 0,
                        longitude: area?.longitude ?? 0,
                        radius: area?.mRadius ?? 0,
                        page: page ? page : criteria.page
                    }
                }),
                TrackingService.getAggregatePositionsPoints({
                    ...criteria,
                    ...{
                        latitude: area?.latitude ?? 0,
                        longitude: area?.longitude ?? 0,
                        radius: area?.mRadius ?? 0
                    }
                }),
            ]);


            setLoadedOnce(true);
            setTotalItems(pointsInfo.totalItems)
            if (pointsInfo?.items.length) {
                pointsInfo.items.forEach(i => {
                    _points.push(L.latLng(i.latitude ?? 0, i.longitude ?? 0));
                    _pointsInfo.push(i);
                });
            }

            setPointsInfo(prev => criteria.page === 1 ? _pointsInfo : [...prev, ..._pointsInfo]);
            setPoints(_points);

            const _pointsInfoMap: TrackingPointItemDto[] = [];
            const _pointsMap: L.LatLng[] = [];

            setLoadedOnce(true);
            if (pointsInfoMap?.length) {
                pointsInfoMap.forEach(i => {
                    i.position = L.latLng(i.latitude ?? 0, i.longitude ?? 0)
                    _pointsMap.push(L.latLng(i.latitude ?? 0, i.longitude ?? 0));
                    _pointsInfoMap.push(i);
                });
            }
           
            setPointsOnMapInfo(_pointsInfoMap);
        } catch (err) {
            setLoadedOnce(true);
            Logger.error(LOGGER_LOG_TYPE.VEHICLES_GPS, `Couldn't get points info`, err);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        } finally {
            isSearching = false;
            Loading.hide();
        }
    }

    const searchPage = async (page?: number) => {
        if (isSearching) {
            return;
        }
        isSearching = true;

        try {
            Loading.show();
            const _pointsInfo: TrackingItemDto[] = [];
            const _points: L.LatLng[] = [];

            const [pointsInfo] = await Promise.all([
                TrackingService.getAggregatePositions({
                    ...criteria,
                    ...{
                        latitude: area?.latitude ?? 0,
                        longitude: area?.longitude ?? 0,
                        radius: area?.mRadius ?? 0,
                        page: page ? page : criteria.page
                    }
                })
            ]);


            setLoadedOnce(true);
            setTotalItems(pointsInfo.totalItems)
            if (pointsInfo?.items.length) {
                pointsInfo.items.forEach(i => {
                    _points.push(L.latLng(i.latitude ?? 0, i.longitude ?? 0));
                    _pointsInfo.push(i);
                });
            }

            setPointsInfo(prev => criteria.page === 1 ? _pointsInfo : [...prev, ..._pointsInfo]);
            setPoints(_points);

        } catch (err) {
            setLoadedOnce(true);
            Logger.error(LOGGER_LOG_TYPE.VEHICLES_GPS, `Couldn't get points info`, err);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        } finally {
            isSearching = false;
            Loading.hide();
        }
    }



    const showRemoveItemDialog = async (item: SelectValueLabel) => {
        setItemToRemove(item);
        setShowRemoveModal(true);
    }

    const onCancelRemove = () => {
        setItemToRemove(null);
        setShowRemoveModal(false);
    };

    const onRemove = async () => {
        if (itemToRemove === null) {
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
            return;
        }
        try {
            setShowRemoveModal(false);
            Loading.show();
            if (hotSpot) {
                const _hotSpot = await HotSpotsService.getById(hotSpot.value);
                await HotSpotsService.remove(_hotSpot);

                addToast(t('common.messages.record_delete_success'), {
                    appearance: 'success',
                });
                cleanHotSpot();

                Loading.hide();
            }
            onCancelRemove();
        }
        catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't delete companies`, error);
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
            Loading.hide();
        }
    };

    const cleanHotSpot = async () => {
        setArea(null);
        setValue('hotSpotId', null);
        setHotSpot(null);
        loadHotSpots(false);
        await HotSpotsService.deselect();
    }

    const loadIconColor = (i: number) => {
        return pointsInfo[i]?.ignition ? colors.pinIgnitionOn : colors.pinIgnitionOff
    }

    const calculateZoom = (distance?: number) => {
        if (!distance) {
            return;
        }

        let z = 15 + Math.round(Math.log2(1.5 / (distance / 1000)));

        const maxZoom = 18;
        const minZoom = 5;
        if (z > maxZoom) {
            z = maxZoom;
        } else if (z < minZoom) {
            z = minZoom;
        }

        setZoom(z);
    }


    const onCurrentLocationPress = (event: any, id?: string) => {
        if (id) {
            event.preventDefault();
            event.stopPropagation();            
            if (event.ctrlKey) {
                window.open(history.location.pathname + '/details/' + id + '/gps', '_blank');
                return;
            }
            history.push(`/vehicles/details/${id}/gps`);
        }
    }

    const GeoPositions = ({ position }: { position: TrackingItemDto }, key?: number) => {
        const { licensePlate, brandName, modelName, driverName, driverPhotoUrl, photoUrl } = position;
        
        return (
            <div className={`${styles.card} ${selectedPoint?.vehicleId == position.vehicleId ? styles.selected : undefined}`} onClick={() => {
                markers.current.find(x => x.vehicleId == position.vehicleId)?.marker.openPopup();
                setSelectedPoint(position);
            }}>
                <div className={styles.cardHeader}>
                    <div className={styles.vehicle}>
                        <div className={styles.image}>
                            <div className={styles.contentImage} style={{ width: '5rem', height: '3rem' }}>
                                <div className={styles.img}
                                    style={{ width: '5rem', height: '3rem', backgroundImage: photoUrl ? 'url(' + photoUrl ?? + '?_=' + imageCacheKey.current + ')' : 'url(' + NoImage + ')' }} />
                            </div>
                        </div>
                        <div className={styles.descriptionContainer}>
                            <div className={styles.description}>
                                <div className={styles.bold}>{licensePlate}</div>
                                <div className={styles.smallLabel}>
                                    <span>{brandName}</span>
                                    {modelName &&
                                        <>  <span>{(' | ')}</span>
                                            <span>{modelName}</span>
                                        </>
                                    }
                                </div>
                            </div>
                            {driverName && <div className={styles.driver}>
                                <div className={styles.label} data-tip={t('common.driver')}>
                                    <AvatarWithText src={driverPhotoUrl} size="x2">{driverName ?? '-'}</AvatarWithText>
                                </div>
                                <ReactTooltip id={'driver-' + key} />
                            </div>}
                        </div>
                    </div>

                </div>
                <div className={styles.cardBody} onClick={(e: any) => onCurrentLocationPress(e, position.vehicleId)}>                    
                    <CurrentLocation position={position}/>
                </div>
            </div>
        );
    };

    const loadFilters = () => {
        const cachedFilters = localStorage.getItem('VEHICLESGPSFILTER');
        if (cachedFilters != null) {
            onChangeFilters(JSON.parse(cachedFilters));
        }
        else {
            search();
        }
    }

    const saveFiltersOnCache = (f: Filters) => {
        setFilters(f);
        localStorage.setItem('VEHICLESGPSFILTER', JSON.stringify(f));
    }

    const onChangeFilters = (f: Filters) => {
        updateTotalFilters(f);
        criteria.page=1;
        criteria.registrationNumber = f.registrationNumber;
        criteria.driverId = f.driverId;
        criteria.ignition = f.ignition;

        saveFiltersOnCache(f);
        setCriteria({ ...criteria });
    }

    const updateTotalFilters = (f: Filters) => {
        let total = 0;

        if (f.registrationNumber) {
            total++;
        }
        if (f.driverId && f.driverId.length > 0) {
            total++;
        }
        if (f.ignition != undefined) {
            total++;
        }

        setFiltersTotal(total);
    }

    const onCreateArea = (area: LocationArea) => {
        setDrawingArea(area);
        setShowSaveAreaModal(true);
    }

    const onDrawStart = () => {
        setIsDrawing(true);
    }

    const onDrawStop = () => {
        setIsDrawing(false);
    }

    const onSaveArea = (hotSpot: HotSpotDto) => {
        setIsDrawing(false);
        setShowSaveAreaModal(false);
        setRefresh(refresh + 1)
        loadHotSpots(false, hotSpot);
    }

    const getCoordsToCenter = (): LatLngLiteral => {
        const coords: LatLngLiteral = { lat: 48.864716, lng: 2.349014 }; // Paris

        if (area) {
            coords.lat = area.latitude;
            coords.lng = area.longitude;
        }
        else if (selectedPoint) {
            coords.lat = selectedPoint.latitude!;
            coords.lng = selectedPoint.longitude!;
        }
        else if (points?.length) {
            coords.lat = points[0].lat;
            coords.lng = points[0].lng;
        }
        else if (currentPosition) {
            coords.lat = currentPosition.coords.latitude;
            coords.lng = currentPosition.coords.longitude;
        }

        return coords;
    }

    const onPageChange = () => {
        setCriteria(c => ({ ...c, page: c.page + 1 }));
    };

    const renderMap = () => {
        return <MapContainer maxZoom={18} minZoom={5} zoom={zoom} scrollWheelZoom={true} zoomSnap={0.1} zoomDelta={0.5} className={styles.map} tap={false}>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            {area && <Circle center={[area.latitude, area.longitude]} radius={area.mRadius} color="#6417FC"></Circle>}
            <DrawTools onCreated={onCreateArea} onDrawStart={onDrawStart} onDrawStop={onDrawStop} />
            <RecenterMap lat={getCoordsToCenter()?.lat} lng={getCoordsToCenter()?.lng} />
            <ZoomLevelControl onZoomChange={setZoom} />
            <RoutingMachine
                waypoints={pointsOnMapInfo.map(x => x.position)}
                renderInfo={(i: number) => <MarkerPopup position={pointsOnMapInfo[i]} />}
                loadIconColor={loadIconColor}
                setMarkerRef={(i: any, r: any) => {
                    markers.current[i] = { marker: r, vehicleId: pointsOnMapInfo[i].vehicleId ?? '' };
                }}
            />
        </MapContainer>
    }

    return (
        <ScreenTitle title={t('vehicles_gps.title')}>
            <ScreenContainer>
                <ScreenHeader title={t('vehicles_gps.title')}>
                    <Popover
                        contentContainerClassName={styles.filtersPopoverContainer}
                        containerStyle={{ boxShadow: '0 4px 14px rgba(0, 0, 0, 0.1)', backgroundColor: '#fff' }}
                        positions={['bottom', 'left']}
                        align={'end'}
                        onClickOutside={() => updateTotalFilters(filters)}
                        content={setIsPopoverOpen => <VehicleGpsFiltersScreen
                            filters={filters}
                            onFilter={f => { setIsPopoverOpen(false); onChangeFilters(f) }}
                            onChange={f => updateTotalFilters(f)}
                        />}>
                        {(isPopoverOpen, setIsPopoverOpen) => (
                            <ScreenHeaderButton icon={FiltersIcon} onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
                                {filtersTotal > 0 &&
                                    <div className={styles.counterList}> <div className={styles.counterNumber}>{filtersTotal}</div> </div>
                                }
                            </ScreenHeaderButton>
                        )}
                    </Popover>
                </ScreenHeader>

                <FilterList filters={[
                    {
                        value: filters.registrationNumber,
                        label: t('vehicle.registration'),
                        onRemove: () => { filters.registrationNumber = undefined; onChangeFilters(filters) }
                    },
                    {
                        value: filters.driverName,
                        label: t('vehicle.driver'),
                        onRemove: () => { filters.driverId = filters.driverName = undefined; onChangeFilters(filters) }
                    },
                    {
                        value: filters.ignition ? t('vehicle.ignition.ON') : filters.ignition == false ? t('vehicle.ignition.OFF') : '',
                        label: t('common.state'),
                        onRemove: () => { filters.ignition = undefined; onChangeFilters(filters) }
                    }
                ]} />

                <Box>
                    <Row>
                        <Col lg={12} xl={4} className={styles.colLeft}>
                            <Row>
                                <Col className={styles.selectCol}>
                                    <SelectController
                                        form={form}
                                        name="hotSpotId"
                                        placeholder={t('vehicles_gps.select_hot_spot')}
                                        isDisabled={isDrawing || showSaveAreaModal}
                                        options={hotSpots}
                                        isClearable={true}
                                        filterOption={(candidate: any, input: any) => input ? removeAccents(candidate.label).toUpperCase().includes(removeAccents(input).toUpperCase()) : true}
                                        onChangeSelect={(data: SelectValueLabel) => {
                                            if (!data?.value) {
                                                cleanHotSpot();
                                                return;
                                            }
                                            setValue('hotSpotId', data ? data.value : null);
                                            setHotSpot(data);
                                        }}
                                    />
                                </Col>
                                <Col className={styles.trashButton}>
                                    <Button size={'normal'} preset={hotSpot ? 'danger' : 'disabled'} disabled={!hotSpot} onlyIcon={true}
                                        onClick={() => showRemoveItemDialog(hotSpot!)} type='button'
                                        data-tip={t('vehicles_gps.delete_hot_spot_message')} data-for="labelToolTip"
                                    >
                                        <FaTrashAlt />
                                    </Button>
                                    {hotSpot && <ReactTooltip id="labelToolTip" />}
                                </Col>
                            </Row>
                            {
                                isDrawing || showSaveAreaModal
                                    ? <Row className={styles.emptyContainerRow}>
                                        <div className={styles.emptyContainer}>
                                            <Label className={styles.emptyLabel}>
                                                {t('vehicles_gps.drawing_message')}
                                            </Label>
                                        </div>
                                    </Row>
                                    : pointsInfo?.length
                                        ? <Row>
                                            <Col className={styles.cardsList} id="tableScroll">
                                                <Label className={styles.totalPoints}>{totalItems} {t('menu.vehicles')} </Label>
                                                <InfiniteScroll
                                                    dataLength={pointsInfo.length}
                                                    loader={null}
                                                    hasMore={pointsInfo.length < totalItems}
                                                    next={onPageChange}
                                                    scrollableTarget={'tableScroll'}>
                                                    {pointsInfo.map((info, i) => <GeoPositions key={i} position={info} />)}
                                                </InfiniteScroll>
                                            </Col>
                                        </Row>
                                        : <Row className={styles.emptyContainerRow}>
                                            <div className={styles.emptyContainer}>
                                                <div className={styles.circleButton}>
                                                    <FaCircle size={10} />
                                                </div>
                                                <Label className={styles.emptyLabel}>
                                                    {t('vehicles_gps.without_hot_spots')}
                                                </Label>
                                            </div>
                                        </Row>
                            }
                        </Col>
                        <Col lg={12} xl={8}>
                            <div className={styles.map} key={`map_${refresh}`}>
                                {renderMap()}
                            </div>
                            {drawingArea && showSaveAreaModal && <HotSpotModal show={showSaveAreaModal} latitude={drawingArea.latitude} longitude={drawingArea.longitude} radius={drawingArea.mRadius}
                                onYes={onSaveArea}
                                onNo={() => {
                                    setRefresh(refresh + 1)
                                    setIsDrawing(false);
                                    setShowSaveAreaModal(false);
                                }}
                            />}
                            <QuestionYesNo onNo={onCancelRemove} onYes={onRemove} isVisible={showRemoveModal} message={t('vehicles_gps.delete_hot_spot', { name: hotSpot?.label })} />
                        </Col>
                    </Row>
                </Box>
            </ScreenContainer>
        </ScreenTitle>
    );
}

export default VehiclesGpsScreen;
