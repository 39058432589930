import Http from '../../common/services/Http';
import { Paged } from '../common/types/Page';
import { VehicleConsumptionsResultDto, VehicleCounterListDto, VehicleDto, VehicleMileageCounterListDto } from './models/VehicleDto';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import CustomFile from 'common/models/CustomFile';
import { VehicleMonthlyCostsDto } from 'api/vehicleMonthlyCosts/models/VehicleMonthlyCostsDto';
import { VehiclesSearchCriteria } from './models/VehiclesSearchCriteria';
import { VehicleConsumptionSearchCriteria } from './models/VehicleConsumptionSearchCriteria';
import { VehicleMonthlyCostsSearchCriteria } from 'api/vehicleMonthlyCosts/models/VehicleMonthlyCostsSearchCriteria';
import { CatalogMaintenanceContractsSearchCriteria } from 'api/catalog/models/CatalogMaintenanceContractsSearchCriteria';

class VehiclesService {

    public getList(criteria: VehiclesSearchCriteria) {
        return Http.get<Paged<VehicleDto>>('vehicles', criteria);
    }

    public getExpensesTotalsReport(criteria: VehiclesSearchCriteria) {
        return Http.getWithConfig<Blob>(`vehicles/get-expenses-total-report`, criteria, { responseType: 'blob' });
    }

    public getExpensesDetailsReport(criteria: VehiclesSearchCriteria) {
        return Http.getWithConfig<Blob>(`vehicles/get-expenses-details-report`, criteria, { responseType: 'blob' });
    }

    public getVehiclesDetailsReport(criteria: VehiclesSearchCriteria) {
        return Http.getWithConfig<Blob>(`vehicles/get-vehicles-details-report`, { ...criteria, isToExcel: true }, { responseType: 'blob' });
    }

    public getVehiclesMileageReport(criteria: VehiclesSearchCriteria) {
        return Http.getWithConfig<Blob>(`vehicles/get-vehicles-mileage-report`, { ...criteria, isToExcel: true }, { responseType: 'blob' });
    }

    public catalog(vehicleId?: string) {
        return Http.get<SelectValueLabel[]>(`vehicles/catalog` + (vehicleId ? ('/' + vehicleId) : ''));
    }

    public catalogWithDeleted() {
        return Http.get<SelectValueLabel[]>(`vehicles/catalog-with-deleted`);
    }

    public catalogByCharges() {
        return Http.get<SelectValueLabel[]>(`vehicles/catalog-by-charges`);
    }

    public catalogByMaintenances() {
        return Http.get<SelectValueLabel[]>(`vehicles/catalog-by-maintenances`);
    }

    public getById(id: string) {
        return Http.get<VehicleDto>('vehicles/' + id);
    }

    public create(model: VehicleDto, photo?: CustomFile[]) {
        return Http.postWithFile<string>('vehicles', model, photo ? photo : []);
    }

    public update(model: VehicleDto, photo?: CustomFile[]) {
        return Http.putWithFile('vehicles', model, photo ? photo : []);
    }

    public remove(model: VehicleDto) {
        return Http.put('vehicles/deactivate', model);
    }

    public activate(model: VehicleDto) {
        return Http.put('vehicles/activate', model);
    }

    public getFuelList() {
        return Http.get<SelectValueLabel[]>('vehicles/getFuelList');
    }

    public getColorsList() {
        return Http.get<SelectValueLabel[]>('vehicles/getColorsList');
    }

    public getConsumptionCounters(criteria: VehicleConsumptionSearchCriteria) {
        return Http.get<VehicleConsumptionsResultDto>('vehicles/consumption-counters', criteria);
    }

    public getCountersList(criteria: VehiclesSearchCriteria) {
        return Http.get<VehicleCounterListDto>('vehicles/counters-list', criteria);
    }

    public getMileageCountersList(criteria: VehiclesSearchCriteria) {
        return Http.get<VehicleMileageCounterListDto>('vehicles/mileage-counters-list', criteria);
    }
    
    public getMonthlyCosts(criteria: VehicleMonthlyCostsSearchCriteria) {
        return Http.get<Paged<VehicleMonthlyCostsDto>>('vehicles/monthly-costs', criteria);
    }

    public getByMaintenanceContractId(criteria?: CatalogMaintenanceContractsSearchCriteria) {
        return Http.get<SelectValueLabel[]>('vehicles/get-by-maintenance-contract-id', criteria);
    }

    public getCarCurrentDriver(id: string) {
        return Http.get<SelectValueLabel>('vehicles/get-car-current-driver/' + id);
    }

    public candAddVehicles() {
        return Http.get<boolean>('vehicles/can-add-vehicles');
    }
    
    public companyHaveVehicles() {
        return Http.get<boolean | null>('vehicles/company-have-vehicles');
    }
 
}

export default new VehiclesService();