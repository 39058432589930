import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, ColumnSizeType, Row } from 'react-flexbox-grid';
import FileSaver from 'file-saver';
import { FaTimesCircle } from 'react-icons/fa';
import { blobToBase64 } from 'Config';
import styles from './FileSelector.module.scss';
import colors from 'styles/export/colors.module.scss';
import QuestionYesNo from '../questionYesNo/QuestionYesNo';
import Utils from 'common/services/Utils';
import Label from 'common/components/label/Label';
import CustomFile from 'common/models/CustomFile';
import Button from '../button/Button';
import ScreenHeaderButton from 'common/components/screenHeader/ScreenHeaderButton';
import AddIcon from 'assets/svg/desktop_add_new.svg';

type ColSize = {
    xs?: ColumnSizeType;
    sm?: ColumnSizeType;
    md?: ColumnSizeType;
    lg?: ColumnSizeType;
    xl?: ColumnSizeType,
}

type Props = {
    accept?: string;
    container?: string;
    isMulti?: boolean;
    isDetails?: boolean;
    initialFiles?: CustomFile[];
    onFilesChanged: (files: CustomFile[]) => void;
    addButtonSizes?: ColSize;
    fileButtonSizes?: ColSize;
    onRemoveFile?: (file: CustomFile) => void;
    filesListClassName?: string;
    smallButtonAdd?: boolean;
    label?: JSX.Element;
    inLine?: boolean;
    iconButton?: string;
};

const FileSelector: React.FC<Props> = ({
    accept, container, onFilesChanged, isMulti = false, initialFiles = [], isDetails = false,
    addButtonSizes = { xs: 6, sm: 4, md: 3, lg: 2 }, fileButtonSizes = { sm: 6, md: 4, lg: 3, xl: 2 }, onRemoveFile,
    filesListClassName, smallButtonAdd, label, inLine = false, iconButton, ...props
}: Props) => {
    const { t } = useTranslation();

    const [files, setFiles] = useState<CustomFile[]>([]);
    const [selectedFile, setSelectedFile] = useState<CustomFile | null>(null);
    const [selectedFileIndex, setSelectedFileIndex] = useState<number | null>(null);
    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);

    const fileRef = useRef<HTMLInputElement | null>(null);

    const importFile = () => {
        if (!fileRef.current) {
            return;
        }

        fileRef.current!.click();
    }

    const getData = async () => {
        if (initialFiles.length > 0) {
            initialFiles.map(file => { file.urlBase64 = file.url; file.key = file.id; });
            setFiles(initialFiles);
        }
    }

    const uploadFile = async (event: any) => {
        const m: CustomFile[] = [];
        for (let size = 0; size < event.target.files.length; size++) {
            const file: CustomFile = event.target.files[size];
            const fileUrl = await blobToBase64(file);
            file.urlBase64 = fileUrl;
            file.key = Utils.newGuid();
            if (container) {
                file.containerName = container;
            }
            m.push(file);
        }

        setFiles([...files, ...m]);
        onFilesChanged([...files, ...m]);
    }

    const renderWithouFile = (): JSX.Element => {
        return <div className={styles.withoutFile}>{t('common.without_file')}</div>
    }

    const renderAddFile = (): JSX.Element => {
        return <Col >
            {smallButtonAdd ?
                <div className={styles.containerSmallButton}>
                    {label && label}
                    <ScreenHeaderButton icon={iconButton ? iconButton : AddIcon} onClick={importFile} classNameIcon={styles.icon} />
                </div>
                :
                <div className={inLine ? styles.inLineButton : ''}>
                    <Button
                        onClick={importFile}
                        text={t('common.search')}
                        className={styles.addButton}
                        style={{  marginLeft: inLine ? 0 : '', maxWidth: inLine ? '6.5rem' : '' }}
                    />
                </div>}

        </Col>
    }

    const renderListFile = (file: CustomFile, index: number) => {
        return (
            <Col {...fileButtonSizes} key={`file-${index}`}>
                <div className={styles.fileButton} onClick={() => openFile(file)}>
                    <Label>{file.name}</Label>
                    {!isDetails && <FaTimesCircle size={16} className={styles.removeIcon} style={{ fill: colors.black }} onClick={e => { e.stopPropagation(); removeFile(file, index) }} />}
                </div>
            </Col>
        );
    }

    const renderSingleFile = (): JSX.Element => {
        return (
            <>
                {!files?.length && !isDetails && renderAddFile()}
                <Row className={`${styles.filesList} ${filesListClassName || ''}`}>
                    {files?.length ? renderListFile(files[0], 0) : (isDetails ? renderWithouFile() : null)}
                </Row>
            </>
        );
    }

    const renderMultipleFile = (): JSX.Element => {
        return <>
            {inLine ?
                <>
                    {!isDetails &&
                        <Col xs={12} md={3} xl={4} >
                            {!isDetails && renderAddFile()}
                        </Col>}

                    <Col xs={12} md={!isDetails ? 9 : 12} xl={!isDetails ? 8 : 12} >
                        <Row className={`${styles.filesList} ${filesListClassName || ''}`}>
                            {!files?.length && isDetails
                                ? renderWithouFile()
                                : files.map(renderListFile)
                            }
                        </Row>
                    </Col>

                </>
                :
                <>
                    {!isDetails && renderAddFile()}
                    <Row className={`${styles.filesList} ${filesListClassName || ''}`}>
                        {!files?.length && isDetails
                            ? renderWithouFile()
                            : files.map(renderListFile)
                        }
                    </Row>
                </>
            }
        </>
    }

    const openFile = (file: CustomFile) => {
        FileSaver.saveAs(file.url ?? file, file.name);
    }

    const removeFile = (file: CustomFile, index: number) => {
        setSelectedFile(file);
        setSelectedFileIndex(index);
        setShowRemoveModal(true);
    }

    const onCancelRemove = () => {
        setShowRemoveModal(false);
    }

    const onRemove = () => {
        setShowRemoveModal(false);

        if (selectedFileIndex === null || !selectedFile) { return; }

        if (onRemoveFile) { onRemoveFile(selectedFile); }
        const newFiles = [...files.filter((_, i) => i !== selectedFileIndex)];

        setFiles(newFiles);
        onFilesChanged(newFiles);
    }

    useEffect(() => {
        getData();
    }, [initialFiles]);

    return (
        <div {...props} className={styles.container}>
            {inLine ?
                <Row>
                    {isDetails && !!label && label}
                    {isMulti && renderMultipleFile()}
                    {!isMulti && renderSingleFile()}
                </Row>
                :
                <div>
                    {isDetails && !!label && label}
                    {isMulti && renderMultipleFile()}
                    {!isMulti && renderSingleFile()}
                </div>
            }

            <input type="file" id="up" ref={fileRef} hidden multiple={isMulti} name="myFile" accept={accept ?? '*'} className="multiple-upload" onChange={uploadFile} />

            <QuestionYesNo
                onNo={onCancelRemove}
                onYes={onRemove}
                isVisible={showRemoveModal}
                message={t('common.messages.remove_file')} />
        </div>
    );
}

export default FileSelector;
