import ScreenHeader from 'common/components/screenHeader/ScreenHeader';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import ScreenContainer from 'common/components/screenContainer/ScreenContainer';
import Tabs, { TabItem } from 'common/components/tabs/Tabs';
import VehicleSegmentsTab from './components/vehicleSegmentsTab/VehicleSegmentsTab';
import VehicleModelTab from './components/vehicleModelTab/VehicleModelTab';
import VehicleBrandsTab from './components/vehicleBrandsTab/VehicleBrandsTab';
import SuppliersTab from './components/suppliersTab/SuppliersTab';
import CommunicationsTab from './components/communicationsTab/CommunicationsTab';
import NotificationsTab from './components/notificationsTab/NotificationsTab';
import DamageTypesTab from './components/damageTypesTab/DamageTypesTab';
import { useHistory, useParams } from 'react-router-dom';
import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import LocalsTab from './components/localsTab/LocalsTab';

const SettingsScreen = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { tabId } = useParams<{ tabId: string }>();

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasVehicleSegmentsReadPolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_VEHICLE_SEGMENTS_READ']);
    const hasVehicleBrandsReadPolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_VEHICLE_BRANDS_READ']);
    const hasVehicleModelsReadPolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_VEHICLE_MODELS_READ']);
    const hasSuppliersReadPolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_SUPPLIERS_READ']);
    const hasLocalsReadPolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_LOCALS_READ']);
    const hasDiagnosticTypesReadPolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_DIAGNOSTIC_TYPES_READ']);
    const hasCommunicationsReadPolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_COMMUNICATIONS_READ']);
    const hasNotificationsReadPolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_NOTIFICATIONS_READ']);

    const changeTab = (_tabId: string) => {
        history.push(`/settings/${_tabId}/list`);
    }

    const defaultTab = (
        hasVehicleSegmentsReadPolicy ? 'segments' : (
            hasVehicleBrandsReadPolicy ? 'brands' : (
                hasVehicleModelsReadPolicy ? 'models' : (
                    hasSuppliersReadPolicy ? 'suppliers' :
                    hasCommunicationsReadPolicy ? 'communications' : 
                      hasLocalsReadPolicy ? 'locals' : 
                      hasNotificationsReadPolicy ? 'notifications' :
                    'damageTypes'))));
    if (!tabId) changeTab(defaultTab);

    const tabs: TabItem[] = useMemo(() => {
        const items: TabItem[] = [];
        if (hasVehicleSegmentsReadPolicy) {
            items.push({
                id: 'segments',
                title: t('settings.vehicle_segments'),
                content: <VehicleSegmentsTab />
            });
        }
        if (hasVehicleBrandsReadPolicy) {
            items.push({
                id: 'brands',
                title: t('settings.vehicle_brands'),
                content: <VehicleBrandsTab />
            });
        }
        if (hasVehicleModelsReadPolicy) {
            items.push({
                id: 'models',
                title: t('settings.vehicle_models'),
                content: <VehicleModelTab />
            });
        }
        if (hasSuppliersReadPolicy) {
            items.push({
                id: 'suppliers',
                title: t('settings.suppliers'),
                content: <SuppliersTab />
            });
        }
        if (hasLocalsReadPolicy) {
            items.push({
                id: 'locals',
                title: t('settings.locals'),
                content: <LocalsTab />
            });
        }
        if (hasDiagnosticTypesReadPolicy) {
            items.push({
                id: 'damageTypes',
                title: t('settings.diagnosis_types'),
                content: <DamageTypesTab />
            });
        }

        if (hasCommunicationsReadPolicy) {
            items.push({
                id: 'communications',
                title: t('settings.communications'),
                content: <CommunicationsTab />
            });
        }

        if (hasNotificationsReadPolicy) {
            items.push({
                id: 'notifications',
                title: t('settings.notifications'),
                content: <NotificationsTab />
            });
        }
        

        return items;
    }, []);

    return (
        <ScreenTitle title={t('settings.title')}>
            <ScreenContainer>
                <ScreenHeader title={t('settings.title')} />
                <Tabs
                    items={tabs}
                    activeTabId={tabId}
                    onChange={changeTab}
                />
            </ScreenContainer>
        </ScreenTitle>
    );
}

export default SettingsScreen;
