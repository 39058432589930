import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import ScreenContainer from 'common/components/screenContainer/ScreenContainer';
import styles from './ListMaintenancesScreen.module.scss';
import FiltersIcon from 'assets/svg/desktop_filtres.svg';
import AddIcon from 'assets/svg/desktop_add_new.svg';
import { DEFAULT_PAGINATION_ITEMS_PER_PAGE, LOGGER_LOG_TYPE } from 'Config';
import Logger from 'common/services/Logger';
import { useToasts } from 'react-toast-notifications';
import VehicleMaintenancesService from 'api/vehicleMaintenances/VehicleMaintenancesService';
import Loading from 'common/services/Loading';
import InputSearch from 'common/components/inputSearch/InputSearch';
import { useDebouncedCallback } from 'use-debounce';
import Popover from 'common/components/popover/Popover';
import { VehicleMaintenanceDto } from 'api/vehicleMaintenances/models/VehicleMaintenanceDto';
import { VehicleMaintenancesSearchCriteria } from 'api/vehicleMaintenances/models/VehicleMaintenancesSearchCriteria';
import MaintenanceFiltersScreen, { Filters } from 'screens/maintenances/components/maintenanceFilters/MaintenancesFiltersScreen';
import { useParams } from 'react-router-dom';
import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import MaintenanceListTable from './MaintenanceListTable/MaintenanceListTable';
import { Paged } from 'api/common/types/Page';
import ScreenHeaderButton from 'common/components/screenHeader/ScreenHeaderButton';

export type Props = {
    criteria?: VehicleMaintenancesSearchCriteria;
    editable: boolean,
    onClickItem: (item: VehicleMaintenanceDto, isDetails: boolean,event:any) => void;
    createNewMaintenance: () => void;
    onDeleteFromList?: () => void;
    setPage?: (page:number) => void;
    tourReRender?: () => void;
} & React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const ListMaintenancesScreen = ({ criteria: _criteria, editable, onClickItem, createNewMaintenance, onDeleteFromList, setPage, tourReRender, ...props }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();

    const [maintenances, setMaintenances] = useState<Paged<VehicleMaintenanceDto>>();

    const { vehicleId, month } = useParams<{ vehicleId: string, month: string }>();

    const [criteria, setCriteria] = useState<VehicleMaintenancesSearchCriteria>(_criteria || {
        itemsPerPage: DEFAULT_PAGINATION_ITEMS_PER_PAGE,
        page: 1,
        orderColumn: 'vm.expected_date',
        orderBy: 'desc',
        vehicleId: vehicleId,
        startDate: month ? new Date(month) : undefined,
        endDate: month ? new Date(new Date(new Date(new Date(month).setMonth(new Date(month).getMonth() + 1)).setUTCHours(0)).setUTCSeconds(-1)) : undefined
    });

    const [currentPage, setCurrentPage] = useState(1);

    const [filters, setFilters] = useState<Filters>(month ? { startDate: criteria.startDate, endDate: criteria.endDate } : {});
    const [filtersTotal, setFiltersTotal] = useState(month ? 1 : 0);

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasVehicleMaintenancesWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_VEHICLE_MAINTENANCES_WRITE']);

    const getData = async () => {
        try {
            Loading.show();
            criteria.vehicleId = vehicleId ?? criteria.vehicleId;
            const res = await VehicleMaintenancesService.getList(criteria);
            setMaintenances(res);
            Loading.hide();
        } catch (error) {
            Loading.hide();
            Logger.error(LOGGER_LOG_TYPE.MAINTENANCES, `Couldn't get maintenance list`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
        finally{
            tourReRender && tourReRender();
        }
    };

    const onTableFilter = (field: string, isFilterAsc: boolean) => {
        setCriteria({ ...criteria, page: 1, orderBy: (isFilterAsc ? 'asc' : 'desc'), orderColumn: field });
    }

    const onPageChange = (page?: number) => {
        setCurrentPage(page ?? currentPage);
        setCriteria({ ...criteria, page: page ?? currentPage });
        if(setPage){setPage(page ?? 1)}
    }

    const debounced = useDebouncedCallback((value: string) => {
        setCriteria({ ...criteria, page: 1, allIn: value });
    }, 500);

    const onChangeFilters = (f: Filters) => {
        updateTotalFilters(f);
        setFilters(f);
        criteria.startDate = f.startDate;
        criteria.endDate = f.endDate;
        criteria.vehicleId = f.vehicleId;
        criteria.state = f.currentState;
        criteria.supplierId = f.supplierId;
        criteria.responsibleId = f.responsibleId;
        criteria.typeId = f.typeId;

        if (currentPage > 1) {
            setCurrentPage(1);
        }
        else {
            getData();
        }
    }

    const updateTotalFilters = (f: Filters) => {
        let total = 0;

        if (f.startDate) {
            total++;
        }
        if (f.endDate) {
            total++;
        }
        if (f.currentState) {
            total++;
        }
        if (f.typeId) {
            total++;
        }
        if (f.supplierId) {
            total++;
        }
        if (f.vehicleId) {
            total++;
        }
        if (f.responsibleId) {
            total++;
        }

        setFiltersTotal(total);
    }

    useEffect(() => {
        if (_criteria) {
            setCriteria(_criteria);
        }
    }, [_criteria]);

    useEffect(() => {
        getData();
    }, [currentPage, criteria]);

    return (
        <ScreenTitle title={t('maintenances.title')} {...props}>
            <ScreenContainer>
                {vehicleId &&
                    <div className={styles.tabHeader}>
                        <div>
                            <InputSearch onChangeValue={debounced} placeholder={t('common.search')}></InputSearch>
                        </div>
                        <div className={styles.rowFlex}>
                            <Popover
                                contentContainerClassName={styles.filtersPopoverContainer}
                                containerStyle={{ boxShadow: '0 4px 14px rgba(0, 0, 0, 0.1)', backgroundColor: '#fff' }}
                                positions={['left']}
                                onClickOutside={() => updateTotalFilters(filters)}
                                content={setIsPopoverOpen =>
                                    <MaintenanceFiltersScreen
                                        filters={filters}
                                        onFilter={_filters => { setIsPopoverOpen(false); onChangeFilters(_filters) }}
                                        onChange={_filters => updateTotalFilters(_filters)}
                                    />
                                }
                            >
                                {(isPopoverOpen, setIsPopoverOpen) => (
                                    <ScreenHeaderButton icon={FiltersIcon} onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
                                        {filtersTotal > 0 &&
                                            <div className={styles.counterList}> <div className={styles.counterNumber}>{filtersTotal}</div> </div>
                                        }
                                    </ScreenHeaderButton>
                                )}

                            </Popover>
                            {hasVehicleMaintenancesWritePolicy && editable && <ScreenHeaderButton icon={AddIcon} onClick={createNewMaintenance} />}
                        </div>
                    </div>
                }

                {maintenances && <MaintenanceListTable
                    data={maintenances}
                    vehicleId={vehicleId}
                    editable={editable}
                    onPageChange={onPageChange}
                    onTableFilter={onTableFilter}
                    onClickItem={onClickItem}
                    onDeleteFromList={onDeleteFromList}
                />}
            </ScreenContainer>
        </ScreenTitle>
    );
}

export default ListMaintenancesScreen;
