import Box from '../../../../common/components/box/Box';
import ScreenTitle from '../../../../common/components/screenTitle/ScreenTitle';
import Logger from '../../../../common/services/Logger';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import styles from './VehiclesTab.module.scss';
import toggleSwitchStyle from 'common/components/toggleSwitch/ToggleSwitch.module.scss'
import Loading from '../../../../common/services/Loading';
import { DEFAULT_PAGINATION_ITEMS_PER_PAGE, LOGGER_LOG_TYPE } from '../../../../Config';
import InputSearch from 'common/components/inputSearch/InputSearch';
import ListingTable, { ListingTableColumn } from 'common/components/listingTable/ListingTable';
import { VehicleAlarmListItemDto } from 'api/vehicles/models/VehicleDto';
import NoImage from 'assets/img/car.jpg';
import { AlarmSettingVehicleDto } from 'api/alarms/models/AlarmSettingDto';
import AlarmsService from 'api/alarms/AlarmsService';
import { useParams } from 'react-router-dom';
import { AlarmVehiclesSearchCriteria } from 'api/alarms/models/AlarmsSearchCriteria';
import { useDebouncedCallback } from 'use-debounce/lib';
import PaginationWithInfo from 'common/components/pagination/PaginationWithInfo';
import AvatarWithText from 'common/components/avatar/AvatarWithText';

type Props = {
    vehiclesSelected: AlarmSettingVehicleDto[];
    onSelectAllVehicles: (ids: AlarmSettingVehicleDto[]) => void
    onSelectVehicle: (ids: string, value: boolean) => void;
    allVehiclesSelected: boolean,
    setAllVehiclesSelected: (value: boolean) => void;
}

const VehiclesTab = ({ vehiclesSelected, onSelectAllVehicles, onSelectVehicle, allVehiclesSelected, setAllVehiclesSelected }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();

    const { type, alarmId } = useParams<{ type: string, alarmId: string }>();
    const isDetails = type === 'details';
    const [criteria, setCriteria] = useState<AlarmVehiclesSearchCriteria>({
        itemsPerPage: DEFAULT_PAGINATION_ITEMS_PER_PAGE,
        page: 1,
        orderColumn: 'v.registration_number',
        orderBy: 'asc',
        hasGps: true,
        alarmId: alarmId
    });
    const [totalItems, setTotalItems] = useState<number>(0);
    const [vehicles, setVehicles] = useState<VehicleAlarmListItemDto[]>([]);
    const imageCacheKey = useRef(new Date().getTime());
    const [allVehiclesIds, setAllVehiclesIds] = useState<string[]>([]);


    useEffect(() => {
        getData();
    }, [criteria]);

    useEffect(() => {
        setAllVehiclesSelected(totalItems == vehiclesSelected.length)
    }, [vehiclesSelected]);

    useEffect(() => {
        try {
            Loading.show();
            AlarmsService.getCompanyVehiclesList().then(res => {
                setAllVehiclesIds(res);
            })
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.ALARMS, `Couldn't get all cars ids for alarm page`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        } finally {
            Loading.hide();
        }

    }, []);

    const getData = async () => {
        try {
            Loading.show();
            const result = await AlarmsService.getVehiclesList(criteria);
            setTotalItems(result.totalItems)
            setAllVehiclesSelected(result.totalItems == vehiclesSelected.length)

            const updatedVehicles = result.items.map(x => {
                const isSelected = vehiclesSelected?.find(selectedVehicle => selectedVehicle.vehicleId === x.trackingDeviceId) != null;
                return { ...x, active: isSelected };
            });

            setVehicles(updatedVehicles);
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.ALARMS, `Couldn't get information to create alarm`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        } finally {
            Loading.hide();
        }
    }

    const renderVehicleCell = (row: VehicleAlarmListItemDto) => {
        return (<div style={{ display: 'flex', flexDirection: 'row' }}>
            <div>
                <div className={styles.contentImage} style={{ width: '5rem', height: '3rem' }}>
                    <div className={styles.img} style={{ width: '5rem', height: '3rem', backgroundImage: row?.photoUrl ? 'url(' + row?.photoUrl + '?_=' + imageCacheKey.current + ')' : 'url(' + NoImage + ')' }} />
                </div>
            </div>
            <div >
                <div className={styles.columnPlate}>{row.registrationNumber}</div>
                <div className={styles.columnBrand}>{row.vehicleBrandName + (' | ') + row.vehicleModelName}</div>
            </div>
        </div>
        )
    }

    const onCheckVehicle = async (vehicleId: string, isToAdd?: boolean) => {
        onSelectVehicle(vehicleId, isToAdd ?? false);
    };

    const onTableFilter = (field: string, isFilterAsc: boolean) => {
        setCriteria({ ...criteria, page: 1, orderBy: (isFilterAsc ? 'asc' : 'desc'), orderColumn: field });
    }

    const tableColumns: ListingTableColumn<VehicleAlarmListItemDto>[] = [
            {
                name: t('alarms.vehicles_tab.vehicle'),
                onSearch: onTableFilter,
                searchField: 'v.registration_number',
                renderCell: renderVehicleCell,
                width: 'fill'
            },
            {
                name: t('alarms.vehicles_tab.responsible'),
                renderCell: row => <div>
                    <AvatarWithText src={row.driverPhotoUrl}
                        size="x2"
                        hideAvatar={!row.driverName}><div className={styles.bold}>{row.driverName || '-'}</div></AvatarWithText>                   
                </div>,
                width: 'fill',
                onSearch: onTableFilter,
                hideOn: ['sm'],
                searchField: 'driverName'
            },
            {
                name: t('alarms.vehicles_tab.notifications'),
                renderCell: row => <div>{row.notificationsTotal}</div>,
                width: 'fill'
            },
            {
                name: t('alarms.vehicles_tab.active'),
                renderCell: row => <div key={'active_' + row.trackingDeviceId}>
                    <label
                        style={{ cursor: isDetails ? 'no-drop' : 'pointer' }}
                        className={`${toggleSwitchStyle.toggleSwitch} ${toggleSwitchStyle.enabled}`}>
                        <input
                            type='checkbox'
                            id={`checkbox_${row.trackingDeviceId}_${row.active}`}
                            checked={row.active}
                            onChange={e => {
                                onCheckVehicle(row.trackingDeviceId, e.target.checked);
                                row.active = !row.active;
                            }}
                            disabled={isDetails}
                        />
                        <span className={toggleSwitchStyle.switch} />
                    </label>
                </div>,
                width: 'fill'
            }
        ];
    


    const debounced = useDebouncedCallback((value: string) => {
        setCriteria({ ...criteria, allIn: value, page: 1 })
    }, 500);

    const onPageChange = (page: number) => {
        criteria.page = page;
        getData();
    }

    const onAllSelectCheck = (value: boolean) => {
        setAllVehiclesSelected(value)
        if (value) {
            setVehicles(vehicles.map(x => ({ ...x, active: true })))
            onSelectAllVehicles(allVehiclesIds.map(x => { return ({ vehicleId: x }) }))
        } else {
            setVehicles(vehicles.map(x => ({ ...x, active: false })))
            onSelectAllVehicles([])
        }
    }

    return (
        <ScreenTitle title={t('alarms.vehicles_tab.title')}>
            <Box>
                <div className={styles.tabHeader}>
                    <div>                     
                        <InputSearch
                            onChangeValue={debounced}
                            defaultValue={criteria.allIn}
                            placeholder={t('alarms.vehicles_tab.search')}
                            onKeyDown={(e: any) => {                                
                                if (e.keyCode === 13) {
                                    e.preventDefault();
                                }
                            }}
                        ></InputSearch>
                    </div>

                </div>
                <div className={styles.flexRow}>
                    <div>{t('alarms.vehicles_tab.select_all_vehicles')}</div>
                    <label
                        style={{ cursor: isDetails ? 'no-drop' : 'pointer' }}
                        className={`${toggleSwitchStyle.toggleSwitch} ${toggleSwitchStyle.enabled}`}>
                        <input
                            type='checkbox'
                            checked={allVehiclesSelected}
                            onChange={e => {
                                onAllSelectCheck(!allVehiclesSelected)
                            }}
                            disabled={isDetails}
                        />
                        <span className={toggleSwitchStyle.switch} />
                    </label>
                </div>
                <ListingTable
                    columns={tableColumns}
                    rows={vehicles}
                    allowHover={true}
                    initialSearch={{ colField: 'v.registration_number', isOrderAsc: true }}
                />
                <PaginationWithInfo
                    itemName={t('alarms.list.alarms')}
                    currentPage={criteria.page}
                    pageItems={vehicles.length}
                    totalItems={totalItems}
                    onChange={onPageChange}
                />
            </Box>
        </ScreenTitle >

    );
};

export default VehiclesTab;