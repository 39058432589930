import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ListingTable, { ListingTableColumn } from 'common/components/listingTable/ListingTable';
import PaginationWithInfo from 'common/components/pagination/PaginationWithInfo';
import { DEFAULT_PAGINATION_ITEMS_PER_PAGE, LOGGER_LOG_TYPE } from 'Config';
import { useToasts } from 'react-toast-notifications';
import { SupplierDto } from 'api/suppliers/models/SupplierDto';
import { SupplierSearchCriteria } from 'api/suppliers/models/SupplierSearchCriteria';
import Loading from 'common/services/Loading';
import SuppliersService from 'api/suppliers/SuppliersService';
import Logger from 'common/services/Logger';
import { Paged } from 'api/common/types/Page';
import styles from './SuppliersList.module.scss';
import InputSearch from 'common/components/inputSearch/InputSearch';
import { useDebouncedCallback } from 'use-debounce';
import Dropdown from 'common/components/dropdown/Dropdown';
import DropdownItem from 'common/components/dropdown/DropdownItem';
import { FaEllipsisH } from 'react-icons/fa';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import Popover from 'common/components/popover/Popover';
import SuppliersFiltersScreen, { Filters } from '../suppliersFilters/SuppliersFiltersScreen';
import { useHistory } from 'react-router-dom';
import NoImage from 'assets/svg/semfoto.svg';
import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import ScreenHeaderButton from 'common/components/screenHeader/ScreenHeaderButton';
import FiltersIcon from 'assets/svg/desktop_filtres.svg';
import AddIcon from 'assets/svg/desktop_add_new.svg';

type Props = {
};

const SuppliersList: React.FC<Props> = ({ }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();

    const history = useHistory();

    const [suppliersPage, setSuppliersPage] = useState<Paged<SupplierDto>>();
    const [criteria, setCriteria] = useState<SupplierSearchCriteria>({
        itemsPerPage: DEFAULT_PAGINATION_ITEMS_PER_PAGE,
        page: 1,
        orderColumn: 'name',
        orderBy: 'asc'
    });

    const imageCacheKey = useRef(new Date().getTime());

    const [itemToRemove, setItemToRemove] = useState<SupplierDto | null>(null);
    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);

    const [filters, setFilters] = useState<Filters>({});
    const [filtersTotal, setFiltersTotal] = useState(0);

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasSuppliersWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_SUPPLIERS_WRITE']);

    const showRemoveItemDialog = (item: SupplierDto) => {
        setItemToRemove(item);
        setShowRemoveModal(true);
    };

    const renderTableActionCell = (
        row: SupplierDto,
    ) => {
        return (
            <Dropdown
                options={
                    <>
                        <DropdownItem url={`/settings/suppliers/details/${row.id ?? ''}`}>
                            {t('common.details')}
                        </DropdownItem>
                        {hasSuppliersWritePolicy && <DropdownItem url={`/settings/suppliers/edit/${row.id ?? ''}`}>
                            {t('common.edit')}
                        </DropdownItem>}
                        {hasSuppliersWritePolicy && <DropdownItem onClick={() => showRemoveItemDialog(row)}>
                            {t('common.remove')}
                        </DropdownItem>}
                    </>
                }
            >
                <div>
                    <FaEllipsisH />
                </div>
            </Dropdown>
        );
    };

    const onTableFilter = (field: string, isFilterAsc: boolean) => {
        setCriteria({ ...criteria, page: 1, orderBy: (isFilterAsc ? 'asc' : 'desc'), orderColumn: field });
    }


    const renderImageDescCell = (row: SupplierDto) => {
        return (
            <div className={styles.imageDescCell}>
                <div className={styles.contentImage} style={{ width: 100, height: 80 }}>
                    <div className={styles.img} style={{ width: 100, height: 80, backgroundImage: row?.logoUrl ? 'url(' + row?.logoUrl + '?_=' + imageCacheKey.current + ')' : 'url(' + NoImage + ')' }} />
                </div>
                <div className={styles.descCell}>
                    {row.name}
                </div>
            </div>
        )
    }

    const tableColumns: ListingTableColumn<SupplierDto>[] = [
        {
            field: 'name',
            name: t('vehicle_segments.list.name'),
            onSearch: onTableFilter,
            searchField: 's.name',
            renderCell: renderImageDescCell
        },
        {
            name: '',
            width: '60px',
            cellAlignment: 'right',
            preventClick: true,
            renderCell: renderTableActionCell,
            cellStyle: { overflow: 'unset' },
        }
    ];

    const getData = async () => {
        try {
            Loading.show();

            const page = await SuppliersService.getList(criteria);
            setSuppliersPage(page);

            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't get vehicle segments list`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    };

    const onCancelRemove = () => {
        setItemToRemove(null);
        setShowRemoveModal(false);
    };

    const onRemove = async () => {
        if (itemToRemove === null) {
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
            return;
        }

        try {
            await SuppliersService.remove(itemToRemove);
            setCriteria({ ...criteria, page: 1 });
            onCancelRemove();
            addToast(t('common.messages.record_delete_success'), {
                appearance: 'success',
            });
        }
        catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't delete supplier`, error);
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
        }
    };

    useEffect(() => {
        getData();
    }, [criteria]);

    const debounced = useDebouncedCallback((value: string) => {
        setCriteria({ ...criteria, page: 1, allIn: value });
    }, 500);

    const onChangeFilters = (filters: Filters) => {
        updateTotalFilters(filters);
        setFilters(filters);
        criteria.typeId = filters.typeId;
        getData();
    }

    const updateTotalFilters = (filters: Filters) => {
        let total = 0;

        if (filters.typeId && filters.typeId.length > 0) {
            total++;
        }

        setFiltersTotal(total);
    }

    return (
        <div className='list'>
            <div className={styles.tabHeader}>
                <div>
                    <InputSearch onChangeValue={debounced} placeholder={t('common.search')} ></InputSearch>
                </div>
                <div className={styles.rowFlex}>
                    <Popover
                        contentContainerClassName={styles.filtersPopoverContainer}
                        containerStyle={{ boxShadow: '0 4px 14px rgba(0, 0, 0, 0.1)', backgroundColor: '#fff' }}
                        positions={['bottom', 'left']}
                        align={'end'}
                        onClickOutside={() => updateTotalFilters(filters)}
                        content={setIsPopoverOpen => <SuppliersFiltersScreen
                            filters={filters}
                            onFilter={filters => { setIsPopoverOpen(false); onChangeFilters(filters) }}
                            onChange={filters => updateTotalFilters(filters)}
                        />}>
                        {(isPopoverOpen, setIsPopoverOpen) => (
                            <ScreenHeaderButton icon={FiltersIcon} onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
                                {filtersTotal > 0 &&
                                    <div className={styles.counterList}> <div className={styles.counterNumber}>{filtersTotal}</div> </div>
                                }
                            </ScreenHeaderButton>
                        )}
                    </Popover>
                    {hasSuppliersWritePolicy && <ScreenHeaderButton icon={AddIcon} onClick={() => history.push(`/settings/suppliers/new`)} />}
                </div>
            </div>
            <ListingTable
                columns={tableColumns}
                rows={suppliersPage?.items || []}
                onRowClick={row => history.push(`/settings/suppliers/details/${row.id ?? ''}`)}
                onHref={row => `/settings/suppliers/details/${row.id ?? ''}`}
                allowHover={true}
                initialSearch={{ colField: 's.name', isOrderAsc: true }}
            />
            <PaginationWithInfo
                itemName={t('suppliers.list.suppliers')}
                currentPage={suppliersPage?.currentPage || 1}
                pageItems={suppliersPage?.items.length || 0}
                totalItems={suppliersPage?.totalItems || 0}
                onChange={page => setCriteria({ ...criteria, page })}
            />
            <QuestionYesNo onNo={onCancelRemove} onYes={onRemove} isVisible={showRemoveModal} message={t('common.messages.remove_supplier', { supplier: itemToRemove?.name ?? '' })}/>
        </div>
    );
};

export default SuppliersList;
