import AccidentsService from 'api/accidents/AccidentsService';
import { AccidentDto } from 'api/accidents/models/AccidentDto';
import { CatalogDriversSearchCriteria } from 'api/catalog/models/CatalogDriversSearchCriteria';
import { CatalogInsuranceContractsSearchCriteria } from 'api/catalog/models/CatalogInsuranceContractsSearchCriteria';
import InsuranceContractsService from 'api/contracts/insuranceContract/InsuranceContractsService';
import DriversService from 'api/drivers/DriversService';
import IconBack from 'assets/svg/Arrow-grey.svg';
import Button from 'common/components/button/Button';
import FormItem from 'common/components/formItem/FormItem';
import InputController from 'common/components/input/InputController';
import InputError from 'common/components/inputError/InputError';
import InputGroup from 'common/components/inputGroup/InputGroup';
import Label from 'common/components/label/Label';
import FileSelector from 'common/components/fileSelector/FileSelector';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import SelectController from 'common/components/select/SelectController';
import InputResizable from 'common/components/input/InputResizable';
import DateWithHour from 'common/components/dateWithHour/DateWithHour';
import CustomFile from 'common/models/CustomFile';
import Loading from 'common/services/Loading';
import Logger from 'common/services/Logger';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import { BLAMING_COLORS, DATE_FORMAT_DEFAUT, DEFAULT_INPUT_RULES_WITH_REQUIRED, LOGGER_LOG_TYPE } from 'Config';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaCalendarAlt } from 'react-icons/fa';
import { useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import styles from './AccidentScreen.module.scss';
import Utils from 'common/services/Utils';
import { Types } from 'screens/vehicle/VehicleScreen';
import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';

export type Props = {
    isDetails: boolean;
    onBackList: () => void;
    onItemDetails: (state: boolean) => void;
    isDriver?: boolean;
    isVehicle?: boolean;
    editable: boolean;
} & React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const AccidentScreen = ({ isDetails, editable, onBackList, onItemDetails, isDriver, isVehicle, ...props }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();
    // eslint-disable-next-line
    let { vehicleId, type, tabId, itemId, itemType } = useParams<{ vehicleId: string, type: Types, tabId: string, itemType: string, itemId: string }>();
    const history = useHistory();

    const form = useForm<AccidentDto>({ shouldUnregister: false });
    const { handleSubmit, watch, setValue, getValues, control, errors, reset } = form;

    const [itemToRemove, setItemToRemove] = useState<AccidentDto>();
    const [dialogDeleteItemIsOpen, setDialogDeleteItemIsOpen] = React.useState(false);
    const [accident, setAccident] = useState<AccidentDto | null>(null);

    const [contractNumbersOptions, setContractNumbersOptions] = useState<SelectValueLabel[]>([]);
    const [driversOptions, setDriversOptions] = useState<SelectValueLabel[]>([]);

    const [savedFiles, setSavedFiles] = useState<CustomFile[]>([]);

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasAccidentsWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_ACCIDENTS_WRITE']);

    const getData = async (id?: string) => {
        setValue('attachmentsToRemove', []);
        try {
            Loading.show();
            if (isVehicle && Utils.isStringNullOrEmpty(vehicleId)) {
                throw Error('vehicleId doesnt exist');
            }
            setValue('vehicleId', vehicleId);

            if (itemId) {
                const result = await AccidentsService.getById(itemId);
                if (result === undefined) {
                    addToast(t('common.messages.error_load_info'), { appearance: 'error' });
                    Logger.error(LOGGER_LOG_TYPE.ACCIDENTS, `Couldn't get the accident with id: ${itemId}`);
                    onBackList();
                }
                setValues(result);
            } else {
                setValue('guilty', false);
                getInsuranceContractsByDate(itemType === 'details');
                getDrivers(itemType === 'details');
            }
            Loading.hide();

        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.ACCIDENTS, `Couldn't get information to create accident`, error);
            Loading.hide();
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        }
    }

    const getDrivers = async (isDetails: boolean, id?: string | null) => {
        try {
            Loading.show();

            const drivers = await DriversService.catalog({ id: (isDetails ? id : null) } as CatalogDriversSearchCriteria);
            if (id) {
                const exists = drivers.find(x => x.value === id);
                if (!exists) { setValue('driverId', null); }
            }
            setDriversOptions(drivers);

            Loading.hide();

        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.ACCIDENTS, `Couldn't get drivers`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        }
    }

    const getInsuranceContractsByDate = async (isDetails: boolean, date?: Date, id?: string | null) => {
        try {
            Loading.show();

            const crit = isDetails ? { id: id } : { vehicleId: vehicleId, endDate: date };
            const contractNumbers = await InsuranceContractsService.catalog(crit as CatalogInsuranceContractsSearchCriteria);
            if (id) {
                const exists = contractNumbers.find(x => x.value === id);
                if (!exists) { setValue('insuranceContractId', null); }
            }
            setContractNumbersOptions(contractNumbers);

            Loading.hide();

        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.ACCIDENTS, `Couldn't get insurance contracts`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        }
    }

    const setValues = (values: AccidentDto) => {
        setValue('id', values.id);

        setValue('vehicleId', values.vehicleId);
        const driverId = values.driverId;
        setValue('driverId', driverId);
        getDrivers(itemType === 'details', driverId);

        const date = new Date(values.date);
        setValue('date', date);
        const insuranceContractId = values.insuranceContractId;
        setValue('insuranceContractId', insuranceContractId);
        getInsuranceContractsByDate(itemType === 'details', date, insuranceContractId);

        setValue('location', values.location);
        setValue('guilty', values.guilty);
        setValue('hour', values.hour);
        setValue('notes', values.notes);

        setValuesFiles(values.attachments);

        setAccident(values);
    }

    const onSubmit = async (form: AccidentDto) => {
        try {
            if (!form.insuranceContractId || !form.location || !form.date) {
                addToast(t('common.messages.complete_required_fields'), { appearance: 'warning' });
                return;
            }

            Loading.show();

            prepareFilesToSubmit();

            if (form.id) {
                await AccidentsService.update(form, form.attachments ?? []);
            } else {
                itemId = await AccidentsService.create(form, form.attachments ?? []);
            }

            Loading.hide();
            addToast(t('common.messages.record_save_success'), { appearance: 'success' });

            onBackList();
        } catch (error) {
            addToast(t('common.messages.record_save_error'), { appearance: 'error' });
            Logger.error(LOGGER_LOG_TYPE.ACCIDENTS, `Couldn't update the accident with id: ${form.id}`, error);
            arrangeFilesAfterSubmit();
            Loading.hide();
        }
    }

    const arrangeFilesAfterSubmit = () => {
        setValuesFiles(savedFiles);
    }

    const prepareFilesToSubmit = () => {
        setValuesFiles(getValues('attachments')?.filter(x => !x.id));
    }

    const setValuesFiles = (files: CustomFile[]) => {
        setValue('attachments', files);
        setSavedFiles(files?.filter(x => x.id));
    }

    const onRemoveFile = (file: CustomFile) => {
        if (file.id) {
            const arr = [...(getValues('attachmentsToRemove') || []), file.id];
            setValue('attachmentsToRemove', arr);
        }
    }

    const showRemoveItemDialog = (item: AccidentDto) => {
        setItemToRemove(item);
        setDialogDeleteItemIsOpen(true);
    };

    const removeItem = async () => {
        setDialogDeleteItemIsOpen(false);
        if (!!itemToRemove) {
            try {
                Loading.show();
                await AccidentsService.remove(itemToRemove);
                addToast(t('common.messages.record_delete_success'), { appearance: 'success' });
                onBackList();
                Loading.hide();
            } catch (error) {
                addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
                Loading.hide();
            }
        }
    };

    const renderBlaming = (onChange: (...event: any[]) => void, valueController: boolean, toSetValue: boolean, text: string) => {
        return <div onClick={() => (Utils.isStringNullOrEmpty(itemType) ? type !== 'details' : itemType !== 'details') ? onChange(toSetValue) : null}
            className={`${(valueController === false && !toSetValue) || (valueController === true && toSetValue) ? styles.selected : styles.notSelected}`}
            style={{ width: '50%', backgroundColor: ((valueController === false && !toSetValue) ? BLAMING_COLORS.NOT_GUILTY : (valueController === true && toSetValue) ? BLAMING_COLORS.GUILTY : '#fff') }}>
            <div className={styles.labelSpinner}>
                <div>
                    <span>{text}</span>
                </div>
            </div>
        </div>
    }

    useEffect(() => {
        getData();
    }, []);


    const renderBody = () => {
        return (
            <>
                <Row className={styles.fullHeight} style={{ margin: 0 }}>
                    <Col xs={12} sm={12} md={12} lg={8} xl={9}
                            className={styles.colLeftContent}>
                        <div className={styles.header}>
                                {
                                    <>
                                        <img src={IconBack} className={styles.icon} onClick={() => {if(!!accident){ getData(accident?.id); } onBackList();}} />
                                        {t('accidents.title')}
                                    </>
                                }
                            </div>
                    <Row>
                        <Col xs={12} md={6} lg={4} >
                            <FormItem>
                                <Label className={styles.label}>{t('accidents.accident.date')} {(Utils.isStringNullOrEmpty(itemType) ? type !== 'details' : itemType !== 'details') ? '*' : ''}</Label>
                                <DateWithHour 
                                    form={form}
                                    datePlaceholder={t('accidents.accident.date')}
                                    onChangeDate={(date: Date) => {
                                        setValue('date', date);
                                        getInsuranceContractsByDate((Utils.isStringNullOrEmpty(itemType) ? type === 'details' : itemType === 'details'), date, getValues('insuranceContractId'));
                                    }}
                                    dateSelected={watch('date')}
                                    dateFormat={DATE_FORMAT_DEFAUT}
                                    dateCustomInput={<InputGroup icon={<FaCalendarAlt />} removeIconOrTextBorderRadius={true} />}
                                    dateName="date"
                                    dateRequired={true}
                                    dateDisabled={(Utils.isStringNullOrEmpty(itemType) ? type === 'details' : itemType === 'details')}

                                    onTimeChoose={hour => { setValue('hour', hour.formatted24) }}
                                    timePlaceholder={t('common.hour')}
                                    timeName="hour"
                                    timeDisabled={(Utils.isStringNullOrEmpty(itemType) ? type === 'details' : itemType === 'details')}
                                />
                                <InputError error={errors.date} />
                            </FormItem>
                        </Col>
                        <Col xs={12} md={6} lg={4} >
                            <FormItem>
                                <Label className={styles.label}>{t('accidents.accident.contract_number')} {(Utils.isStringNullOrEmpty(itemType) ? type !== 'details' : itemType !== 'details') ? '*' : ''}</Label>
                                <SelectController
                                    form={form}
                                    isDisabled={(Utils.isStringNullOrEmpty(itemType) ? type === 'details' : itemType === 'details')}
                                    name="insuranceContractId"
                                    options={contractNumbersOptions}
                                    rules={{ required: true }}
                                />
                                <InputError error={errors.insuranceContractId} />
                            </FormItem>
                        </Col>
                        <Col xs={12} md={6} lg={4} >
                            <FormItem>
                                <Label className={styles.label}>{t('accidents.accident.driver')} {(Utils.isStringNullOrEmpty(itemType) ? type !== 'details' : itemType !== 'details') ? '*' : ''}</Label>
                                <SelectController
                                    form={form}
                                    isDisabled={(Utils.isStringNullOrEmpty(itemType) ? type === 'details' : itemType === 'details')}
                                    name="driverId"
                                    options={driversOptions}
                                    rules={{ required: true }}
                                />
                                <InputError error={errors.driverId} />
                            </FormItem>
                        </Col>
                        <Col xs={12} md={6} lg={4} >
                            <FormItem>
                                <Label className={styles.label}>{t('accidents.accident.location')} {(Utils.isStringNullOrEmpty(itemType) ? type !== 'details' : itemType !== 'details') ? '*' : ''}</Label>
                                <InputController
                                    name="location"
                                    form={form as any}
                                    autoComplete='off'
                                    disabled={(Utils.isStringNullOrEmpty(itemType) ? type === 'details' : itemType === 'details')}
                                    placeholder={t('accidents.accident.location')}
                                    rules={{ ...DEFAULT_INPUT_RULES_WITH_REQUIRED }}
                                />
                                <InputError error={errors.location} maxLength={250} />
                            </FormItem>
                        </Col>
                        <Col xs={12} md={6} lg={4}>
                            <FormItem>
                                <Label className={styles.label}>{t('accidents.accident.guilt')} {(Utils.isStringNullOrEmpty(itemType) ? type !== 'details' : itemType !== 'details') ? '*' : ''}</Label>
                                <Controller
                                    render={({ onChange, value }) => {
                                        return (
                                            <div className={styles.groupSpinner}>
                                                <div className={(Utils.isStringNullOrEmpty(itemType) ? type !== 'details' : itemType !== 'details') ? styles.spinner : styles.spinnerDetails}>
                                                    {renderBlaming(onChange, value, false, t('accidents.guilt_types.innocent'))}
                                                    {renderBlaming(onChange, value, true, t('accidents.guilt_types.guilty'))}
                                                </div>
                                            </div>
                                        );
                                    }}
                                    control={control}
                                    name="guilty"
                                    defaultValue={getValues('guilty')} />
                                <InputError error={errors.guilty} />
                            </FormItem>
                        </Col>
                    </Row>
                    {renderButtons()}
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={4} xl={3}
                        className={styles.colRightContent}>
                        <div>
                            <Label className={styles.subtitle}>{t('common.attachment')}</Label>
                            <FormItem>
                                <FileSelector
                                    isDetails={(Utils.isStringNullOrEmpty(itemType) ? type === 'details' : itemType === 'details')}
                                    isMulti={true}
                                    initialFiles={getValues('attachments')}
                                    onFilesChanged={setValuesFiles}
                                    onRemoveFile={onRemoveFile}
                                    label={<Label className={styles.label}>{t('common.add_new_file')}</Label>}
                                    smallButtonAdd={true}
                                    fileButtonSizes={{ sm: 12, lg: 6 }}
                                />
                            </FormItem>
                        </div>
                        <div>
                            <FormItem>
                                <Label className={styles.label}>{t('common.notes')}</Label>
                                <InputResizable
                                    name="notes"
                                    form={form as any}
                                    disabled={(Utils.isStringNullOrEmpty(itemType) ? type === 'details' : itemType === 'details')}
                                    defaultValue={accident?.notes}
                                    onChange={event => setValue('notes', event.currentTarget.value.toString())}
                                    placeholder={t('common.notes')}
                                    rows={5}
                                />
                            </FormItem>
                        </div>
                    </Col>
                </Row>
            </>
        );
    }

    const renderButtons = () => {
        return (
            <>
                <div className={styles.buttonsContainer}>
                    <Button
                        preset={'secondary'}
                        type="button"
                        onClick={() => {
                            if(!!accident){ getData(accident?.id); }
                            onBackList();
                        }}
                        text={t('common.cancel')}
                    />
                    {(Utils.isStringNullOrEmpty(itemType) ? type === 'details' : itemType === 'details') && editable && hasAccidentsWritePolicy &&
                        <Button
                            type="button"
                            text={t('common.remove')}
                            preset={'danger'}
                            onClick={() => showRemoveItemDialog({ id: accident?.id } as AccidentDto)} />
                    }
                    {(Utils.isStringNullOrEmpty(itemType) ? type === 'details' : itemType === 'details') && editable && hasAccidentsWritePolicy &&
                        <Button
                            type={'button'}
                            text={t('common.edit')}
                            onClick={() => { onItemDetails(false); }}
                        />}
                    {(Utils.isStringNullOrEmpty(itemType) ? type !== 'details' : itemType !== 'details') && hasAccidentsWritePolicy &&
                        <Button
                            type={'submit'}
                            text={t('common.save')}
                        />}

                    <QuestionYesNo message={t('common.messages.remove_record')}
                        isVisible={dialogDeleteItemIsOpen}
                        onYes={() => removeItem()}
                        onNo={() => setDialogDeleteItemIsOpen(false)} />
                </div>
            </>
        );
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div>
                <div {...props}>
                    <div className={styles.contentIsVehicle}>
                        {renderBody()}
                        
                    </div>
                </div>
            </div>
        </form>
    );
}

export default AccidentScreen;
