import React from 'react';
import { useTranslation } from 'react-i18next';
import { DriversTotalsDto } from 'api/drivers/models/DriversTotalsDto';
import CounterBox from 'common/components/counterBox/CounterBox';
import TicketIcon from 'assets/svg/ticket_white.svg';
import ConeIcon from 'assets/svg/cone.svg';
import SpinIcon from 'assets/svg/spin.svg';
import UserIcon from 'assets/svg/user.svg';
import styles from './CounterBoxDriver.module.scss';

type Props = {
    totals?: DriversTotalsDto
};

const CounterBoxDriver: React.FC<Props> = ({ totals }: Props) => {
    const { t } = useTranslation();

    const renderCounterBoxes = () => {
        return <div className={styles.rowCounters}>
            {totals && renderBox(
                UserIcon,
                t('drivers.list.average_kilometers'),
                totals.averageKilometers ?? 0,
                false,
                true
            )}
            {totals && renderBox(
                SpinIcon,
                t('drivers.list.total_refunds'),
                totals.totalRefunds ?? 0, true
            )}
            {totals && renderBox(
                TicketIcon,
                t('drivers.list.total_fines'),
                totals.totalFines ?? 0
            )}
            {totals && renderBox(
                ConeIcon,
                t('drivers.list.total_accidents'),
                totals.totalAccidents ?? 0
            )}
        </div>
    }

    const renderBox = (icon: any, label: string, counter?: number | undefined, isMoney?: boolean, isKm?: boolean) => {
        return <CounterBox
            icon={icon}
            label={label}
            counter={counter}
            isMoney={isMoney}
            isKm={isKm}
            col4={true}
            verticalAlign
        />
    }

    return (<>
        {totals && renderCounterBoxes()}
        </>
    );
}

export default CounterBoxDriver;