import { UserProfile } from 'api/account/models/UserProfile';
import { CatalogSupplierSearchCriteria } from 'api/catalog/models/CatalogSupplierSearchCriteria';
import ChargesService from 'api/charges/ChargesService';
import { ChargeDto } from 'api/charges/models/ChargeDto';
import { GenericType } from 'api/genericTypes/enums/GenericType';
import GenericTypesService from 'api/genericTypes/GenericTypesService';
import { SelectValueGenericTypeDto } from 'api/genericTypes/models/SelectValueGenericTypeDto';
import SuppliersService from 'api/suppliers/SuppliersService';
import { UsersSelectItemDto } from 'api/users/models/UserDto';
import UsersService from 'api/users/UsersService';
import VehiclesService from 'api/vehicles/VehiclesService';
import IconBack from 'assets/svg/Arrow-grey.svg';
import Box from 'common/components/box/Box';
import Button from 'common/components/button/Button';
import CheckBox from 'common/components/checkBox/CheckBox';
import DateTimePickerController from 'common/components/dateTimePicker/DateTimePickerController';
import FileSelector from 'common/components/fileSelector/FileSelector';
import FormItem from 'common/components/formItem/FormItem';
import InputController from 'common/components/input/InputController';
import InputError from 'common/components/inputError/InputError';
import InputGroup from 'common/components/inputGroup/InputGroup';
import InputGroupController from 'common/components/inputGroup/InputGroupController';
import Label from 'common/components/label/Label';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import ScreenContainer from 'common/components/screenContainer/ScreenContainer';
import ScreenHeader from 'common/components/screenHeader/ScreenHeader';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import Select from 'common/components/select/Select';
import SelectController from 'common/components/select/SelectController';
import CustomFile from 'common/models/CustomFile';
import Loading from 'common/services/Loading';
import Logger from 'common/services/Logger';
import Utils from 'common/services/Utils';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import { DATE_FORMAT_DEFAUT, DEFAULT_INPUT_RULES, DEFAULT_INPUT_RULES_WITH_REQUIRED, FUELS, LOGGER_LOG_TYPE } from 'Config';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaCalendarAlt } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { Reducers } from 'store/types';
import styles from './ChargeScreen.module.scss';
import TimeLine, { TimeLineItem } from 'common/components/timeLine/TimeLine';
import InputResizableController from 'common/components/input/InputResizableController';
import { ChargesStatusColorEnum } from 'api/charges/enums/ChargesStatusColorEnum';
import ReactTooltip from 'react-tooltip';
import DateTimePickerControllerNew from 'common/components/dateTimePicker/DateTimePickerControllerNew';
import { VehicleDto } from 'api/vehicles/models/VehicleDto';
import InputInfoWarning from 'common/components/inputInfoWarning/InputInfoWarning';
import TrackingService from 'api/tracking/TrackingService';
import { TrackingProvider } from 'api/tracking/enums/TrackingProvider';

export enum SituationType {
    TREATED = 'TREATED',
    NOT_TREATED = 'NOT_TREATED',
}

export enum RequestStatusType {
    APPROVED = 'APPROVED',
    REFUSED = 'REFUSED'
}


export type Props = {
    isVehicle?: boolean;
    isDriver?: boolean;
    onBackList: () => void;
} & React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const ChargeScreen = ({ isVehicle, isDriver, onBackList, ...props }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const history = useHistory();
    // eslint-disable-next-line
    let { vehicleId, driverId, itemType, itemId, tabId, type } = useParams<{ vehicleId: string, driverId: string, itemType: string, itemId: string, tabId: string, type: string }>();
    const [itemToRemove, setItemToRemove] = useState<ChargeDto>();
    const [dialogDeleteItemIsOpen, setDialogDeleteItemIsOpen] = React.useState(false);
    const [charge, setCharge] = useState<ChargeDto | null>(null);

    const [vehicleOptions, setVehicleOptions] = useState<SelectValueLabel[]>([]);
    const [typeOptions, setTypeOptions] = useState<SelectValueGenericTypeDto[]>([]);
    const [supplierOptions, setSupplierOptions] = useState<SelectValueLabel[]>([]);
    const [responsibleOptions, setResponsibleOptions] = useState<SelectValueLabel[]>([]);
    const [selectecType, setSelectecType] = useState<SelectValueGenericTypeDto>();
    const [selectecTypeName, setSelectecTypeName] = useState<string | null>(null);
    const [selectedRefundTreated, setSelectedRefundTreated] = useState<boolean | null>(null);
    const [selectedRequestRefund, setSelectedRequestRefund] = useState<boolean>(false);
    const [fuelOptions, setFuelOptions] = useState<SelectValueLabel[]>([]);
    const [reRender, setReRender] = useState<number>(0);



    const [requiredFields, setRequiredFields] = useState<string[]>([]);
    const [displayFields, setDisplayFields] = useState<string[]>([]);
    const [vehicle, setVehicle] = useState<VehicleDto | undefined>(undefined);
    const [inputKmsBelowCurrentVehicleKms, setInputKmsBelowCurrentVehicleKms] = useState<boolean>(false);
    const [currentKms, setCurrentKms] = useState<number | null>(null);


    const demandStatusOptions: SelectValueLabel[] = [
        { value: RequestStatusType.APPROVED, label: t(('charges.request_status_type.' + RequestStatusType.APPROVED) as any) },
        { value: RequestStatusType.REFUSED, label: t(('charges.request_status_type.' + RequestStatusType.REFUSED) as any) }
    ];

    const [blockRefundTreated, setBlockRefundTreated] = useState<boolean>(false);

    const [savedFiles, setSavedFiles] = useState<CustomFile[]>([]);

    const form = useForm<ChargeDto>({ shouldUnregister: false, defaultValues: {} });
    const { handleSubmit, getValues, setValue, control, errors, reset } = form;

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasChargesWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_CHARGES_WRITE']);
    const hasWriteRefundStatusPolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_REFUND_STATUS_WRITE']);
    const hasWriteRefundSituationPolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_REFUND_SITUATION_WRITE']);
    const hasReadRefundPolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_REFUND_READ']);
    const hasReadGPSPolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_GPS_READ']);

    const [refundHistory, setRefundHistory] = useState<TimeLineItem[]>([]);
    const [refreshKey, setRefreshKey] = useState<number>(new Date().getTime());

    const refresh = () => {
        setRefreshKey(new Date().getTime());
    }

    const getData = async () => {

        setValue('attachmentsToRemove', []);
        try {
            Loading.show();

            const types1 = await GenericTypesService.catalogCharge();
            const types2 = types1.map(x => ({ ...x, name: x.label as GenericType, label: t(`common.generic_types.${x.label}` as any) }));
            const types = Utils.sortAlphabetically(types2);
            setTypeOptions(types);

            const responsables = await UsersService.getAllForCompany();
            setResponsibleOptions((responsables || []).map((x: UsersSelectItemDto) => ({ value: x.id || '', label: x.realName || '' })));

            if (Utils.isStringNullOrEmpty(vehicleId) && !itemId) {
                const vehicles = await VehiclesService.catalog();
                setVehicleOptions(vehicles);
            }

            let vehicleIdVerified = vehicleId;
            if (itemId) {
                const result = await ChargesService.getById(itemId);
                if (result === undefined) {
                    addToast(t('common.messages.error_load_info'), { appearance: 'error' });
                    Logger.error(LOGGER_LOG_TYPE.CHARGES, `Couldn't get the charge with id: ${itemId}`);
                    history.goBack();
                }

                const vehicles = await VehiclesService.catalog(result.vehicleId ?? null);
                setVehicleOptions(vehicles);

                vehicleIdVerified = !vehicleIdVerified ? (result.vehicleId ?? '') : vehicleIdVerified;
                setValues(result, types);

                getVehicle((result.vehicleId ?? ''), result);

            } else {
                setValue('date', new Date());

                if (!Utils.isStringNullOrEmpty(vehicleIdVerified)) {
                    getVehicle(vehicleId);
                }
            }


            refresh();

            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.CHARGES, `Couldn't get information to create charge`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        }
    }

    const setValues = (values: ChargeDto, types: SelectValueGenericTypeDto[]) => {
        const refundHistories = values.refundHistory.map(x => ({
            ...x,
            title: x.status && !x.situation ?
                <> <span className={styles.labelHistory}> {t(('charges.request_status_type.' + x.status) as any)}</span>  </>
                :
                x.situation ?
                    <> <span className={styles.labelHistory} style={{ color: (ChargesStatusColorEnum as any)[x.situation] }}> {t(('charges.situations.' + x.situation) as any)}</span> </> : '',
            date: x.updatedDate ?? x.createdDate,
            dateRefund: x.dateRefundTreated,
            userName: x.statusUserName ?? x.userCreatedHistoryName,
            description: x.observations,
            status: x.status,
            situation: x.situation
        }));
        values.refund = refundHistories && refundHistories.length ? refundHistories[0] : undefined;
        values.paymentDate = values.paymentDate ? new Date(values.paymentDate) : undefined;

        reset({ ...values, date: new Date(values.date) });
        setCharge(values);

        getSupplierByType(values.typeId);
        setSelectedRequestRefund(values.requestRefund);

        const type = types.find(x => x.value == values.typeId);

        setRequiredFields(type ? (type.requiredFieldsList ?? []) : [])
        setDisplayFields(type ? (type.displayFieldsList ?? []) : [])

        setBlockRefundTreated(!Utils.isStringNullOrEmpty(values.fuelCard ?? ''));

        setValuesFiles(values.attachments);

        setSelectecTypeName(type?.name ?? null);


        setSelectedRefundTreated(values.refund?.situation == null ? null
            : (values.refund?.situation == SituationType.TREATED) ? true : false);

        setRefundHistory(refundHistories);
    }



    const onSubmit = async (form: ChargeDto) => {
        try {
            form.vehicleId = Utils.isStringNullOrEmpty(vehicleId) ? form.vehicleId : vehicleId;

            if (form.refund && charge?.refund) {
                if (form.refund.status == charge?.refund.status &&
                    form.refund.statusUserId == charge?.refund.statusUserId &&
                    form.refund.situation == charge?.refund.situation &&
                    form.refund.dateRefundTreated == charge?.refund.dateRefundTreated
                ) {
                    form.refund = undefined;
                }
                else if (form.refund.status != charge?.refund.status && form.refund.situation == charge?.refund.situation) {
                    form.refund.situation = undefined;
                    form.refund.dateRefundTreated = undefined;
                }
            } else if (!form.refund?.status && !form.refund?.situation) {
                form.refund = undefined;
            }

            Loading.show();

            prepareFilesToSubmit();

            if (form.id) {
                await ChargesService.update(form, form.attachments ? form.attachments : [])
            } else {
                itemId = await ChargesService.create(form, form.attachments ? form.attachments : [])
            }

            Loading.hide();
            addToast(t('common.messages.record_save_success'), { appearance: 'success' });

            getData();

            onBackList && onBackList();
        } catch (error) {
            addToast(t('common.messages.record_save_error'), { appearance: 'error' });
            Logger.error(LOGGER_LOG_TYPE.CHARGES, `Couldn't update the charge with id: ${form.id}`, error);
            arrangeFilesAfterSubmit();
            Loading.hide();
        }
    }

    const showRemoveItemDialog = (item: ChargeDto) => {
        setItemToRemove(item);
        setDialogDeleteItemIsOpen(true);
    };

    const removeItem = async () => {
        setDialogDeleteItemIsOpen(false);
        if (!!itemToRemove) {
            try {
                Loading.show();
                await ChargesService.remove(itemToRemove);
                addToast(t('common.messages.record_delete_success'), { appearance: 'success' });

                onBackList && onBackList();
                Loading.hide();
            } catch (error) {
                addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
                Loading.hide();
            }
        }
    };

    const getSupplierByType = async (typeId: string) => {
        try {
            Loading.show();
            let options: SelectValueLabel[] = [];
            if (typeId) {
                const suppliers = await SuppliersService.catalog({ typeId: typeId } as CatalogSupplierSearchCriteria);
                options = (suppliers || []).map((x: SelectValueLabel) => ({ value: x.value || '', label: x.label || '' }));
            }

            if (getValues('supplierId') && !options.find(x => x.value === getValues('supplierId'))) {
                if (Utils.isStringNullOrEmpty(itemType) ? type === 'details' : itemType === 'details') {
                    options.push({ value: getValues('supplierId') ?? '', label: getValues('supplierName') ?? '' })
                } else {
                    setValue('supplierId', null);
                }
            }

            setSupplierOptions(options);

        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.CHARGES, `Couldn't get supplier by type`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        }
        finally {
            Loading.hide();
        }
    }

    const navigateTo = (typeUrl?: string, id?: string) => {
        if (typeUrl) {
            history.push(`/charges/${typeUrl}/${id}`);
        } else {
            history.push(`/charges`);
        }
    }

    const onSelectRefundTreated = (mode: boolean, typeSituation?: SituationType) => {
        setSelectedRefundTreated(selectedRefundTreated != null && mode == selectedRefundTreated ? null : mode);

        setValue('refund.situation', selectedRefundTreated != null && mode == selectedRefundTreated ? null : typeSituation);
        setValue('refund.dateRefundTreated', (!mode) || (selectedRefundTreated != null && mode == selectedRefundTreated) ? null : new Date());
    }

    const onchangeRequestRefund = (mode: boolean) => {
        setSelectedRequestRefund(mode);
        setSelectedRefundTreated(null);
        setValue('refundTreated', selectedRefundTreated);
        setValue('dateRefundTreated', mode ? new Date() : null);
    }

    const getFuelCard = async (id: string) => {
        try {
            const result = await VehiclesService.getById(id);
            let fuelCard = null;
            if (result) {
                fuelCard = result.fuelCard;
                setValue('supplierId', result.fuelCardSupplierId);
            }
            setValue('fuelCard', fuelCard ?? '');
            setBlockRefundTreated(!!fuelCard ? true : false);

        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.MAINTENANCE_CONTRACTS, `Couldn't get information to use contract associate`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        }
    }

    const changeFuelCard = () => {
        const fuelCardAux = getValues('fuelCard');
        if (fuelCardAux != null && fuelCardAux != '') {
            setSelectedRequestRefund(false);
            onSelectRefundTreated(false);
            setValue('requestRefund', false);
            setBlockRefundTreated(true);
        } else {
            setBlockRefundTreated(false);
        }
    }

    const changeType = (data: SelectValueGenericTypeDto) => {
        form.clearErrors();
        setValue('typeId', data ? data.value : null);
        setSelectecTypeName(data ? data.name : null);

        setSelectecType(data);
        setRequiredFields(data ? (data.requiredFieldsList ?? []) : [])
        setDisplayFields(data ? (data.displayFieldsList ?? []) : [])

        // current kms from tracking
        getGpsKms();
    }

    const onRemoveFile = (file: CustomFile) => {
        if (file.id) {
            const arr = [...(getValues('attachmentsToRemove') || []), file.id];
            setValue('attachmentsToRemove', arr);
        }
    }

    const changeVehicle = (data: SelectValueLabel) => {
        const vId = data ? data.value : null;
        setValue('vehicleId', vId);
        getVehicle(vId);
    }

    const arrangeFilesAfterSubmit = () => {
        setValuesFiles(savedFiles);
    }

    const prepareFilesToSubmit = () => {
        setValuesFiles(getValues('attachments')?.filter(x => !x.id));
    }

    const setValuesFiles = (files: CustomFile[]) => {
        setValue('attachments', files);
        setSavedFiles(files?.filter(x => x.id));
    }

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if (vehicle && ((!!type &&  type !== 'details') || (!!itemType && itemType !== 'details'))) {
            // current kms from tracking
            getGpsKms();
        }
    }, [vehicle, type, itemType]);


    useEffect(() => {

        if (shouldDisplay('CARD')) {
            vehicleId && getFuelCard(vehicleId);
        } else {
            setValue('fuelCard', null);
            setBlockRefundTreated(false);
        }

        if (shouldDisplay('SUPPLIER') && selectecType) {
            getSupplierByType(selectecType?.value);
        } else {
            setValue('supplierId', null);
        }
    }, [selectecType]);

    const refundApproved = form.getValues('refund.status') == RequestStatusType.APPROVED;

    const isRequired = (field: string) => {
        return requiredFields.includes(field);
    }

    const shouldDisplay = (field: string) => {
        return displayFields.includes(field);
    }

    const getVehicle = async (id?: string | null, chargeAux?: ChargeDto) => {
        try {
            const _vehicle = id ? await VehiclesService.getById(id) : undefined
            setVehicle(_vehicle);

            if (_vehicle) {

                let aux = _vehicle?.vehicleFuels?.map(x => {
                    return {
                        value: x.fuelId,
                        label: !!x.fuelName ? t(('vehicle.fuels.' + x.fuelName) as any) : '',
                        fullLabel: (x.fuelName ?? ''),
                    }
                }) ?? [];

                if (chargeAux && !!chargeAux.fuelId && !aux.find(x => x.value == chargeAux.fuelId)) {
                    aux.push({ value: chargeAux.fuelId, fullLabel: (chargeAux.fuelName ?? ''), label: !!chargeAux.fuelName ? t(('vehicle.fuels.' + chargeAux.fuelName) as any) : '' });
                    aux = Utils.sortAlphabetically(aux);
                }

                setFuelOptions(aux);

                if (aux.length == 1) {
                    form.setValue('fuelId', aux[0].value);
                }
            }

            if (itemId && _vehicle && (form.watch('kilometers') ?? 0) < (_vehicle?.currentKilometers ?? 0)) {
                setInputKmsBelowCurrentVehicleKms(true);
            } else {
                setInputKmsBelowCurrentVehicleKms(false);
            }

        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.CHARGES, `Couldn't get vehicle data`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        }
    }

    const getGpsKms = async () => {
        if (vehicle && vehicle.id && vehicle.companyTrackingProvider && vehicle?.companyTrackingProvider !== TrackingProvider.NONE && vehicle.trackingDeviceId) {
            try {
                const position = await TrackingService.getPosition(vehicle.id);
                setCurrentKms(position.km ?? null);

            } catch (error) {
                Logger.error(LOGGER_LOG_TYPE.CHARGES, `Couldn't get vehicle gps kms`, error);
                addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            }
        }
    }

    const compareKms = () => {
        if (vehicle && (form.watch('kilometers') ?? 0) < (vehicle?.currentKilometers ?? 0)) {
            setInputKmsBelowCurrentVehicleKms(true);
            return;
        }
        setInputKmsBelowCurrentVehicleKms(false);
    }

    const renderBody = () => {
        return (
            <>
                <Row>
                    {(refundApproved && Boolean(charge)) &&
                        <div className={styles.messageRefundApproved}>
                            <span className={styles.labelRefundApproved}>{t('charges.charge_has_refund_approved')}</span>
                        </div>}
                </Row>
                <Row>
                    {!isVehicle &&
                        <Col xs={12} md={6} lg={4}>
                            <FormItem>
                                <Label className={styles.label}>{t('vehicle.vehicle')} {Utils.isStringNullOrEmpty(itemType) ? type !== 'details' : itemType !== 'details' ? '*' : ''}</Label>
                                <SelectController
                                    form={form}
                                    isDisabled={Utils.isStringNullOrEmpty(itemType) ? type === 'details' : itemType === 'details' || (refundApproved && Boolean(charge))}
                                    placeholder={t('vehicle.vehicle')}
                                    name="vehicleId"
                                    options={vehicleOptions}
                                    rules={{ required: true }}
                                    onChangeSelect={(data: SelectValueLabel) => {
                                        changeVehicle(data);
                                    }} />
                                <InputError error={errors.vehicleId} />
                            </FormItem>
                        </Col>}
                    <Col xs={12} md={6} lg={4}>
                        <FormItem>
                            <Label className={styles.label}>{t('charges.charge_date')} {Utils.isStringNullOrEmpty(itemType) ? type !== 'details' : itemType !== 'details' ? '*' : ''}</Label>
                            <DateTimePickerController
                                form={form}
                                placeholderText={t('charges.charge_date')}
                                onChange={(date: Date) => {
                                    setValue('date', date);
                                    setCharge(charge ? { ...charge, date: date } : null);
                                }}
                                selected={charge ? moment(charge.date).toDate() : null}
                                dateFormat={DATE_FORMAT_DEFAUT}
                                customInput={<InputGroup icon={<FaCalendarAlt />} />}
                                name="date"
                                autoComplete='off'
                                disabled={Utils.isStringNullOrEmpty(itemType) ? type === 'details' : itemType === 'details' || (refundApproved && Boolean(charge))}
                                rules={{ required: true }} />
                            <InputError error={errors.date} />
                        </FormItem>
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                        <FormItem>
                            <Label className={styles.label}>{t('common.type')} {Utils.isStringNullOrEmpty(itemType) ? type !== 'details' : itemType !== 'details' ? '*' : ''}</Label>
                            <Controller
                                render={({ onChange, value, ref }) => {
                                    return (
                                        <Select
                                            options={typeOptions}
                                            isClearable
                                            isDisabled={Utils.isStringNullOrEmpty(itemType) ? type === 'details' : itemType === 'details' || (refundApproved && Boolean(charge))}
                                            placeholder={t('common.type')}
                                            onChange={(data: SelectValueGenericTypeDto) => {
                                                onChange(data?.value);
                                                changeType(data);
                                            }}
                                            ref={ref}
                                            value={typeOptions.find(x => x.value === value) ? { label: typeOptions.find(x => x.value === value)?.label ?? '', value: value } : null}
                                        />
                                    );
                                }}
                                control={control}
                                name="typeId"
                                defaultValue={getValues('typeId')}
                                rules={{ required: true }} />
                            <InputError error={errors.typeId} />
                        </FormItem>
                    </Col>

                    {shouldDisplay('FUEL') && <Col xs={12} md={6} lg={4}>
                        <FormItem>
                            <Label className={styles.label}>{t('vehicle.fuel')} {(Utils.isStringNullOrEmpty(itemType) ? type !== 'details' : itemType !== 'details') && isRequired('SUPPLIER') ? '*' : ''}</Label>
                            <SelectController
                                form={form}
                                isDisabled={Utils.isStringNullOrEmpty(itemType) ? type === 'details' : itemType === 'details' || (refundApproved && Boolean(charge))}
                                placeholder={t('vehicle.fuel')}
                                name="fuelId"
                                options={fuelOptions}
                                rules={{ required: isRequired('FUEL') }}
                                onChange={() => {
                                    setReRender(reRender + 1);
                                }}
                            />
                            {isRequired('fuel') && <InputError error={errors.fuelId} />}
                        </FormItem>
                    </Col>}


                    {shouldDisplay('SUPPLIER') && <Col xs={12} md={6} lg={4}>
                        <FormItem>
                            <Label className={styles.label}>{t('common.supplier')} {(Utils.isStringNullOrEmpty(itemType) ? type !== 'details' : itemType !== 'details') && isRequired('SUPPLIER') ? '*' : ''}</Label>
                            <SelectController
                                form={form}
                                isDisabled={Utils.isStringNullOrEmpty(itemType) ? type === 'details' : itemType === 'details' || (refundApproved && Boolean(charge))}
                                placeholder={t('common.supplier')}
                                name="supplierId"
                                options={supplierOptions}
                                rules={{ required: isRequired('SUPPLIER') }} />
                            {isRequired('SUPPLIER') && <InputError error={errors.supplierId} />}
                        </FormItem>
                    </Col>}
                    {shouldDisplay('CARD') && <Col xs={12} md={6} lg={4}>
                        <FormItem>
                            <Label className={styles.label}>{t('vehicle.fuel_card')} {(Utils.isStringNullOrEmpty(itemType) ? type !== 'details' : itemType !== 'details') && isRequired('CARD') ? '*' : ''}</Label>
                            <InputController
                                name="fuelCard"
                                form={form as any}
                                autoComplete='off'
                                disabled={Utils.isStringNullOrEmpty(itemType) ? type === 'details' : itemType === 'details' || (refundApproved && Boolean(charge))}
                                placeholder={t('vehicle.fuel_card')}
                                rules={isRequired('CARD') ? { ...DEFAULT_INPUT_RULES_WITH_REQUIRED } : { ...DEFAULT_INPUT_RULES }}
                                onKeyUp={changeFuelCard} />
                            {isRequired('CARD') && <InputError error={errors.fuelCard} />}
                        </FormItem>
                    </Col>}
                    {shouldDisplay('DESCRIPTION') && <Col xs={12} md={6} lg={4}>
                        <FormItem>
                            <Label className={styles.label}>{t('charges.description')} {(Utils.isStringNullOrEmpty(itemType) ? type !== 'details' : itemType !== 'details') && isRequired('DESCRIPTION') ? '*' : ''}</Label>
                            <InputController
                                name="description"
                                form={form as any}
                                autoComplete='off'
                                disabled={Utils.isStringNullOrEmpty(itemType) ? type === 'details' : itemType === 'details' || (refundApproved && Boolean(charge))}
                                placeholder={t('charges.description')}
                                rules={isRequired('DESCRIPTION') ? { ...DEFAULT_INPUT_RULES_WITH_REQUIRED, maxLength: 999 } : { ...DEFAULT_INPUT_RULES, maxLength: 999 }}
                            />
                            {isRequired('DESCRIPTION') && <InputError error={errors.description} />}
                        </FormItem>
                    </Col>}
                    <Col xs={12} md={6} lg={4}>
                        <FormItem>
                            <Label className={styles.label}>{t('common.responsible')} {Utils.isStringNullOrEmpty(itemType) ? type !== 'details' : itemType !== 'details' && selectedRequestRefund ? '*' : ''}</Label>
                            <SelectController
                                form={form}
                                isDisabled={Utils.isStringNullOrEmpty(itemType) ? type === 'details' : itemType === 'details' || (refundApproved && Boolean(charge))}
                                placeholder={t('common.responsible')}
                                name="responsibleId"
                                options={responsibleOptions}
                                rules={{ required: selectedRequestRefund ? true : false }} />
                            {selectedRequestRefund && <InputError error={errors.responsibleId} />}
                        </FormItem>
                    </Col>
                    {shouldDisplay('COST') && <>
                        <Col xs={12} md={6} lg={4}>
                            <FormItem>
                                <Label className={styles.label}>{t('common.cost', { vat: (userProfile?.useValueWithVat ? t('common.with_vat') : t('common.without_vat')) })} {(Utils.isStringNullOrEmpty(itemType) ? type !== 'details' : itemType !== 'details') && isRequired('COST') ? '*' : ''}</Label>
                                <InputGroupController
                                    text={t('common.euro')}
                                    placeholder={t('common.cost', { vat: (userProfile?.useValueWithVat ? t('common.with_vat') : t('common.without_vat')) })}
                                    name="cost"
                                    form={form}
                                    disabled={Utils.isStringNullOrEmpty(itemType) ? type === 'details' : itemType === 'details' || (refundApproved && Boolean(charge))}
                                    format="money"
                                    rules={{ required: isRequired('COST') }} />
                                {isRequired('COST') && <InputError error={errors.cost} />}

                                <div className={styles.checkboxArea} data-tip={t('charges.has_fuel_card_message')}>
                                    <Controller
                                        control={control}
                                        name={'requestRefund'}
                                        render={({ value }) => {
                                            return (
                                                <CheckBox
                                                    onChange={e => {
                                                        setValue('requestRefund', e.target.checked);
                                                        onchangeRequestRefund(e.target.checked);
                                                    }}
                                                    label={t('charges.request_refund')}
                                                    checked={value ? true : false}
                                                    disabled={(Utils.isStringNullOrEmpty(itemType) ? type === 'details' : itemType === 'details') || blockRefundTreated || refundHistory.length > 0}
                                                />
                                            );
                                        }}
                                        defaultValue={form.getValues('requestRefund') ? true : false} />
                                </div>
                                {(blockRefundTreated || ((form.getValues('fuelCard') ?? '').length > 0)) && <ReactTooltip />}
                            </FormItem>
                        </Col>
                        <Col xs={12} md={6} lg={4}>
                            <FormItem>
                                <Label className={styles.label}>
                                    {t('maintenances.payment_date')}
                                </Label>
                                <DateTimePickerControllerNew
                                    form={form}
                                    placeholderText={t('common.date')}
                                    name="paymentDate"
                                    autoComplete='off'
                                    disabled={Utils.isStringNullOrEmpty(itemType) ? type === 'details' : itemType === 'details' || (refundApproved && Boolean(charge))}
                                    rules={{ required: false }}
                                />
                                <InputError error={errors.paymentDate} />
                            </FormItem>
                        </Col>
                    </>}

                    {shouldDisplay('QTY') && <Col xs={12} md={6} lg={4} key={'chargeQtdFuel' + reRender}>
                        <FormItem>
                            <Label className={styles.label}>{t((fuelOptions.find(x => x.value == form.getValues(`fuelId`))?.fullLabel == FUELS.ELECTRIC ? 'common.kwh' : 'common.liters') as any)} {(Utils.isStringNullOrEmpty(itemType) ? type !== 'details' : itemType !== 'details') && isRequired('QTY') ? '*' : ''}</Label>
                            <InputGroupController
                                text={t((fuelOptions.find(x => x.value == form.getValues(`fuelId`))?.fullLabel == FUELS.ELECTRIC ? 'common.kwh' : 'common.lt') as any)}
                                placeholder={t((fuelOptions.find(x => x.value == form.getValues(`fuelId`))?.fullLabel == FUELS.ELECTRIC ? 'common.kwh' : 'common.liters') as any)}
                                name="liters"
                                form={form}
                                disabled={Utils.isStringNullOrEmpty(itemType) ? type === 'details' : itemType === 'details' || (refundApproved && Boolean(charge))}
                                format="money"
                                rules={{ required: isRequired('QTY') }} />
                            {isRequired('QTY') && <InputError error={errors.liters} />}
                        </FormItem>
                    </Col>}
                    {shouldDisplay('KMS') && <Col xs={12} md={6} lg={4}>
                        <FormItem>
                            <Label className={styles.label}>{t('common.kilometers')} {(Utils.isStringNullOrEmpty(itemType) ? type !== 'details' : itemType !== 'details') && isRequired('KMS') ? '*' : ''}</Label>
                            <InputGroupController
                                text={t('common.kms')}
                                placeholder={t('common.kilometers')}
                                name="kilometers"
                                form={form}
                                disabled={Utils.isStringNullOrEmpty(itemType) ? type === 'details' : itemType === 'details' || (refundApproved && Boolean(charge))}
                                type="number"
                                format="money"
                                noFixedDecimalCase={true}
                                rules={{ required: isRequired('KMS') }}
                                onChange={() => compareKms()} />
                            {isRequired('KMS') && <InputError error={errors.kilometers} />}
                            {vehicle && ((type !== 'details' && !isVehicle && getValues('vehicleId')) || (itemType !== 'details' && isVehicle)) && <InputInfoWarning isWarning={inputKmsBelowCurrentVehicleKms} message={t((inputKmsBelowCurrentVehicleKms ? 'common.inserted_kms_below_current_kms' : 'common.insert_kms_below_current_kms'), { kms: Utils.numberFormatter(vehicle?.currentKilometers ?? 0) })} />}
                            {hasReadGPSPolicy && vehicle && currentKms != null && ((type !== 'details' && !isVehicle && getValues('vehicleId')) || (itemType !== 'details' && isVehicle)) && <InputInfoWarning isWarning={false} message={t('location.current_km', { kms: Utils.numberFormatter(currentKms ?? 0) })} />}
                        </FormItem>
                    </Col>}

                </Row>
                <Row className={styles.filesHeader}>
                    <Label className={styles.label}>{t('common.add_file')}</Label>
                </Row>

                <Row className={styles.filesContent}>
                    <Col xl={12}>
                        <FormItem>
                            <FileSelector
                                isDetails={Utils.isStringNullOrEmpty(itemType) ? type === 'details' : itemType === 'details' || (refundApproved && Boolean(charge))}
                                isMulti={true}
                                initialFiles={getValues('attachments')}
                                onFilesChanged={setValuesFiles}
                                onRemoveFile={onRemoveFile}
                                label={<Label className={styles.label}>{t('common.add_new_file')}</Label>}
                                smallButtonAdd={true}
                            />
                        </FormItem>
                    </Col>
                    <Col xl={8}>
                        <FormItem>
                            <Label className={styles.label}>{t('charges.notes')}</Label>
                            <InputResizableController
                                name="notes"
                                form={form as any}
                                placeholder={t('charges.notes')}
                                disabled={(Utils.isStringNullOrEmpty(itemType) ? type === 'details' : itemType === 'details') || (refundApproved && Boolean(charge))}
                                rows={1}
                            />
                        </FormItem>
                    </Col>

                </Row>

            </>
        );
    }

    const renderButtons = () => {
        const showButtonBasedOnPolicy = (Utils.isStringNullOrEmpty(itemType) ? type !== 'details' : itemType !== 'details') && hasChargesWritePolicy;
        const showEditOrRemoveButton = (Utils.isStringNullOrEmpty(itemType) ? type === 'details' : itemType === 'details') && !refundApproved && hasChargesWritePolicy && !(charge?.vehicleId && !charge?.vehicleActive) && charge?.active;

        return (
            <>
                <div className={styles.buttonsContainer}>
                    <Button
                        preset={'secondary'}
                        type="button"
                        onClick={() => {
                            if (!!charge) { reset(charge); }
                            (isVehicle || isDriver) ? onBackList() : history.goBack()
                        }}
                        text={t('common.cancel')}
                    />
                    {showEditOrRemoveButton &&
                        <Button
                            type="button"
                            text={t('common.remove')}
                            preset={'danger'}
                            onClick={() => showRemoveItemDialog({ id: charge?.id } as ChargeDto)} />
                    }
                    {showEditOrRemoveButton &&
                        <Button
                            type={'button'}
                            text={t('common.edit')}
                            onClick={() => {
                                if (Utils.isStringNullOrEmpty(tabId)) {
                                    history.push(`/charges/edit/${itemId}`);
                                } else {
                                    history.push(`/${isDriver ? 'drivers' : 'vehicles'}/details/${isDriver ? driverId : vehicleId}/${tabId}/edit/${itemId}`);
                                }
                            }}
                        />}
                    {showButtonBasedOnPolicy &&
                        <Button
                            type={'submit'}
                            text={t('common.save')}
                        />}

                    <QuestionYesNo message={t('common.messages.remove_record')}
                        isVisible={dialogDeleteItemIsOpen}
                        onYes={() => removeItem()}
                        onNo={() => setDialogDeleteItemIsOpen(false)} />
                </div>
            </>
        );
    };

    const renderSideInfo = () => {
        return (
            <>
                <div className={styles.scrollable}>
                    <div>
                        <Label className={styles.subtitle}>{t('charges.refund')}</Label>

                        {refundHistory.length > 0 && <>
                            <div style={{ display: 'flex', justifyContent: 'left', marginBottom: '1.5rem' }}>
                                <TimeLine items={refundHistory} />
                            </div>
                            <div className={styles.divider} />
                        </>}

                        <Row>
                            <Col xl={12}>
                                <FormItem>
                                    <Label className={styles.label}>{t('charges.request_status')}</Label>
                                    <Controller
                                        render={({ onChange, value, ref }) => {
                                            return (
                                                <Select
                                                    options={demandStatusOptions}
                                                    isClearable
                                                    isDisabled={!hasWriteRefundStatusPolicy || (Utils.isStringNullOrEmpty(itemType) ? type === 'details' : itemType === 'details')}
                                                    placeholder={t('charges.request_status')}
                                                    onChange={(data: SelectValueGenericTypeDto) => {
                                                        onChange(data?.value);
                                                    }}
                                                    ref={ref}
                                                    value={demandStatusOptions.find(x => x.value === value) ? { label: demandStatusOptions.find(x => x.value === value)?.label ?? '', value: value } : null}
                                                />
                                            );
                                        }}
                                        control={control}
                                        name="refund.status"
                                        defaultValue={getValues('refund.status')} />
                                </FormItem>
                            </Col>

                            <Col xl={12}>
                                <FormItem>
                                    <Label className={styles.label}>
                                        {form.watch('refund.status') == RequestStatusType.REFUSED ? t('charges.refused_by') : t('charges.approved_by')} {Utils.isStringNullOrEmpty(itemType) ? type !== 'details' : itemType !== 'details' && form.watch('refund.status') ? '*' : ''}
                                    </Label>
                                    <SelectController
                                        form={form}
                                        isDisabled={!hasWriteRefundStatusPolicy || (Utils.isStringNullOrEmpty(itemType) ? type === 'details' : itemType === 'details')}
                                        placeholder={form.watch('refund.status') == RequestStatusType.REFUSED ? t('charges.refused_by') : t('charges.approved_by')}
                                        name="refund.statusUserId"
                                        options={responsibleOptions}
                                        rules={{ required: form.watch('refund.status') }}
                                    />
                                    <InputError error={errors.refund?.statusUserId} />
                                </FormItem>
                            </Col>

                            {refundApproved &&
                                <>
                                    <Col xl={12}>
                                        <FormItem>
                                            <Label className={styles.label}>{t('charges.situation')}</Label>
                                            <Controller
                                                render={({ ref }) => {
                                                    return (
                                                        <div ref={ref} className={(Utils.isStringNullOrEmpty(itemType) ? type !== 'details' : itemType !== 'details') ? styles.situation : styles.situationDetails}>
                                                            <div onClick={() => (hasWriteRefundSituationPolicy && (Utils.isStringNullOrEmpty(itemType) ? type !== 'details' : itemType !== 'details')) ? onSelectRefundTreated(true, SituationType.TREATED) : null} style={{ width: '50%' }}>
                                                                <div className={styles.labelContainer}>
                                                                    <div className={selectedRefundTreated == true ? styles.selected : styles.notSelected} style={{ background: selectedRefundTreated == true ? (ChargesStatusColorEnum as any)[SituationType.TREATED] : null }}>
                                                                        <span>{t(('charges.situations.' + SituationType.TREATED) as any)}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div onClick={() => (hasWriteRefundSituationPolicy && (Utils.isStringNullOrEmpty(itemType) ? type !== 'details' : itemType !== 'details')) ? onSelectRefundTreated(false, SituationType.NOT_TREATED) : null} style={{ width: '50%' }}>
                                                                <div className={styles.labelContainer}>
                                                                    <div className={selectedRefundTreated == false ? styles.selected : styles.notSelected} style={{ background: selectedRefundTreated == false ? (ChargesStatusColorEnum as any)[SituationType.NOT_TREATED] : null }}>
                                                                        <span>{t(('charges.situations.' + SituationType.NOT_TREATED) as any)}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    );
                                                }}
                                                control={control}
                                                name="refund.situation"
                                                defaultValue={getValues('refund.situation')} />
                                        </FormItem>
                                    </Col>
                                    <Col xl={12}>
                                        {selectedRefundTreated == true && <FormItem>
                                            <Label className={styles.label}>{t('charges.refund_date')}</Label>
                                            <DateTimePickerController
                                                form={form}
                                                placeholderText={t('common.date')}
                                                onChange={(date: Date) => {
                                                    setValue('refund.dateRefundTreated', date);
                                                }}
                                                dateFormat={DATE_FORMAT_DEFAUT}
                                                customInput={<InputGroup icon={<FaCalendarAlt />} />}
                                                name="refund.dateRefundTreated"
                                                autoComplete='off'
                                                disabled={!hasWriteRefundSituationPolicy || (Utils.isStringNullOrEmpty(itemType) ? type === 'details' : itemType === 'details')}
                                                rules={{ required: true }}
                                            />
                                            <InputError error={errors.refund?.dateRefundTreated} />
                                        </FormItem>}
                                    </Col>
                                </>
                            }
                            {!!form.watch('refund.status') && <Col xl={12}>
                                <FormItem>
                                    <Label className={styles.label}>{t('charges.observations')}</Label>
                                    <InputResizableController
                                        name="refund.observations"
                                        form={form as any}
                                        placeholder={t('charges.observations')}
                                        disabled={(Utils.isStringNullOrEmpty(itemType) ? type === 'details' : itemType === 'details')}
                                        rows={3}
                                    />
                                </FormItem>
                            </Col>}
                        </Row>
                    </div>
                </div>
            </>
        );
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={styles.fullHeight} key={'form-' + refreshKey}>
            {(isVehicle || isDriver) &&
                <div {...props} className={styles.fullHeight}>
                    <Row className={styles.fullHeight} style={{ margin: 0 }}>
                        <Col xs={12} sm={12} md={12} lg={8} xl={9}>
                            <div className={styles.content}>
                                <div className={styles.header}>
                                    <img src={IconBack} className={styles.icon} onClick={() => {
                                        if (!!charge) { reset(charge); }
                                        onBackList()
                                    }} /> {t('charges.title')}
                                </div>
                                <div>
                                    {renderBody()}
                                </div>
                            </div>
                            <Row>
                                {renderButtons()}
                            </Row>
                        </Col>
                        {!blockRefundTreated && selectedRequestRefund && hasReadRefundPolicy &&
                            <Col xs={12} sm={12} md={12} lg={4} xl={3}
                                className={styles.colRightContent}>
                                {renderSideInfo()}
                            </Col>
                        }
                    </Row>
                </div>

            }

            {(!isVehicle && !isDriver) &&
                <ScreenTitle title={t('charges.title')}>
                    <ScreenContainer>
                        <ScreenHeader title={t('charges.title')} />
                        <Box className={styles.box}>
                            <div>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={8} xl={9}>
                                        <div className={styles.header}>
                                            {type === 'new' &&
                                                <>
                                                    <img src={IconBack} className={styles.icon} onClick={() => navigateTo()} /> {t('charges.new')}
                                                </>
                                            }
                                            {type == 'edit' &&
                                                <>
                                                    <img src={IconBack} className={styles.icon} onClick={() => navigateTo()} /> {t('charges.edit')}
                                                </>}
                                            {type == 'details' &&
                                                <>
                                                    <img src={IconBack} className={styles.icon} onClick={() => navigateTo()} /> {t('charges.details')}
                                                </>}

                                            {(Boolean(charge) && !charge?.active) &&
                                                <span className={styles.messageDeleted}>{' - ' + t('charges.charge_deleted')}</span>
                                            }
                                        </div>


                                        {renderBody()}
                                    </Col>

                                    {!blockRefundTreated && selectedRequestRefund && <Col xs={12} sm={12} md={12} lg={4} xl={3}
                                        className={styles.colRightContent}>
                                        {renderSideInfo()}
                                    </Col>}
                                </Row>
                            </div>


                            <Row>
                                {renderButtons()}
                            </Row>


                        </Box>
                    </ScreenContainer>
                </ScreenTitle >
            }

        </form >
    );
}

export default ChargeScreen;