import Utils from 'common/services/Utils';
import { RegisterOptions } from 'react-hook-form';

/**
 * Languages
 */
export const DEFAULT_LANGUAGE = 'fr';
export const DEFAULTNS = 'translations';

/**
 * API
 */
export const API_BASE_URL = process.env.REACT_APP_API_URL;
export const SERVER_BASE_URL = process.env.REACT_APP_SERVER_URL;

/**
 * Storage
 */
export const STORAGE_KEY = 'cQfTjWnZr4u7x!A%D*G-KaNdRgUkXp2s';
export const STORAGE = {
    CURRENT_LOCALE: 'CURRENT_LOCALE',
    AUTH_USER_TOKEN: 'AUTH_USER_TOKEN',
    AUTH_USER_PROFILE: 'AUTH_USER_PROFILE',
};

/**
* GA
*/
export const GA = process.env.REACT_APP_GA;

/**
 * Logger
 */
export enum LOGGER_MESSAGE_TYPE {
    INFO = 1,
    ERROR,
}
export enum LOGGER_LOG_TYPE {
    REQUEST,
    APP_CRASH,
    VEHICLES,
    USE_CONTRACTS,
    MAINTENANCE_CONTRACTS,
    INSURANCE_CONTRACTS,
    CONTRACTS,
    CHARGES,
    IMPORT_CHARGES,
    MAINTENANCES,
    ACCIDENTS,
    DRIVERS,
    ALERTS,
    VERIFICATIONS,
    DASHBOARD,
    ALARMS,
    VEHICLES_GPS
}
export const LOGGER_PRINT_LOGS = process.env.REACT_APP_LOGGER_PRINT_LOGS || false;
export const LOGGER_PRINT_LOGS_LEVEL = LOGGER_MESSAGE_TYPE.INFO;
export const LOGGER_SEND_LOGS = process.env.REACT_APP_LOGGER_SEND_LOGS || false;
export const LOGGER_SEND_LOGS_LEVEL = LOGGER_MESSAGE_TYPE.ERROR;
export const LOGGER_KEYS = process.env.REACT_APP_LOGGER_KEYS ?? '';
export const LOGGER_URL = process.env.REACT_APP_LOGGER_URL ?? '';

/**
 * DATE FORMAT
 */
export const DATE_FORMAT_DEFAUT = 'DD/MM/YYYY';
export const DATEPICKER_FORMAT_DEFAULT = 'dd/MM/yyyy';
export const DATE_TIME_FORMAT_DEFAUT = 'DD/MM/YYYY HH:mm';
export const DATE_FORMAT_MONTH_YEAR = 'MM/YYYY';
export const DATE_TIME_FORMAT_DEFAULT = 'DD/MM/YYYY | HH:mm';
export const DATE_FORMAT_MONTH_TEXT_YEAR = 'MMM/YYYY';
export const TIME_FORMAT_DEFAULT = 'HH:mm';


/**
 * NUMBERS
 */
export const NUMBERS_MAX_LENGTH = 14;
export const NUMBERS_MAX_VALUE = 999999999.99;

/**
 * POLICIES
 */
export const POLICIES = {
    SETTINGUP_COMPANIES_READ: 'SETTINGUP_COMPANIES_READ',
    SETTINGUP_COMPANIES_WRITE: 'SETTINGUP_COMPANIES_WRITE',
    SETTINGUP_MY_COMPANY_READ : 'SETTINGUP_MY_COMPANY_READ',
    SETTINGUP_MY_COMPANY_WRITE : 'SETTINGUP_MY_COMPANY_WRITE',
    SETTINGUP_USERS_READ: 'SETTINGUP_USERS_READ',
    SETTINGUP_USERS_WRITE: 'SETTINGUP_USERS_WRITE',
    SETTINGUP_ROLES_READ: 'SETTINGUP_ROLES_READ',
    SETTINGUP_ROLES_WRITE: 'SETTINGUP_ROLES_WRITE',
    SETTINGUP_RULES_READ: 'SETTINGUP_RULES_READ',
    SETTINGUP_RULES_WRITE: 'SETTINGUP_RULES_WRITE',
    SETTINGUP_VEHICLES_READ: 'SETTINGUP_VEHICLES_READ',
    SETTINGUP_VEHICLES_WRITE: 'SETTINGUP_VEHICLES_WRITE',
    SETTINGUP_VEHICLE_SEGMENTS_READ: 'SETTINGUP_VEHICLE_SEGMENTS_READ',
    SETTINGUP_VEHICLE_SEGMENTS_WRITE: 'SETTINGUP_VEHICLE_SEGMENTS_WRITE',
    SETTINGUP_VEHICLE_BRANDS_READ: 'SETTINGUP_VEHICLE_BRANDS_READ',
    SETTINGUP_VEHICLE_BRANDS_WRITE: 'SETTINGUP_VEHICLE_BRANDS_WRITE',
    SETTINGUP_VEHICLE_MODELS_READ: 'SETTINGUP_VEHICLE_MODELS_READ',
    SETTINGUP_VEHICLE_MODELS_WRITE: 'SETTINGUP_VEHICLE_MODELS_WRITE',
    SETTINGUP_SUPPLIERS_READ: 'SETTINGUP_SUPPLIERS_READ',
    SETTINGUP_SUPPLIERS_WRITE: 'SETTINGUP_SUPPLIERS_WRITE',
    SETTINGUP_LOCALS_READ: 'SETTINGUP_LOCALS_READ',
    SETTINGUP_LOCALS_WRITE: 'SETTINGUP_LOCALS_WRITE',
    SETTINGUP_DIAGNOSTIC_TYPES_READ: 'SETTINGUP_DIAGNOSTIC_TYPES_READ',
    SETTINGUP_DIAGNOSTIC_TYPES_WRITE: 'SETTINGUP_DIAGNOSTIC_TYPES_WRITE',
    SETTINGUP_DRIVERS_READ: 'SETTINGUP_DRIVERS_READ',
    SETTINGUP_DRIVERS_WRITE: 'SETTINGUP_DRIVERS_WRITE',
    SETTINGUP_ALERTS_READ: 'SETTINGUP_ALERTS_READ',
    SETTINGUP_VERIFICATIONS_READ: 'SETTINGUP_VERIFICATIONS_READ',
    SETTINGUP_VERIFICATIONS_WRITE: 'SETTINGUP_VERIFICATIONS_WRITE',
    SETTINGUP_ANNOTATIONS_READ: 'SETTINGUP_ANNOTATIONS_READ',
    SETTINGUP_ANNOTATIONS_WRITE: 'SETTINGUP_ANNOTATIONS_WRITE',
    SETTINGUP_VEHICLE_MAINTENANCES_READ: 'SETTINGUP_VEHICLE_MAINTENANCES_READ',
    SETTINGUP_VEHICLE_MAINTENANCES_WRITE: 'SETTINGUP_VEHICLE_MAINTENANCES_WRITE',
    SETTINGUP_CHARGES_READ: 'SETTINGUP_CHARGES_READ',
    SETTINGUP_CHARGES_WRITE: 'SETTINGUP_CHARGES_WRITE',
    SETTINGUP_CONTRACTS_READ: 'SETTINGUP_CONTRACTS_READ',
    SETTINGUP_CONTRACTS_WRITE: 'SETTINGUP_CONTRACTS_WRITE',
    SETTINGUP_ACCIDENTS_READ: 'SETTINGUP_ACCIDENTS_READ',
    SETTINGUP_ACCIDENTS_WRITE: 'SETTINGUP_ACCIDENTS_WRITE',
    SETTINGUP_GPS_READ: 'SETTINGUP_GPS_READ',
    CHARGES_TOTAL_READ: 'CHARGES_TOTAL_READ',
    SETTINGUP_VEHICLES_MANAGEMENT_READ: 'SETTINGUP_VEHICLES_MANAGEMENT_READ',
    SETTINGUP_VEHICLES_MANAGEMENT_WRITE: 'SETTINGUP_VEHICLES_MANAGEMENT_WRITE',
    SETTINGUP_VEHICLES_MILEAGE_READ: 'SETTINGUP_VEHICLES_MILEAGE_READ',
    SETTINGUP_VEHICLES_MILEAGE_WRITE: 'SETTINGUP_VEHICLES_MILEAGE_WRITE',
    SETTINGUP_COMMUNICATIONS_READ: 'SETTINGUP_COMMUNICATIONS_READ',
    SETTINGUP_COMMUNICATIONS_WRITE: 'SETTINGUP_COMMUNICATIONS_WRITE',
    SETTINGUP_RESERVATIONS_READ: 'SETTINGUP_RESERVATIONS_READ',
    SETTINGUP_RESERVATIONS_WRITE: 'SETTINGUP_RESERVATIONS_WRITE',
    SETTINGUP_RESERVATIONS_EDIT_ALL: 'SETTINGUP_RESERVATIONS_EDIT_ALL',
    SETTINGUP_NOTIFICATIONS_READ: 'SETTINGUP_COMMUNICATIONS_READ',
    SETTINGUP_NOTIFICATIONS_WRITE: 'SETTINGUP_COMMUNICATIONS_WRITE',
    SETTINGUP_REFUND_READ: 'SETTINGUP_REFUND_READ',
    SETTINGUP_REFUND_STATUS_WRITE: 'SETTINGUP_REFUND_STATUS_WRITE',
    SETTINGUP_REFUND_SITUATION_WRITE: 'SETTINGUP_REFUND_SITUATION_WRITE',
    SETTINGUP_DASHBOARDS_READ: 'SETTINGUP_DASHBOARDS_READ',
    SETTINGUP_IMPORT_EXPENSES_WRITE : 'SETTINGUP_IMPORT_EXPENSES_WRITE',
    SETTINGUP_IMPORT_EXPENSES_READ: 'SETTINGUP_IMPORT_EXPENSES_READ',
    SETTINGUP_REQUEST_VERIFICATIONS_READ: 'SETTINGUP_REQUEST_VERIFICATIONS_READ',
    SETTINGUP_REQUEST_VERIFICATIONS_WRITE: 'SETTINGUP_REQUEST_VERIFICATIONS_WRITE',
    SETTINGUP_IMPORT_DATA_WRITE: 'SETTINGUP_IMPORT_DATA_WRITE',
    SETTINGUP_EXPORT_EXPENSES_WRITE: 'SETTINGUP_EXPORT_EXPENSES_WRITE',
    SETTINGUP_MOBILE_SEE_ONLY_RESERVE_VEHICLES: 'SETTINGUP_MOBILE_SEE_ONLY_RESERVE_VEHICLES'
};

/**
 * ROLES
 */
export const ROLES = {
    PLATFORM_ADMINISTRATOR: 'PLATFORM_ADMINISTRATOR',
    ADMINISTRATOR: 'ADMINISTRATOR',
    CONTRIBUTOR: 'CONTRIBUTOR'
};

/**
 * Pagination
 */
export const DEFAULT_PAGINATION_ITEMS_PER_PAGE = 10;
export const MAX_PAGINATION_ITEMS_PER_PAGE = 999999;

/**
 * MIMETYPES
 */
export const MIMETYPES_IMAGE = 'image/png, image/gif, image/jpeg';
export const MIMETYPES_ALL_IMAGE = 'image/*';

export const isTablet = (width: number) => {
    return (width <= 1200);
}

export const removeAccents = (str: string) => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export const blobToBase64 = (blobFile: Blob): Promise<string> => {
    return new Promise(resolve => {
        const reader = new FileReader();
        reader.onloadend = () => {
            resolve((reader.result) as string);
        };
        reader.readAsDataURL(blobFile);
    });
}

export const urlToBase64 = (url: string): Promise<string> => {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.onload = () => {
            blobToBase64(xhr.response).then(resolve).catch(reject);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.withCredentials = true;
        xhr.send();
    });
}

export const googleMapsQuery = (latitude?: number, longitude?: number): string => {
    return `https://www.google.com/maps/search/?api=1&query=${latitude ?? 0},${longitude ?? 0}`;
}

export const DEFAULT_INPUT_RULES: RegisterOptions = { minLength: 1, maxLength: 255 }
export const DEFAULT_INPUT_RULES_WITH_REQUIRED: RegisterOptions = { required: true, validate: value => !Utils.isStringNullOrEmpty(value), ...DEFAULT_INPUT_RULES }
export const DEFAULT_EMAIL_RULES: RegisterOptions = { ...DEFAULT_INPUT_RULES, pattern: { value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,4}$/, message: 'common.errors.email_not_valid' } }
export const DEFAULT_EMAIL_RULES_WITH_REQUIRED: RegisterOptions = { required: true, validate: value => !Utils.isStringNullOrEmpty(value), ...DEFAULT_EMAIL_RULES }

export const FUELS = {
    HYBRID: 'HYBRID',
    ELECTRIC: 'ELECTRIC',
    GASEOUS_FUEL: 'GASEOUS_FUEL',
    GASOLINE: 'GASOLINE',
    DIESEL: 'DIESEL'
};

export const COUNTRIES = {
    PT: 'PT',
    FR: 'FR'
};

export const TOKEN_TYPES = {
    RESET_PASSWORD: 'resetPassword'
};


export const ApplicationName = 'FleetMax';

export const QueryParameterNames = {
    ReturnUrl: 'returnUrl',
    Message: 'message'
};

export const LogoutActions = {
    LogoutCallback: 'logout-callback',
    Logout: 'logout',
    LoggedOut: 'logged-out'
};

export const LoginActions = {
    Login: 'login',
    LoginCallback: 'login-callback',
    LoginFailed: 'login-failed',
    Profile: 'profile',
    Register: 'register',
    Reset: 'resetPassword'
};

const prefix = '/authentication';

export const ApplicationPaths = {
    DefaultLoginRedirectPath: '/',
    ApiAuthorizationClientConfigurationUrl: `${SERVER_BASE_URL}/_configuration/${ApplicationName}`,
    ApiAuthorizationPrefix: prefix,
    Login: `${prefix}/${LoginActions.Login}`,
    LoginFailed: `${prefix}/${LoginActions.LoginFailed}`,
    LoginCallback: `${prefix}/${LoginActions.LoginCallback}`,
    Register: `${prefix}/${LoginActions.Register}`,
    Profile: `${prefix}/${LoginActions.Profile}`,
    LogOut: `${prefix}/${LogoutActions.Logout}`,
    LoggedOut: `${prefix}/${LogoutActions.LoggedOut}`,
    LogOutCallback: `${prefix}/${LogoutActions.LogoutCallback}`,
    IdentityRegisterPath: 'Identity/Account/Register',
    IdentityManagePath: 'Identity/Account/Manage',
    IdentityTwoFactorAuthenticationPath: `${SERVER_BASE_URL}/Identity/Account/Manage/TwoFactorAuthentication`,
    Reset: `${prefix}/${LoginActions.Reset}`
};

export const DASHBOARD_COLORS = {
    expenses: {
        ASSURANCE: '#68D9F3',
        CONTRACTS: '#E2B434',
        MAINTENANCES: '#DC3B86',
        COSTS: '#1781A1',
        FINES: '#e74c3c',
        FUEL: '#16E0CC',
        ENERGY: '#fc8332',
        CLEANING: '#27ae60',
        ACCESSORIES : '#9900CC',
	    LOGO : '#0000CC',
        TRANSPORT: '#66999B',
        MISCELLANEOUS: '#996633',
    },
    consumes: {
        AVERAGE: '#1781A1',
        FUEL: '#1781A1',
        ENERGY: '#16DDCB',
    },
    monthlyCost: {
        FIXED_COST: '#D4872F',
        VARIABLE_COST: '#E2B434',
    },
    fuels: {
        GASOLINE: '#68D9F3',
        GASEOUS_FUEL: '#E2B434',
        ELECTRIC: '#DC3B86',
        DIESEL: '#1781A1',
    },
    monthlyRoute: {
        KM: '#000',
        TIME: '#000',
        TOTAL: '#000',
        BAR: '#16E0CC'
    },
};

export const BLAMING_COLORS = {
    GUILTY: '#DC4C4C',
    NOT_GUILTY: '#16CCC3',
};

export const USE_CONTRACT_TYPES = {
    CREDIT: 'CREDIT',
    LEASING: 'LEASING',
    ALD: 'ALD',
    PURCHASE: 'PURCHASE',
};