import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { SupplierContactDto, SupplierDto, SupplierTypeDto } from 'api/suppliers/models/SupplierDto';
import Loading from 'common/services/Loading';
import SuppliersService from 'api/suppliers/SuppliersService';
import Logger from 'common/services/Logger';
import Button from 'common/components/button/Button';
import styles from './SuppliersDetails.module.scss';
import InputError from 'common/components/inputError/InputError';
import Label from 'common/components/label/Label';
import FormItem from 'common/components/formItem/FormItem';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { DEFAULT_EMAIL_RULES, DEFAULT_INPUT_RULES_WITH_REQUIRED, LOGGER_LOG_TYPE } from 'Config';
import GreyArrow from 'assets/svg/Arrow-grey.svg';
import React, { useEffect, useState } from 'react';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import Select from 'common/components/select/Select';
import GenericTypesService from 'api/genericTypes/GenericTypesService';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import { Col, Row } from 'react-flexbox-grid';
import CustomFile from 'common/models/CustomFile';
import InputController from 'common/components/input/InputController';
import Utils from 'common/services/Utils';
import MediaSelector from 'common/components/mediaSelector/MediaSelector';
import { useHistory, useParams } from 'react-router-dom';
import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import ScreenHeaderButton from 'common/components/screenHeader/ScreenHeaderButton';
import AddIcon from 'assets/svg/desktop_add_new.svg';
import { ReactComponent as TrashIcon } from 'assets/svg/trash_no_color.svg';

type Props = {
};

const SuppliersDetails: React.FC<Props> = ({ }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();

    const [typesList, setTypesList] = useState<SelectValueLabel[]>([]);
    const [supplier, setSupplier] = useState<SupplierDto>();

    const history = useHistory();
    // eslint-disable-next-line
    let { type, itemId } = useParams<{ type: string, itemId: string }>();

    const [itemToRemove, setItemToRemove] = useState<SupplierDto>();
    const [dialogDeleteItemIsOpen, setDialogDeleteItemIsOpen] = React.useState(false);

    const form = useForm<SupplierDto>({ shouldUnregister: false });
    const supplierContacts = useFieldArray<SupplierContactDto>({ control: form.control, name: 'supplierContacts', keyName: 'formId' as any });
    const { handleSubmit, errors, reset, control, getValues, setValue } = form;

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasSuppliersWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_SUPPLIERS_WRITE']);
    const isDetails = type === 'details';
    const [supplierContactToRemoveIndex, setSupplierContactToRemoveIndex] = useState<number | null>(null);
    const [showRemoveSupplierContact, setShowRemoveSupplierContact] = useState<boolean>(false);
    const [supplierContactsIdsToRemove, setSupplierContactsIdsToRemove] = useState<string[]>([]);
    const [loadedFormValues, setLoadedFormValues] = useState<boolean>(false);

    const getTypes = async () => {
        Loading.show();
        try {
            const result = await GenericTypesService.catalogSupplier();
            const options = result.map(x => ({ ...x, label: t(`common.generic_types.${x.label}` as any) }) as SelectValueLabel);
            setTypesList(Utils.sortAlphabetically(options));
            Loading.hide();
        } catch (error) {
            addToast(t('common.messages.error_load_info'), {
                appearance: 'error',
            });

            Logger.error(
                LOGGER_LOG_TYPE.REQUEST,
                `Couldn't get catalog supplier types`,
                error
            );
            Loading.hide();
        }
    }

    const getSupplier = async () => {
        if (!itemId) {
            setLoadedFormValues(true);
            return;
        }
        Loading.show();
        try {
            const result = await SuppliersService.getById(itemId);
            reset(result);
            setSupplier(result);     
            setLoadedFormValues(true);
            Loading.hide();
        } catch (error) {
            addToast(t('common.messages.error_load_info'), {
                appearance: 'error',
            });

            Logger.error(
                LOGGER_LOG_TYPE.REQUEST,
                `Couldn't get catalog supplier types`,
                error
            );
            Loading.hide();
        }
    }

    const showRemoveItemDialog = (item: SupplierDto) => {
        setItemToRemove(item);
        setDialogDeleteItemIsOpen(true);
    };

    const removeItem = async () => {
        setDialogDeleteItemIsOpen(false);
        if (!!itemToRemove) {
            try {
                Loading.show();
                await SuppliersService.remove(itemToRemove);
                addToast(t('common.messages.record_delete_success'), { appearance: 'success' });
                history.push(`/settings/suppliers/list`);
                Loading.hide();
            } catch (error) {
                addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
                Loading.hide();
            }
        }
    };

    const onAddMedia = (files: CustomFile[]) => {
        setValue('logo', files[0]);
    }

    const onRemoveMedia = () => {
        setValue('logo', []);
        setValue('removeAttachment', true);
    }

    useEffect(() => {
        getTypes();
        getSupplier();
    }, []);

    const onSubmit = async (formData: SupplierDto) => {
        try {

            if (!formData.name || !formData.supplierTypes || (formData.supplierTypes != null && formData.supplierTypes.length == 0)) {
                addToast(t('common.messages.complete_required_fields'), { appearance: 'warning' });
                return;
            }

            Loading.show();

            if (supplier != null) {
                formData.id = supplier.id;
                await SuppliersService.update(formData, formData.logo ? [formData.logo] : []);
            } else {
                itemId = await SuppliersService.create(formData, formData.logo ? [formData.logo] : []);
                getSupplier();
            }

            Loading.hide();

            addToast(t('common.messages.record_save_success'), { appearance: 'success' });
            history.goBack();
        } catch (error) {
            Loading.hide();

            if (error?.response?.status === 409) {
                addToast(t('common.messages.name_already_exists'), { appearance: 'warning' });
                return;
            }

            addToast(t('common.messages.record_save_error'), { appearance: 'error' });
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't create or update supplier`, error);
        }
    };

    const onGoBack = () => {
        if (supplier) {
            reset(supplier);
        }
        history.goBack();        
    }

    const addSupplierContact = () => {
        supplierContacts.append({
            id: null,
            description: '',
            name: undefined,
            email: undefined,
            phoneNumber: undefined
        });
    }

    const setRemoveSupplierContact = (index: number) => {
        setShowRemoveSupplierContact(true);
        setSupplierContactToRemoveIndex(index);
    }

    const removeSupplierContact = () => {
        if (supplierContactToRemoveIndex === null) return;
        
        if (supplierContacts.fields[supplierContactToRemoveIndex]?.id) {
            setSupplierContactsIdsToRemove([...supplierContactsIdsToRemove, supplierContacts.fields[supplierContactToRemoveIndex].id!]);
        }

        supplierContacts.remove(supplierContactToRemoveIndex);

        setShowRemoveSupplierContact(false);
    }

    const renderSupplierContact = () => {
        return supplierContacts && supplierContacts.fields.map((x, i) => {
            return (<Row key={x.formId} className={styles.rowSupplierContacts}>
                <Col xs={12} md={12} lg={6} xl={3}>
                    <FormItem>
                        {i == 0 && <Label className={styles.labelSuppliers}>
                            {t('suppliers.description')} {!isDetails ? '*' : ''}
                        </Label>}
                        <InputController
                            name={`supplierContacts[${i}].description`}
                            form={form as any}
                            disabled={isDetails}
                            placeholder={t('suppliers.description')}
                            rules={{ ...DEFAULT_INPUT_RULES_WITH_REQUIRED }}
                        />
                        {errors.supplierContacts && errors.supplierContacts.length > 0 && <InputError error={errors.supplierContacts[i]?.description} maxLength={250} />}
                    </FormItem>
                </Col>
                <Col xs={12} md={12} lg={6} xl={3}>
                    <FormItem>
                        {i == 0 && <Label className={styles.labelSuppliers}>
                            {t('suppliers.name')}
                        </Label>}
                        <InputController
                            name={`supplierContacts[${i}].name`}
                            form={form as any}
                            disabled={isDetails}
                            placeholder={t('suppliers.name')}
                        />
                        {!!errors.supplierContacts && errors.supplierContacts.length > 0 && <InputError error={errors.supplierContacts[i]?.name} />}
                    </FormItem>
                </Col>
                <Col xs={12} md={12} lg={6} xl={3}>
                    <FormItem>
                        {i == 0 &&<Label className={styles.labelSuppliers}>
                            {t('suppliers.email')}
                        </Label>}
                        <InputController
                            name={`supplierContacts[${i}].email`}
                            form={form as any}
                            disabled={isDetails}
                            placeholder={t('suppliers.email')}
                            rules={{ ...DEFAULT_EMAIL_RULES }}
                        />
                        {!!errors.supplierContacts && errors.supplierContacts.length > 0 && <InputError error={errors.supplierContacts[i]?.email} />}
                    </FormItem>
                </Col>
                <Col xs={11} md={11} lg={5} xl={2}>
                    <FormItem>
                        {i == 0 && <Label className={styles.labelSuppliers}>
                            {t('suppliers.phone_number')}
                        </Label>}
                        <InputController
                            name={`supplierContacts[${i}].phoneNumber`}
                            form={form as any}
                            disabled={isDetails}
                            placeholder={t('suppliers.phone_number')}
                        />
                        {!!errors.supplierContacts && errors.supplierContacts.length > 0 && <InputError error={errors.supplierContacts[i]?.phoneNumber} />}
                    </FormItem>
                </Col>
                {!isDetails && <Col xs={1} style={{ display: 'flex', alignItems: 'center' }}>
                    <TrashIcon className={styles.removeIcon} onClick={() => setRemoveSupplierContact(i)} />
                </Col>}
            </Row>)
        })
    }

    return (
        <div>
            <div className={styles.pageNameContainer}>
                <div className={styles.pageNameContent} onClick={() => history.push(`/settings/suppliers/list`)} >
                    <img className={styles.pageNameContentImage} src={GreyArrow} />
                    <span className={styles.pageNameContentText}>
                        {t('settings.suppliers')}
                    </span>
                </div>
            </div>

            <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col xs={12} lg={8} xl={9}>
                        <Row>
                            <Col xs={12} md={12} lg={6}>
                                <FormItem>
                                    <Label className={styles.label}>
                                        {t('suppliers.name')} {!isDetails ? '*' : ''}
                                    </Label>
                                    <InputController
                                        name="name"
                                        form={form as any}
                                        disabled={isDetails}
                                        placeholder={t('suppliers.name')}
                                        rules={{ ...DEFAULT_INPUT_RULES_WITH_REQUIRED }}
                                    />
                                    <InputError error={errors.name} maxLength={250} />
                                </FormItem>
                            </Col>
                            <Col xs={12} md={12} lg={6}>
                                <FormItem>
                                    <Label className={styles.label}>{t('common.types')} {!isDetails ? '*' : ''}</Label>
                                    <Controller
                                        render={({ onChange, value }) => {
                                            return (
                                                <Select
                                                    options={typesList}
                                                    isClearable
                                                    isMulti
                                                    isDisabled={isDetails}
                                                    placeholder={t('common.types')}
                                                    onChange={(data: SelectValueLabel[]) => {
                                                        onChange(data?.map(x => ({ typeId: x.value })));
                                                    }}
                                                    value={value ? typesList.filter(x => value.find((y: SupplierTypeDto) => y.typeId === x.value)) : null}
                                                />
                                            );
                                        }}
                                        control={control}
                                        name="supplierTypes"
                                        rules={{ required: true }}
                                        defaultValue={getValues('supplierTypes')} />
                                    <InputError error={errors.supplierTypes as any} />
                                </FormItem>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} lg={4} xl={3}>
                        <FormItem>
                            <Label className={styles.label}>{t('common.image')}</Label>
                            <MediaSelector
                                isMulti={false}
                                isDetails={isDetails}
                                onAddMedias={onAddMedia}
                                onRemoveMedia={onRemoveMedia}
                                initialMedias={supplier?.logo ? [supplier.logo] : []}
                            />
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <div className={styles.containerSmallButton}>
                            <Label className={styles.label}>{t('suppliers.supplier_contacts')}</Label>
                            {(!isDetails) && <ScreenHeaderButton icon={AddIcon} onClick={addSupplierContact} classNameIcon={styles.iconAdd} />}
                        </div>

                        {supplierContacts.fields.length === 0 && <div className={styles.noInfoText}>
                            {t('suppliers.without_supplier_contacts')}
                        </div>}

                        {loadedFormValues && renderSupplierContact()}
                    </Col>
                </Row>
                <div className={styles.buttonContainer}>
                    <Button preset={'secondary'}
                        type='button'
                        text={t('common.cancel')}
                        onClick={() => onGoBack()} />
                    {type === 'details' && hasSuppliersWritePolicy &&
                        <Button
                            type="button"
                            text={t('common.remove')}
                            preset={'danger'}
                            onClick={() => showRemoveItemDialog({ id: supplier?.id } as SupplierDto)} />
                    }
                    {type === 'details' && hasSuppliersWritePolicy &&
                        <Button
                            type='button'
                            text={t('common.edit')}
                            onClick={() => history.push(`/settings/suppliers/edit/${itemId}`)} />
                    }
                    {type !== 'details' && hasSuppliersWritePolicy &&
                        <Button
                            type='submit'
                            text={t('common.save')} />
                    }

                    <QuestionYesNo message={t('common.messages.remove_supplier', { supplier: supplier?.name ?? '' })}
                        isVisible={dialogDeleteItemIsOpen}
                        onYes={() => removeItem()}
                        onNo={() => setDialogDeleteItemIsOpen(false)} />
                </div>

                <QuestionYesNo
                    message={t('common.messages.remove_record')}
                    isVisible={showRemoveSupplierContact}
                    onYes={() => removeSupplierContact()}
                    onNo={() => { setShowRemoveSupplierContact(false); setSupplierContactToRemoveIndex(null) }}
                />
            </form>
        </div>
    );
};

export default SuppliersDetails;
