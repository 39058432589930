import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './VehicleModelsFiltersScreen.module.scss';
import Button from '../../../../../../common/components/button/Button';
import FormItem from '../../../../../../common/components/formItem/FormItem';
import { Col, Row } from 'react-flexbox-grid';
import { SelectValueLabel } from '../../../../../../common/types/SelectValueLabel';
import { Controller, useForm } from 'react-hook-form';
import Select from 'common/components/select/Select';
import Label from 'common/components/label/Label';
import VehicleBrandsService from 'api/vehicleBrands/VehicleBrandsService';

export interface Filters {
    brandId?: string;
}

type Props = {
    filters: Filters;
    onChange: (filters: Filters) => void;
    onFilter: (filters: Filters) => void;
}

const VehicleModelsFiltersScreen: React.FC<Props> = ({ filters, onChange, onFilter }: Props) => {
    const { t } = useTranslation();

    const form = useForm<Filters>({ shouldUnregister: false, shouldFocusError: true, defaultValues: filters });

    const [brandsOptions, setBrands] = useState<SelectValueLabel[]>([]);

    const getData = async () => {
        const brandsOptions = await 
            VehicleBrandsService.catalog();
        setBrands(brandsOptions);
    }

    useEffect(() => {
        getData();
    }, []);
    
    const clearFilters = () => {
        form.reset();
        form.setValue('brandId', null);
        onInputChange();
        onSubmit(form.getValues());
    }

    const onSubmit = (filters: Filters) => {
        onFilter(filters);
    }

    const onInputChange = () => {
        onChange(form.getValues());
    }

    return (
        <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className={styles.formContent}>
                <div>
                    <FormItem>
                        <Row>
                            <Col xs={12}>
                                <FormItem>
                                    <Label className={styles.bold}>{t('vehicle_models.brand')}</Label>
                                    <Controller
                                        render={({ onChange, value }) => {
                                            return (
                                                <Select
                                                    options={brandsOptions}
                                                    isClearable
                                                    placeholder={t('vehicle_models.brand')}
                                                    onChange={(data: SelectValueLabel) => {
                                                        onChange(data?.value);
                                                        onInputChange();
                                                    }}
                                                    value={brandsOptions.find(x => x.value === value) ? { label: brandsOptions.find(x => x.value === value)?.label ?? '', value: value } : null}
                                                />
                                            );
                                        }}
                                        control={form.control}
                                        name="brandId"
                                        defaultValue={form.getValues('brandId')} />
                                </FormItem>
                            </Col>
                        </Row>
                    </FormItem>
                </div>
                <div className={styles.buttonsFooter}>
                    <FormItem>
                        <Button
                            text={t('common.remove')}
                            size={'normal'}
                            preset={'secondary'}
                            onClick={clearFilters}
                            type='reset' />
                        <Button
                            type='submit'
                            text={t('common.apply')}
                            size={'normal'}
                        />
                    </FormItem>
                </div>
            </div>
        </form>
    );
}

export default memo(VehicleModelsFiltersScreen);