import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { UserProfile } from 'api/account/models/UserProfile';
import { POLICIES } from 'Config';
import { Reducers } from 'store/types';
import Sidebar, { SidebarItem } from '../sidebar/Sidebar';
import styles from './AuthenticatedLayout.module.scss';
import UsersService from 'api/users/UsersService';
import { AlertsTotalsDto } from 'api/alerts/models/AlertsTotalsDto';
import { updateTotals } from 'store/alerts/action';
import NewAccountScreen from 'screens/new_account/NewAccountScreen';

type Props = {
    children: React.ReactNode;
}

type SidebarItemWithValidation = {
    policies: (keyof typeof POLICIES)[];
    type?: 'OR' | 'AND';
} & SidebarItem;

const AuthenticatedLayout: React.FunctionComponent<Props> = ({ children }: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const isCompanyInfoFilled = useSelector<Reducers, boolean>(state => state.authentication.isCompanyInfoFilled);
    const hasAlertsPolicy = UsersService.hasPolicies(loggedUser?.policies || [], ['SETTINGUP_ALERTS_READ']);
    const alertsTotals = useSelector<Reducers, AlertsTotalsDto>(state => state.alerts.totals)

    const validateItem = useCallback((item: SidebarItemWithValidation) => {
        const type = item.type || 'AND';
        const policies = item.policies || [];
        let userPolicies = loggedUser?.policies || [];

        //se a empresa não tiver gps configurado, não vê o menu gps
        if (loggedUser?.trackingProvider != null && loggedUser?.trackingProvider == 'NONE') {
            userPolicies = loggedUser.policies.filter(x=> x != 'SETTINGUP_GPS_READ')
        }

        return UsersService.hasPolicies(userPolicies, policies, type);
    }, [loggedUser]);

    useEffect(() => {
        if (hasAlertsPolicy)
        dispatch(updateTotals());
    }, [])

    const sidebarItems: SidebarItemWithValidation[] = useMemo(() => {
        const items: SidebarItemWithValidation[] = [
            {
                text: t('menu.companies'),
                url: '/companies',
                policies: [
                    'SETTINGUP_COMPANIES_READ', 'SETTINGUP_COMPANIES_WRITE'
                ],
                type: 'OR',
            },
            {
                text: t('dashboard.title'),
                url: '/dashboard',
                policies: [
                    'SETTINGUP_DASHBOARDS_READ'
                ],
                type: 'OR',
            },
            {
                text: t('menu.vehicles_gps'),
                url: '/vehicles-gps',
                policies: [
                    'SETTINGUP_GPS_READ'
                ],
                type: 'OR',
            },                        
            {
                text: t('menu.vehicles'),
                url: '/vehicles',
                policies: [
                    'SETTINGUP_VEHICLES_READ', 'SETTINGUP_VEHICLES_WRITE'
                ],
                type: 'OR',
            },
            {
                text: t('menu.vehicles_management'),
                url: '/vehicles-management',
                policies: [
                    'SETTINGUP_VEHICLES_MANAGEMENT_READ', 'SETTINGUP_VEHICLES_MANAGEMENT_WRITE'
                ],
                type: 'OR',
            },
            {
                text: t('menu.vehicles_mileage'),
                url: '/vehicles-mileage',
                policies: [
                    'SETTINGUP_VEHICLES_MILEAGE_READ', 'SETTINGUP_VEHICLES_MILEAGE_WRITE'
                ],
                type: 'OR',
            },
            {
                text: t('menu.drivers'),
                url: '/drivers',
                policies: [
                    'SETTINGUP_DRIVERS_READ', 'SETTINGUP_DRIVERS_WRITE'
                ],
                type: 'OR',
            },
            {
                text: t('menu.costs'),
                url: '/charges',
                policies: [
                    'SETTINGUP_CHARGES_READ', 'SETTINGUP_CHARGES_WRITE'
                ],
                type: 'OR',
            },
            {
                text: t('menu.maintenances'),
                url: '/maintenances',
                policies: [
                    'SETTINGUP_VEHICLE_MAINTENANCES_READ', 'SETTINGUP_VEHICLE_MAINTENANCES_WRITE'
                ],
                type: 'OR',
            },
            {
                text: t('menu.notifications'),
                url: '/alerts',
                policies: [
                    'SETTINGUP_ALERTS_READ'
                ],
                type: 'OR',
                badgeValue: alertsTotals.totalNotReadOwn || undefined,
            },
            {
                text: t('menu.alarms'),
                url: '/alarms',
                policies: [
                    'SETTINGUP_GPS_READ'
                ],
                type: 'OR',
            },
            {
                text: t('menu.verifications'),
                url: '/verifications',
                policies: [
                    'SETTINGUP_VERIFICATIONS_READ', 'SETTINGUP_VERIFICATIONS_WRITE'
                ],
                type: 'OR',
            },
            {
                text: t('menu.reservations'),
                url: '/reservations',
                policies: [
                    'SETTINGUP_RESERVATIONS_READ', 'SETTINGUP_RESERVATIONS_WRITE'
                ],
                type: 'OR',
            },
            {
                text: t('menu.security'), 
                url: '/security',
                policies: [
                    'SETTINGUP_USERS_READ', 'SETTINGUP_USERS_WRITE',
                    'SETTINGUP_ROLES_READ', 'SETTINGUP_ROLES_WRITE',
                    'SETTINGUP_RULES_READ', 'SETTINGUP_RULES_WRITE'
                ],
                type: 'OR',
            },
            {
                text: t('menu.settings'),
                url: '/settings',
                policies: [
                    'SETTINGUP_VEHICLE_SEGMENTS_READ', 'SETTINGUP_VEHICLE_SEGMENTS_WRITE',
                    'SETTINGUP_VEHICLE_BRANDS_READ', 'SETTINGUP_VEHICLE_BRANDS_WRITE',
                    'SETTINGUP_VEHICLE_MODELS_READ', 'SETTINGUP_VEHICLE_MODELS_WRITE',
                    'SETTINGUP_SUPPLIERS_READ', 'SETTINGUP_SUPPLIERS_WRITE',
                    'SETTINGUP_LOCALS_READ', 'SETTINGUP_LOCALS_WRITE',
                    'SETTINGUP_DIAGNOSTIC_TYPES_READ', 'SETTINGUP_DIAGNOSTIC_TYPES_WRITE',
                    'SETTINGUP_COMMUNICATIONS_READ', 'SETTINGUP_COMMUNICATIONS_WRITE',
                    'SETTINGUP_NOTIFICATIONS_READ', 'SETTINGUP_NOTIFICATIONS_WRITE'
                ],
                type: 'OR',
             },    
            // {
            //     text: t('menu.import_charges'),
            //     url: '/import-charges',
            //     policies: [
            //         'SETTINGUP_IMPORT_EXPENSES_READ','SETTINGUP_IMPORT_EXPENSES_WRITE'
            //     ],
            //     type: 'OR',
            // }
        ];

        return items.filter(item => validateItem(item));
    }, [loggedUser, validateItem, alertsTotals]);

    return (
        <div className={styles.container} >
            <div className={styles.sidebarContainer}>
                <Sidebar items={sidebarItems} />
            </div>
            <div className={styles.contentContainer} id="scrollHere">
                {isCompanyInfoFilled && children}
                {!isCompanyInfoFilled && <NewAccountScreen/>}
            </div>
        </div>
    );
}

export default AuthenticatedLayout;
