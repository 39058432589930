import { NextMaintenanceItemDashboardDto, NextMaintenancesDashboardDto } from 'api/dashboard/dtos/NextMaintenancesDashboardDto';
import { VehicleMaintenanceStates } from 'api/vehicleMaintenances/enums/VehicleMaintenanceStates';
import Badge, { BadgePreset } from 'common/components/badge/Badge';
import MoneyFormat from 'common/components/moneyFormat/MoneyFormat';
import { DATE_FORMAT_DEFAUT, DATE_FORMAT_MONTH_YEAR, DATE_TIME_FORMAT_DEFAULT } from 'Config';
import dayjs from 'dayjs';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styles from './NextMaintenances.module.scss';
import NoImage from 'assets/img/car.jpg';
import LoadingSpinner from 'common/components/loading/LoadingSpinner';


function getStatePreset(state: VehicleMaintenanceStates): BadgePreset {
	switch (state) {
		case VehicleMaintenanceStates.PREDICTED:
			return 'primaryDark';

		// case VehicleMaintenanceStates.OBLIGATORY:
		// 	return 'danger';

		case VehicleMaintenanceStates.PERFORM:
			return 'success';

		default:
			return 'primaryDark';
	}
}

type Props = {
	data: NextMaintenancesDashboardDto | null;
	isLoading?: boolean;
};

const NextMaintenances = ({ data, isLoading }: Props) => {
	const { t } = useTranslation();
	const history = useHistory();

	const imageCacheKey = useRef(new Date().getTime());
	
	if (!data && !isLoading) {
		return null;
	}

	if (isLoading) {
		return <div className={styles.noEnoughInfoText}><LoadingSpinner /></div>;
	} else if (!data?.items || data?.items.length === 0) {
		return <div className={styles.noEnoughInfoText}>{t('dashboard.no_enough_information')}</div>;
	}

	const onPress = (item: NextMaintenanceItemDashboardDto) => {
		history.push(`/maintenances/details/${item.id}`);
	};

	return (
		<div className={styles.container}>
			{data?.items.map((item, i) => {
				const statePreset = getStatePreset(item.state);

				return (
					<div key={i} className={styles.item} onClick={() => onPress(item)}>
						<div className={styles.contentImage}>
							<div className={styles.img} style={{ width: '5rem', height: '3rem', backgroundImage: item?.vehiclePhotoUrl ? 'url(' + item?.vehiclePhotoUrl + '?_=' + imageCacheKey.current + ')' : 'url(' + NoImage + ')' }} />
						</div>
						<div className={styles.contentData}>
							<div className={styles.itemRow}>
								<p className={styles.itemText}>{item.description}</p>
								{!item.expectedDate ?
									<Badge
										text={<>
											{t(('maintenances.states.' + item.state) as any)}
											{item.adviseKms && item.adviseDate ?
												<>{': '} <MoneyFormat value={item.adviseKms} suffix={t('vehicles_management.list.kilometers_minified')} /> {' ' + t('common.or') + ' ' + dayjs(item.adviseDate).format(DATE_FORMAT_MONTH_YEAR)} </>
												:
												item.adviseKms && !item.adviseDate ? <>{': '} <MoneyFormat value={item.adviseKms} suffix={t('vehicles_management.list.kilometers_minified')} /></>
													:
													!item.adviseKms && item.adviseDate ? <>{': ' + dayjs(item.adviseDate).format(DATE_FORMAT_MONTH_YEAR)}  </>
														:
														''}
										</>}
										preset={statePreset} />
									:
									< Badge text={t(('maintenances.states.' + item.state) as any)} preset={statePreset} />
								}
							</div>
							<div className={styles.itemRow}>
								<p className={styles.itemVehicleRegistrationNumberText}>
									{item.vehicleRegistrationNumber}
								</p>
								{item.expectedDate ? <p className={styles.dateText}>
									{t('common.at')} {item.expectedHour ?
										dayjs(item.expectedDate).format(DATE_TIME_FORMAT_DEFAULT)
										:
										dayjs(item.expectedDate).format(DATE_FORMAT_DEFAUT)}
								</p>
									:
									<p className={styles.dateText}>
										{t('maintenances.without_programming')}
									</p>
								}
							</div>
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default NextMaintenances;
