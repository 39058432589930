import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import styles from './VehicleFiltersScreen.module.scss';
import Button from '../../../common/components/button/Button';
import FormItem from '../../../common/components/formItem/FormItem';
import Label from '../../../common/components/label/Label';
import { Col, Row } from 'react-flexbox-grid';
import { SelectValueLabel } from '../../../common/types/SelectValueLabel';
import Select from 'common/components/select/Select';
import { Controller, useForm } from 'react-hook-form';
import VehicleSegmentsService from 'api/vehicleSegments/VehicleSegmentsService';
import Input from 'common/components/input/Input';
import RangeInputPicker from 'common/components/rangeInputPicker/RangeInputPicker';
import VehicleBrandsService from 'api/vehicleBrands/VehicleBrandsService';
import VehicleModelsService from 'api/vehicleModels/VehicleModelsService';
import { LOGGER_LOG_TYPE } from 'Config';
import { CatalogBrandsSearchCriteria } from 'api/catalog/models/CatalogBrandsSearchCriteria';
import { CatalogModelsSearchCriteria } from 'api/catalog/models/CatalogModelsSearchCriteria';
import Logger from 'common/services/Logger';
import { useToasts } from 'react-toast-notifications';
import ToggleSwitch from 'common/components/toggleSwitch/ToggleSwitch';
import DriversService from 'api/drivers/DriversService';
import LocalsService from 'api/locals/LocalsService';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import { UserProfile } from 'api/account/models/UserProfile';
import CompaniesService from 'api/companies/CompaniesService';
import { TrackingProvider } from 'api/tracking/enums/TrackingProvider';
import UsersService from 'api/users/UsersService';

export interface Filters {
    registrationNumber?: string;
    registrationDate?: Date | null;
    segmentId?: string;
    segmentName?: string;

    brandId?: string;
    brandName?: string;
    modelId?: string;
    modelName?: string;
    defaultDriverId?: string;
    defaultDriverName?: string;
    reservationAvailable?: boolean;
    hasGps?: boolean;

    driverId?: string;
    driverName?: string;
    costAverage?: number[];
    costAccumulated?: number[];
    costsStartDate?: Date;
    costsEndDate?: Date;

    immobilized?: boolean;
    localId?: string;
    localName?: string;

    inactives?: boolean;
    page?: number;
    allIn?: string;
    tags?: string;
}

type Props = {
    filters: Filters;
    hasCountersPolicy?: boolean;
    onChange: (filters: Filters) => void;
    onFilter: (filters: Filters) => void;
}

const VehicleFiltersScreen: React.FC<Props> = ({ filters, hasCountersPolicy, onChange, onFilter }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();

    const form = useForm<Filters>({ shouldUnregister: false, shouldFocusError: true, defaultValues: filters });
    const { getValues, setValue, reset, watch, control } = form;

    const [segmentsOptions, setSegments] = useState<SelectValueLabel[]>([]);
    //const [driversOptions, setDrivers] = useState<SelectValueLabel[]>([]);
    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const [localsOptions, setLocalsOptions] = useState<SelectValueLabel[]>([]);
    const [brandsOptions, setBrandsOptions] = useState<SelectValueLabel[]>([]);
    const [modelsOptions, setModelsOptions] = useState<SelectValueLabel[]>([]);
    const [driversOptions, setDriversOptions] = useState<SelectValueLabel[]>([]);
    const [hasGps, setHasGps] = useState<boolean>(false);

    const hasGpsReadPolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_GPS_READ']);

    const getData = async () => {
        const [
            segmentsOptionsDB,
            brands,
            models,
            drivers,
            locals,
            company
        ] = await Promise.all([
            VehicleSegmentsService.catalog(),
            VehicleBrandsService.catalog(),
            VehicleModelsService.catalog(),
            DriversService.catalog(),
            LocalsService.catalog(),
            CompaniesService.getCurrent()
        ]);
        setSegments(segmentsOptionsDB);
        setBrandsOptions(brands);
        setModelsOptions(models);
        setDriversOptions(drivers);
        setLocalsOptions(locals);
        setHasGps(company && company.trackingProvider !== TrackingProvider.NONE && hasGpsReadPolicy);
    }

    useEffect(() => {
        getData().then(
            () => {
                if (filters.brandId != undefined) {
                    setInitialModels();
                }

                if (filters.modelId != undefined) {
                    setInitialBrands();
                }
            }
        );
    }, []);

    const setInitialModels = async () => {
        const models = await VehicleModelsService.catalog({ brandId: getValues('brandId') } as CatalogModelsSearchCriteria);
        setModelsOptions(models);
    }

    const setInitialBrands = async () => {
        const brand = await VehicleBrandsService.catalog({ modelId: getValues('modelId') } as CatalogBrandsSearchCriteria);
        setBrandsOptions(brand);
    }

    const clearFilters = () => {
        reset({
            registrationNumber: undefined,
            registrationDate: undefined,
            segmentId: undefined,
            brandId: undefined,
            modelId: undefined,
            defaultDriverId: undefined,
            reservationAvailable: undefined,
            costAverage: undefined,
            costAccumulated: undefined,
            immobilized: undefined,
            localId: undefined,
            inactives: undefined
        });
        onInputChange();
        onSubmit(getValues());
    }

    const onSubmit = (f: Filters) => {
        onFilter(f);
    }

    const onInputChange = () => {
        onChange(getValues());
    }

    const getVal = (arr: any[] | undefined, index: number): any | undefined => {
        return arr && arr.length > index ? arr[index] : undefined;
    }


    const getModelByBrand = async () => {
        try {
            const models = await VehicleModelsService.catalog({ brandId: getValues('brandId') } as CatalogModelsSearchCriteria);
            setModelsOptions(models);
            if (!getValues('modelId') && models.length == 1) {
                setValue('modelId', models.find(x => x.value)?.value);
                setValue('modelName', models.find(x => x.value)?.label);
                getBrandByModel();
            }
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.VEHICLES, `Couldn't get models of brand`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        }
    }

    const getBrandByModel = async () => {
        try {
            const brand = await VehicleBrandsService.catalog({ modelId: getValues('modelId') } as CatalogBrandsSearchCriteria);
            setBrandsOptions(brand);
            if (!getValues('brandId') && brand.length == 1) {
                setValue('brandId', brand.find(x => x.value)?.value);
                setValue('brandName', brand.find(x => x.value)?.label);
                getModelByBrand();
            }
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.VEHICLES, `Couldn't get brands of model`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        }
    }

    return (
        <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className={styles.formContent}>
                <div>
                    <FormItem>
                        <Row>
                            <Col xs={hasCountersPolicy ? 6 : 12}>
                                <FormItem>
                                    <Label className={styles.bold}>{t('vehicle.registration')}</Label>
                                    <Input
                                        maxLength={255}
                                        name="registrationNumber"
                                        onKeyUp={e => {
                                            setValue('registrationNumber', e.currentTarget.value != '' ? e.currentTarget.value : null);
                                            onInputChange();
                                        }}
                                        placeholder={t('vehicle.registration')}
                                        autoComplete='off'
                                        defaultValue={watch('registrationNumber')} />
                                </FormItem>
                            </Col>
                            <Col xs={hasCountersPolicy ? 6 : 12}>
                                <FormItem>
                                    <Label className={styles.bold}>{t('vehicle.type')}</Label>
                                    <Controller
                                        render={({ onChange: onSelectChange, value }) => {
                                            return (
                                                <Select
                                                    options={segmentsOptions}
                                                    isClearable
                                                    placeholder={t('vehicle.type')}
                                                    onChange={(data: SelectValueLabel) => {
                                                        onSelectChange(data?.value);
                                                        setValue('segmentName', data?.label);
                                                        onInputChange();
                                                    }}
                                                    value={segmentsOptions.find(x => x.value === value) ? { label: segmentsOptions.find(x => x.value === value)?.label ?? '', value: value } : null}
                                                />
                                            );
                                        }}
                                        control={control}
                                        name="segmentId"
                                        defaultValue={getValues('segmentId')} />
                                </FormItem>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={12} md={6}>
                                <FormItem>
                                    <Label className={styles.bold}>{t('vehicle.brand')}</Label>
                                    <Controller
                                        render={({ onChange: onSelectChange, value }) => {
                                            return (
                                                <Select
                                                    options={brandsOptions}
                                                    isClearable
                                                    placeholder={t('vehicle.brand')}
                                                    onChange={(data: SelectValueLabel) => {
                                                        onSelectChange(data?.value);
                                                        setValue('brandName', data?.label);
                                                        getModelByBrand();
                                                        onInputChange();
                                                    }}
                                                    value={brandsOptions.find(x => x.value === value) ? { label: brandsOptions.find(x => x.value === value)?.label ?? '', value: value } : null}
                                                />
                                            );
                                        }}
                                        control={control}
                                        name="brandId"
                                        defaultValue={getValues('brandId')} />
                                </FormItem>
                            </Col>
                            <Col sm={12} md={6}>
                                <FormItem>
                                    <Label className={styles.bold}>{t('vehicle.model')}</Label>

                                    <Controller
                                        render={({ onChange: onSelectChange, value }) => {
                                            return (
                                                <Select
                                                    options={modelsOptions}
                                                    isClearable
                                                    placeholder={t('vehicle.model')}
                                                    onChange={(data: SelectValueLabel) => {
                                                        onSelectChange(data?.value);
                                                        setValue('modelName', data?.label);
                                                        getBrandByModel();
                                                        onInputChange();
                                                    }}
                                                    value={modelsOptions.find(x => x.value === value) ? { label: modelsOptions.find(x => x.value === value)?.label ?? '', value: value } : null}
                                                />
                                            );
                                        }}
                                        control={control}
                                        name="modelId"
                                        defaultValue={getValues('modelId')} />
                                </FormItem>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={12} md={6}>
                                <FormItem>
                                    <Label className={styles.bold}>{t('vehicle.local')}</Label>
                                    <Controller
                                        render={({ onChange: onSelectChange, value }) => {
                                            return (
                                                <Select
                                                    options={localsOptions}
                                                    isClearable
                                                    placeholder={t('vehicle.local')}
                                                    onChange={(data: SelectValueLabel) => {
                                                        onSelectChange(data?.value);
                                                        setValue('localName', data?.label);
                                                        onInputChange();
                                                    }}
                                                    value={localsOptions.find(x => x.value === value) ? { label: localsOptions.find(x => x.value === value)?.label ?? '', value: value } : null}
                                                />
                                            );
                                        }}
                                        control={control}
                                        name="localId"
                                        defaultValue={getValues('localId')} />
                                </FormItem>
                            </Col>
                            <Col sm={12} md={6}>
                                <FormItem>
                                    <Label className={styles.bold}>{t('vehicle.default_driver')}</Label>
                                    <Controller
                                        render={({ onChange: onSelectChange, value }) => {
                                            return (
                                                <Select
                                                    options={driversOptions}
                                                    isClearable
                                                    placeholder={t('vehicle.default_driver')}
                                                    onChange={(data: SelectValueLabel) => {
                                                        onSelectChange(data?.value);
                                                        setValue('defaultDriverName', data?.label);
                                                        onInputChange();
                                                    }}
                                                    value={driversOptions.find(x => x.value === value) ? { label: driversOptions.find(x => x.value === value)?.label ?? '', value: value } : null}
                                                />
                                            );
                                        }}
                                        control={control}
                                        name="defaultDriverId"
                                        defaultValue={getValues('defaultDriverId')} />
                                </FormItem>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={12} md={6}>
                                <FormItem>
                                    <Label className={styles.bold}>{t('vehicle.available_for_reservation')}</Label>
                                    <ToggleSwitch
                                        name="reservationAvailable"
                                        form={form}
                                    />
                                </FormItem>
                            </Col>

                            {hasGps && <Col sm={12} md={6}>
                                <FormItem>
                                    <Label className={styles.bold}>{t('vehicle.has_gps')}</Label>
                                    <ToggleSwitch
                                        name="hasGps"
                                        form={form}
                                    />
                                </FormItem>
                            </Col>}

                            <Col sm={12} md={hasGps ? 6 : 3}>
                                <FormItem>
                                    <Label className={styles.bold}>{t('vehicle.immobilized')}</Label>
                                    <ToggleSwitch
                                        name="immobilized"
                                        form={form}
                                    />
                                </FormItem>
                            </Col>

                            <Col sm={12} md={hasGps ? 6 : 3}>
                                <FormItem>
                                    <Label className={styles.bold}>{t('vehicle.inactives')}</Label>
                                    <ToggleSwitch
                                        name="inactives"
                                        form={form}
                                    />
                                </FormItem>
                            </Col>

                        </Row>

                        {hasCountersPolicy && <Row>
                            <Col xs={12}>
                                <RangeInputPicker
                                    key={'costAverage'}
                                    label={t('vehicles.list.average_monthly_cost', { vat: (userProfile?.useValueWithVat ? t('common.with_vat') : t('common.without_vat')) })}
                                    onChange={(startValue: any, endValue: any) => {
                                        const range = [];
                                        range.push(startValue || startValue === 0 ? Number(startValue) : undefined);
                                        range.push(endValue || endValue === 0 ? Number(endValue) : undefined);
                                        setValue('costAverage', range);
                                        onInputChange();
                                    }}
                                    defaultStartValue={getVal(getValues('costAverage'), 0)}
                                    defaultEndValue={getVal(getValues('costAverage'), 1)}
                                    groupSymbol={t('common.euro')}
                                />
                            </Col>
                            <Col xs={12}>
                                <RangeInputPicker
                                    key={'costAccumulated'}
                                    label={t('vehicles.list.total_monthly_cost', { vat: (userProfile?.useValueWithVat ? t('common.with_vat') : t('common.without_vat')) })}
                                    onChange={(startValue: any, endValue: any) => {
                                        const range = [];
                                        range.push(startValue || startValue === 0 ? Number(startValue) : undefined);
                                        range.push(endValue || endValue === 0 ? Number(endValue) : undefined);
                                        setValue('costAccumulated', range);
                                        onInputChange();
                                    }}
                                    defaultStartValue={getVal(getValues('costAccumulated'), 0)}
                                    defaultEndValue={getVal(getValues('costAccumulated'), 1)}
                                    groupSymbol={t('common.euro')}
                                />
                            </Col>
                            <Col xs={12}>
                                <RangeInputPicker
                                    label={t('vehicles.list.total_monthly_cost_date', { vat: (userProfile?.useValueWithVat ? t('common.with_vat') : t('common.without_vat')) })}
                                    onChange={(startValue: any, endValue: any) => {
                                        form.setValue('costsStartDate', startValue ? moment(startValue).toDate() : undefined);
                                        form.setValue('costsEndDate', endValue ? moment(endValue).toDate() : undefined);
                                        onInputChange();
                                    }}
                                    defaultStartValue={form.getValues('costsStartDate')}
                                    defaultEndValue={form.getValues('costsEndDate')}
                                    dateFormat="MM/yyyy"
                                    isDatePicker
                                    showMonthYearPicker
                                />
                            </Col>
                        </Row>}
                    </FormItem>
                </div>

                <div className={styles.buttonsFooter}>
                    <FormItem>
                        <Button
                            text={t('common.remove')}
                            size={'normal'}
                            preset={'secondary'}
                            onClick={clearFilters}
                            type='reset' />
                        <Button
                            type='submit'
                            text={t('common.apply')}
                            size={'normal'}
                        />
                    </FormItem>
                </div>
            </div>
        </form>
    );
}

export default memo(VehicleFiltersScreen);