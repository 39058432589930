import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './DriversFilters.module.scss';
import Button from '../../../common/components/button/Button';
import FormItem from '../../../common/components/formItem/FormItem';
import Label from '../../../common/components/label/Label';
import { Col, Row } from 'react-flexbox-grid';
import { SelectValueLabel } from '../../../common/types/SelectValueLabel';
import Select from 'common/components/select/Select';
import { Controller, useForm } from 'react-hook-form';
import DriversService from 'api/drivers/DriversService';
import RangeInputPicker from 'common/components/rangeInputPicker/RangeInputPicker';
import VehiclesService from 'api/vehicles/VehiclesService';
import moment from 'moment';

export interface Filters {
    driverId?: string;
    driverName?: string;
    vehicleId?: string;
    vehicleName?: string;
    refunds?: number[];
    fines?: number[];
    accidents?: number[];
    startDate?: Date | null;
    endDate?: Date | null;
    page?:number;
}

type Props = {
    filters: Filters;
    onChange: (filters: Filters) => void;
    onFilter: (filters: Filters) => void;
    isDriver?: boolean;
}

const DriversFilters: React.FC<Props> = ({ filters, onChange, onFilter, isDriver }: Props) => {
    const { t } = useTranslation();
    const form = useForm<Filters>({ shouldUnregister: false, shouldFocusError: true, defaultValues: filters });
    const { getValues, setValue } = form;
    const [driversOptions, setDrivers] = useState<SelectValueLabel[]>([]);
    const [vehiclesOptions, setVehicles] = useState<SelectValueLabel[]>([]);

    const getData = async () => {
        const [_driversOptions, _vehiclesOptions] = await Promise.all([
            DriversService.catalog(),
            VehiclesService.catalog()
        ]);
        setDrivers(_driversOptions);
        setVehicles(_vehiclesOptions);
    }

    useEffect(() => {
        getData();
    }, []);

    const clearFilters = () => {
        form.reset({
            driverId: undefined,
            driverName: undefined,
            refunds: undefined,
            fines: undefined,
            accidents: undefined,
            startDate: undefined,
            endDate: undefined
        });
        onInputChange();
        onSubmit(form.getValues());
    }

    const onSubmit = (f: Filters) => {
        onFilter(f);
    }

    const onInputChange = () => {
        onChange(form.getValues());
    }

    const getVal = (arr: any[] | undefined, index: number): any | undefined => {
        return arr && arr.length > index ? arr[index] : undefined;
    }

    return (
        <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className={styles.formContent}>
                <div>
                    <FormItem>
                        <Row>
                            <Col xs={12}>
                                <RangeInputPicker
                                    label={t('common.date')}
                                    onChange={(startValue: any, endValue: any) => {
                                        form.setValue('startDate', startValue ? moment(startValue).toDate() : undefined);
                                        form.setValue('endDate', endValue ? moment(endValue).toDate() : undefined);
                                        onInputChange();
                                    }}
                                    defaultStartValue={form.getValues('startDate')}
                                    defaultEndValue={form.getValues('endDate')}
                                    isDatePicker
                                />
                            </Col>
                            <Col xs={12} md={6}>
                                <FormItem>
                                    <Label className={styles.bold}>{t('drivers.list.vehicle')}</Label>
                                    <Controller
                                        render={({ onChange: onSelectChange, value }) => {
                                            return (
                                                <Select
                                                    options={vehiclesOptions}
                                                    isClearable
                                                    placeholder={t('vehicle.vehicle')}
                                                    onChange={(data: SelectValueLabel) => {
                                                        onSelectChange(data?.value);
                                                        setValue('vehicleName', data?.label);
                                                        onInputChange();
                                                    }}
                                                    value={vehiclesOptions.find(x => x.value === value) ? { label: vehiclesOptions.find(x => x.value === value)?.label ?? '', value: value } : null}
                                                />
                                            );
                                        }}
                                        control={form.control}
                                        name="vehicleId"
                                        defaultValue={getValues('vehicleId')} />
                                </FormItem>
                            </Col>
                            {!isDriver && <Col xs={12} md={6}>
                                <FormItem>
                                    <Label className={styles.bold}>{t('common.driver')}</Label>
                                    <Controller
                                        render={({ onChange: onSelectChange, value }) => {
                                            return (
                                                <Select
                                                    options={driversOptions}
                                                    isClearable
                                                    placeholder={t('common.driver')}
                                                    onChange={(data: SelectValueLabel) => {
                                                        onSelectChange(data?.value);
                                                        setValue('driverName', data?.label);
                                                        onInputChange();
                                                    }}
                                                    value={driversOptions.find(x => x.value === value) ? { label: driversOptions.find(x => x.value === value)?.label ?? '', value: value } : null}
                                                />
                                            );
                                        }}
                                        control={form.control}
                                        name="driverId"
                                        defaultValue={form.getValues('driverId')} />
                                </FormItem>
                            </Col>}
                            {!isDriver && <Col xs={12}>
                                <RangeInputPicker
                                    key={'refunds'}
                                    label={t('drivers.list.refunds')}
                                    onChange={(startValue: any, endValue: any) => {
                                        const range = [];
                                        range.push(startValue || startValue === 0 ? Number(startValue) : undefined);
                                        range.push(endValue || endValue == 0 ? Number(endValue) : undefined);
                                        setValue('refunds', range);
                                        onInputChange();
                                    }}
                                    defaultStartValue={getVal(getValues('refunds'), 0)}
                                    defaultEndValue={getVal(getValues('refunds'), 1)}
                                    groupSymbol={t('common.euro')}
                                />
                            </Col>}
                            {!isDriver && <Col xs={12}>
                                <RangeInputPicker
                                    key={'fines'}
                                    type={'number'}
                                    label={t('drivers.filter.fines')}
                                    onChange={(startValue: any, endValue: any) => {
                                        const range = [];
                                        range.push(startValue || startValue === 0 ? Number(startValue) : undefined);
                                        range.push(endValue || endValue == 0 ? Number(endValue) : undefined);
                                        setValue('fines', range);
                                        onInputChange();
                                    }}
                                    defaultStartValue={getVal(getValues('fines'), 0)}
                                    defaultEndValue={getVal(getValues('fines'), 1)}
                                />
                            </Col>}
                            {!isDriver && <Col xs={12}>
                                <RangeInputPicker
                                    key={'accidents'}
                                    type={'number'}
                                    label={t('drivers.filter.accidents')}
                                    onChange={(startValue: any, endValue: any) => {
                                        const range = [];
                                        range.push(startValue || startValue === 0 ? Number(startValue) : undefined);
                                        range.push(endValue || endValue == 0 ? Number(endValue) : undefined);
                                        setValue('accidents', range);
                                        onInputChange();
                                    }}
                                    defaultStartValue={getVal(getValues('accidents'), 0)}
                                    defaultEndValue={getVal(getValues('accidents'), 1)}
                                />
                            </Col> }
                        </Row>
                    </FormItem>
                </div>
                <div className={styles.buttonsFooter}>
                    <FormItem>
                        <Button
                            text={t('common.remove')}
                            size={'normal'}
                            preset={'secondary'}
                            onClick={clearFilters}
                            type='reset' />
                        <Button
                            type='submit'
                            text={t('common.apply')}
                            size={'normal'}
                        />
                    </FormItem>
                </div>
            </div>
        </form>
    );
}

export default memo(DriversFilters);