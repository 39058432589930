import Http from '../../common/services/Http';
import { SelectValueGenericTypeDto } from './models/SelectValueGenericTypeDto';

class GenericTypesService {
    public catalogSupplier() {
        return Http.get<SelectValueGenericTypeDto[]>('genericTypes/catalogsupplier');
    }

    public catalogCharge() {
        return Http.get<SelectValueGenericTypeDto[]>('genericTypes/catalogcharge');
    }

    public catalogChargeBySupplierId(supplierId: string) {
        return Http.get<SelectValueGenericTypeDto[]>('genericTypes/catalogchargebysupplierid/'+ supplierId);
    }
}

export default new GenericTypesService();