import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import styles from './MaintenancesFiltersScreen.module.scss';
import Button from 'common/components/button/Button';
import FormItem from 'common/components/formItem/FormItem';
import Label from 'common/components/label/Label';
import { Col, Row } from 'react-flexbox-grid';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import VehiclesService from 'api/vehicles/VehiclesService';
import Select from 'common/components/select/Select';
import { Controller, useForm } from 'react-hook-form';
import UsersService from 'api/users/UsersService';
import { UsersSelectItemDto } from 'api/users/models/UserDto';
import SuppliersService from 'api/suppliers/SuppliersService';
import { GenericType } from 'api/genericTypes/enums/GenericType';
import { CatalogSupplierSearchCriteria } from 'api/catalog/models/CatalogSupplierSearchCriteria';
import { VehicleMaintenanceStates } from 'api/vehicleMaintenances/enums/VehicleMaintenanceStates';
import MaintenanceContractsService from 'api/contracts/maintenanceContract/MaintenanceContractsService';
import RangeInputPicker from 'common/components/rangeInputPicker/RangeInputPicker';
import ToggleSwitch from 'common/components/toggleSwitch/ToggleSwitch';
import Loading from 'common/services/Loading';
import Logger from 'common/services/Logger';
import { LOGGER_LOG_TYPE } from 'Config';
import { useToasts } from 'react-toast-notifications';

export interface Filters {
    startDate?: Date;
    endDate?: Date;
    vehicleId?: string;
    vehicleName?: string;
    typeId?: string;
    typeName?: string;
    responsibleId?: string;
    responsibleName?: string;
    supplierId?: string;
    supplierName?: string;
    currentState?: string;
    currentStateName?: string;
    executedStartDate?: Date;
    executedEndDate?: Date;
    vehiclesInactive?: boolean;
    page?: number;
}

type Props = {
    filters: Filters;
    genericFilter?: boolean;
    onChange?: (filters: Filters) => void;
    onFilter: (filters: Filters) => void;
}

const MaintenancesFiltersScreen: React.FC<Props> = ({ filters, genericFilter = false, onChange, onFilter }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();

    const form = useForm<Filters>({ shouldUnregister: false, shouldFocusError: true, defaultValues: filters });
    const { setValue, getValues } = form;
    const [typesOptions, setTypes] = useState<SelectValueLabel[]>([]);
    const [vehiclesOptions, setVehicles] = useState<SelectValueLabel[]>([]);
    const [responsiblesOptions, setResponsibles] = useState<SelectValueLabel[]>([]);
    const [suppliersOptions, setSuppliers] = useState<SelectValueLabel[]>([]);
    const [statesOptions, setStatesOptions] = useState<SelectValueLabel[]>([]);

    const getData = async () => {
        try {
            Loading.show();

            const [types, vehiclesOptionsList, responsiblesOptionsList, suppliersOptionsList] = await Promise.all([
                MaintenanceContractsService.getMaintenanceContractTypes(),
                VehiclesService.catalogByMaintenances(),
                UsersService.getAllForCompany(),
                SuppliersService.catalog({ typeName: GenericType.MAINTENANCE } as CatalogSupplierSearchCriteria)
            ]);

            setTypes(types.map(x=> {
                return {
                    ...x,
                    label: t(`common.maintenance_contract_type.${x.label}` as any)
                }
            }));
            setVehicles(vehiclesOptionsList);
            setSuppliers(suppliersOptionsList);
            setResponsibles((responsiblesOptionsList || []).map((x: UsersSelectItemDto) => ({ value: x.id || '', label: x.realName || '' })));

            const states: SelectValueLabel[] = [
                { value: VehicleMaintenanceStates.PREDICTED, label: t('maintenances.states.PREDICTED') },
                { value: VehicleMaintenanceStates.PERFORM, label: t('maintenances.states.PERFORM') }];

            setStatesOptions(states);

        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.VEHICLES, `Couldn't get maintenances filter`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        }
        finally{
            Loading.hide();
        }
    }

    useEffect(() => {
        getData();
    }, []);

    const clearFilters = () => {
        form.reset({
            startDate: undefined,
            responsibleId: undefined,
            responsibleName: undefined,
            vehicleId: undefined,
            vehicleName: undefined,
            typeId: undefined,
            typeName: undefined,
            supplierId: undefined,
            supplierName: undefined,
            currentState: undefined,
            currentStateName: undefined,
            executedStartDate: undefined,
            executedEndDate: undefined,
            vehiclesInactive: false
        });
        onInputChange();
        onSubmit(form.getValues());
    }

    const onSubmit = (_filters: Filters) => {
        onFilter(_filters);
    }

    const onInputChange = () => {
        if (onChange) {
            onChange(form.getValues());
        }
    }

    return (
        <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className={styles.formContent}>
                <div>
                    <FormItem>
                        {genericFilter && <Row>
                            <Col xs={12}>
                                <FormItem>
                                    <Label className={styles.bold}>{t('vehicle.vehicle')}</Label>
                                    <Controller
                                        render={({ onChange: onSelectChange, value }) => {
                                            return (
                                                <Select
                                                    options={vehiclesOptions}
                                                    isClearable
                                                    placeholder={t('vehicle.vehicle')}
                                                    onChange={(data: SelectValueLabel) => {
                                                        onSelectChange(data?.value);
                                                        setValue('vehicleName', data?.label);
                                                        onInputChange();
                                                    }}
                                                    value={vehiclesOptions.find(x => x.value === value) ?? null}
                                                />
                                            );
                                        }}
                                        control={form.control}
                                        name="vehicleId"
                                        defaultValue={getValues('vehicleId')} />
                                </FormItem>
                            </Col>
                        </Row>}

                        <Row>
                            <Col xs={6}>
                                <FormItem>
                                    <Label className={styles.bold}>{t('common.type')}</Label>
                                    <Controller
                                        render={({ onChange: onSelectChange, value }) => {
                                            return (
                                                <Select
                                                    options={typesOptions}
                                                    isClearable
                                                    placeholder={t('common.type')}
                                                    onChange={(data: SelectValueLabel) => {
                                                        onSelectChange(data?.value);
                                                        setValue('typeName', data?.label);
                                                        onInputChange();
                                                    }}
                                                    value={typesOptions.find(x => x.value === value) ? { label: typesOptions.find(x => x.value === value)?.label ?? '', value: value } : null}
                                                />
                                            );
                                        }}
                                        control={form.control}
                                        name="typeId"
                                        defaultValue={form.getValues('typeId')} />
                                </FormItem>
                            </Col>

                            <Col xs={6}>
                                <FormItem>
                                    <Label className={styles.bold}>{t('common.state')}</Label>
                                    <Controller
                                        render={({ onChange: onSelectChange, value }) => {
                                            return (
                                                <Select
                                                    options={statesOptions}
                                                    isClearable
                                                    placeholder={t('common.state')}
                                                    onChange={(data: SelectValueLabel) => {
                                                        onSelectChange(data?.value);
                                                        setValue('currentStateName', data?.label);
                                                        onInputChange();
                                                    }}
                                                    value={statesOptions.find(x => x.value === value) ? { label: statesOptions.find(x => x.value === value)?.label ?? '', value: value } : null}
                                                />
                                            );
                                        }}
                                        control={form.control}
                                        name="currentState"
                                        defaultValue={form.getValues('currentState')} />
                                </FormItem>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={6}>
                                <FormItem>
                                    <Label className={styles.bold}>{t('common.assigned_to')}</Label>
                                    <Controller
                                        render={({ onChange: onSelectChange, value }) => {
                                            return (
                                                <Select
                                                    options={responsiblesOptions}
                                                    isClearable
                                                    placeholder={t('common.assigned_to')}
                                                    onChange={(data: SelectValueLabel) => {
                                                        onSelectChange(data?.value);
                                                        setValue('responsibleName', data?.label);
                                                        onInputChange();
                                                    }}
                                                    value={responsiblesOptions.find(x => x.value === value) ?? null}
                                                />
                                            );
                                        }}
                                        control={form.control}
                                        name="responsibleId"
                                        defaultValue={getValues('responsibleId')} />
                                </FormItem>
                            </Col>

                            <Col xs={6}>
                                <FormItem>
                                    <Label className={styles.bold}>{t('common.supplier')}</Label>
                                    <Controller
                                        render={({ onChange: onSelectChange, value }) => {
                                            return (
                                                <Select
                                                    options={suppliersOptions}
                                                    isClearable
                                                    placeholder={t('common.supplier')}
                                                    onChange={(data: SelectValueLabel) => {
                                                        onSelectChange(data?.value);
                                                        setValue('supplierName', data?.label);
                                                        onInputChange();
                                                    }}
                                                    value={suppliersOptions.find(x => x.value === value) ?? null}
                                                />
                                            );
                                        }}
                                        control={form.control}
                                        name="supplierId"
                                        defaultValue={getValues('supplierId')} />
                                </FormItem>
                            </Col>
                        </Row>


                        <Row>
                            <Col xs={12}>
                                <FormItem>
                                    <RangeInputPicker
                                        label={t('maintenances.date_maintenance')}
                                        onChange={(startValue: any, endValue: any) => {
                                            form.setValue('executedStartDate', startValue ? moment(startValue).toDate() : undefined);
                                            form.setValue('executedEndDate', endValue ? moment(endValue).toDate() : undefined);
                                            onInputChange();
                                        }}
                                        defaultStartValue={form.getValues('executedStartDate')}
                                        defaultEndValue={form.getValues('executedEndDate')}
                                        isDatePicker
                                    />
                                </FormItem>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12}>
                                <FormItem>
                                    <RangeInputPicker
                                        label={t('maintenances.expected_date')}
                                        onChange={(startValue: any, endValue: any) => {
                                            form.setValue('startDate', startValue ? moment(startValue).toDate() : undefined);
                                            form.setValue('endDate', endValue ? moment(endValue).toDate() : undefined);
                                            onInputChange();
                                        }}
                                        defaultStartValue={form.getValues('startDate')}
                                        defaultEndValue={form.getValues('endDate')}
                                        isDatePicker
                                    />
                                </FormItem>
                            </Col>
                        </Row>

                        {genericFilter && <Row>
                            <Col xs={6}>
                                <FormItem>
                                    <Label className={styles.bold}>{t('maintenances.vehicle_inactives')}</Label>
                                    <ToggleSwitch
                                        name="vehiclesInactive"
                                        form={form}
                                    />
                                </FormItem>
                            </Col>
                        </Row>}

                    </FormItem>
                </div>
                <div className={styles.buttonsFooter}>
                    <FormItem>
                        <Button
                            text={t('common.remove')}
                            size={'normal'}
                            preset={'secondary'}
                            onClick={clearFilters}
                            type='reset' />
                        <Button
                            type='submit'
                            text={t('common.apply')}
                            size={'normal'}
                        />
                    </FormItem>
                </div>
            </div>
        </form>
    );
}

export default memo(MaintenancesFiltersScreen);