import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import { DEFAULT_PAGINATION_ITEMS_PER_PAGE, LOGGER_LOG_TYPE } from 'Config';
import DriversService from 'api/drivers/DriversService';
import { DriversListItemDto } from 'api/drivers/models/DriversListItemDto';
import { DriversTotalsDto } from 'api/drivers/models/DriversTotalsDto';
import { DriversSearchCriteria } from 'api/drivers/models/DriversSearchCriteria';
import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';
import Logger from 'common/services/Logger';
import Loading from 'common/services/Loading';
import ScreenHeader from 'common/components/screenHeader/ScreenHeader';
import ScreenHeaderButton from 'common/components/screenHeader/ScreenHeaderButton';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import ScreenContainer from 'common/components/screenContainer/ScreenContainer';
import Box from 'common/components/box/Box';
import ListingTable, { ListingTableColumn } from 'common/components/listingTable/ListingTable';
import PaginationWithInfo from 'common/components/pagination/PaginationWithInfo';
import Popover from 'common/components/popover/Popover';
import MoneyFormat from 'common/components/moneyFormat/MoneyFormat';
import FilterList from 'common/components/filterList/FilterList';
import AvatarWithText from 'common/components/avatar/AvatarWithText';
import CounterBoxDriver from '../driver/components/counterBoxDriver/CounterBoxDriver';
import NoImage from 'assets/svg/semfoto.svg';
import FiltersIcon from 'assets/svg/desktop_filtres.svg';
import AddIcon from 'assets/svg/desktop_add_new.svg';
import DriversFilters, { Filters } from './components/DriversFilters';
import styles from './DriversScreen.module.scss';
import DateFormat from 'common/components/dateFormat/dateFormat';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import Dropdown from 'common/components/dropdown/Dropdown';
import DropdownItem from 'common/components/dropdown/DropdownItem';
import { FaEllipsisH } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import IconWithText from 'common/components/iconWithText/IconWithText';
import GaugeIcon from 'assets/svg/gauge.svg';

const DriversScreen = () => {
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const imageCacheKey = useRef(new Date().getTime());

    const [criteria, setCriteria] = useState<DriversSearchCriteria>({
        itemsPerPage: DEFAULT_PAGINATION_ITEMS_PER_PAGE,
        page: 1,
        orderColumn: 'u.real_name',
        orderBy: 'asc'
    });
    const [drivers, setDrivers] = useState<DriversListItemDto[]>([]);
    const history = useHistory();
    const [totalItems, setTotalItems] = useState(0);
    const [filters, setFilters] = useState<Filters>({});
    const [filtersTotal, setFiltersTotal] = useState(0);
    const [totals, setTotals] = useState<DriversTotalsDto>({ averageKilometers: 0, totalRefunds: 0, totalFines: 0, totalAccidents: 0 });
    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasUsersWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_USERS_WRITE']);

    const [itemToRemove, setItemToRemove] = useState<DriversListItemDto | null>(null);
    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);

    const getData = async () => {
        try {
            Loading.show();

            const [driversItems, _totals] = await Promise.all([
                DriversService.getList(criteria),
                DriversService.getTotals(criteria)
            ]);

            setTotalItems(driversItems.totalItems);
            setDrivers(driversItems.items);
            setTotals({ ..._totals });

            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.DRIVERS, `Couldn't get drivers list`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    }

    const onTableFilter = (field: string, isFilterAsc: boolean) => {
        setCriteria({ ...criteria, page: 1, orderBy: (isFilterAsc ? 'asc' : 'desc'), orderColumn: field });
    }

    const renderImageDescCell = (row: DriversListItemDto) => {
        return (
            <>
                <div className={styles.imageDescCell}>
                    <AvatarWithText src={row.photoUrl}
                        size="x2"
                        hideAvatar={!row.name}><div className={styles.bold}>{row.name || '-'}</div></AvatarWithText>
                </div>
                <div className={styles.emailDriver}>{row.email}</div>
            </>
        )
    }

    const renderTableActionCell = (
        row: DriversListItemDto,
    ) => {
        const urlDetails = `drivers/details/${row.id}`;
        const urlEdit = `drivers/edit/${row.id}`;


        return (
            <Dropdown
                options={
                    <>
                        <DropdownItem url={urlDetails}>
                            {t('common.details')}
                        </DropdownItem>
                        { hasUsersWritePolicy && <DropdownItem url={urlEdit}>
                            {t('common.edit')}
                        </DropdownItem>}
                        { hasUsersWritePolicy && <DropdownItem onClick={() => showRemoveItemDialog(row)}>
                            {t('common.remove')}
                        </DropdownItem>}
                    </>
                }
            >
                <div>
                    <FaEllipsisH />
                </div>
            </Dropdown>
        );
    };

    const showRemoveItemDialog = (item: DriversListItemDto) => {
        setItemToRemove(item);
        setShowRemoveModal(true);
    };

    const onCancelRemove = () => {
        setItemToRemove(null);
        setShowRemoveModal(false);
    };

    const onRemove = async () => {
        if (itemToRemove === null) {
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
            return;
        }

        try {
            await UsersService.remove(itemToRemove as any);
            setCriteria({ ...criteria, page: 1 });
            onCancelRemove();
            addToast(t('common.messages.record_delete_success'), {
                appearance: 'success',
            });
        }
        catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't delete charge`, error);
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
        }
    };

    const tableColumns: ListingTableColumn<DriversListItemDto>[] = useMemo(() => {
        const cols: ListingTableColumn<DriversListItemDto>[] = [
            {
                name: t('drivers.list.driver'),
                onSearch: onTableFilter,
                searchField: 'u.real_name',
                renderCell: renderImageDescCell,
                width: 'fill'
            },
            {
                renderCell: row =>
                    <div className={styles.imageDescCell}>
                        <div className={styles.contentImage} style={{ width: '4rem', height: '3rem' }}>
                            {!!row?.vehiclePhotoUrl && <div className={styles.img} style={{ width: '4rem', height: '3rem', backgroundImage: row?.vehiclePhotoUrl ? 'url(' + row?.vehiclePhotoUrl + '?_=' + imageCacheKey.current + ')' : 'url(' + NoImage + ')' }} />}
                        </div>
                    </div>,
                width: 'fill',
                hideOn: ['sm']
            },
            {
                name: t('drivers.list.vehicle_on_travel'),
                renderCell: row =>
                    <div className={styles.descCell}>
                        <div className={styles.firstRow}>{row.vehicleRegistrationNumber}</div>
                        {(!!row.vehicleBrandName || !!row.vehicleModelName) && <div className={styles.secondRow}>{row.vehicleBrandName + (' | ') + row.vehicleModelName}</div>}
                    </div>,
                onSearch: onTableFilter,
                searchField: 'driver_verifications.vehicle_registration_number',
                width: 'fill',
                hideOn: ['sm']
            },
            {
                name: <>
                    <div data-tip={t('drivers.list.average_kms_travel')}>
                        {t('drivers.list.average_kms')}
                    </div>
                    <ReactTooltip />
                </>,
                renderCell: row => <div>
                    <IconWithText
                        icon={GaugeIcon}
                        value={row.averageKms??0}
                        suffix={t('common.kms')}
                        isNumeric={true}
                    />
                </div>,
                onSearch: onTableFilter,
                searchField: 'average_kms',
                hideOn: ['sm', 'md', 'lg'],
                width: 'fill'
            },
            {
                name: t('drivers.list.refunds'),
                renderCell: row => <div className={styles.descCell}>
                    <div className={styles.firstRow}><MoneyFormat value={row.refunds ? row.refunds : 0} fixedDecimalScale={true} /></div>
                    <div className={styles.secondRow}>{row.refundsNotDone ? row.refundsNotDone : 0} {t('drivers.list.refunds_not_done')}</div>
                </div>,
                onSearch: onTableFilter,
                cellAlignment: 'right',
                columnCellAlignment: 'right',
                searchField: 'coalesce(uRefunds.total,0)',
                width: 'fill',
                hideOn: ['sm', 'md', 'lg']
            },
            {
                name: t('drivers.filter.fines'),
                renderCell: row => <div className={styles.descCell}>
                    <div className={styles.firstRow}>{row.fines ? row.fines : 0}</div>
                    <div className={styles.secondRow}>{t('drivers.list.total')} <MoneyFormat value={row.totalCostFines ? row.totalCostFines : 0} fixedDecimalScale={true} /></div>
                </div>,
                onSearch: onTableFilter,
                searchField: 'coalesce(uCharges.total,0)',
                width: 'fill',
                hideOn: ['sm', 'md']
            },
            {
                name: t('drivers.filter.accidents'),
                renderCell: row => <div className={styles.descCell}>
                    <div className={styles.firstRow}>{row.accidents ? row.accidents : 0}</div>
                </div>,
                onSearch: onTableFilter,
                searchField: 'coalesce(uAccidents.total,0)',
                width: 'fill',
                hideOn: ['sm', 'md', 'lg', 'xl']
            },
            {
                name: '',
                width: 'fill',
                cellAlignment: 'right',
                preventClick: true,
                renderCell: renderTableActionCell,
                cellStyle: { overflow: 'unset' },
                hideOn: ['sm', 'md', 'lg', 'xl']
            }
        ];
        return cols;
    }, [onTableFilter]);

    const navigateTo = (type?: string, id?: string) => {
        if (id) {
            history.push(`/drivers/${type}/${id}/user/list`);
        } else {
            history.push(`/drivers/${type}`);
        }
    }

    const onRowClick = (event: any, id?: string) => {
        if (id) {
            event.preventDefault();
            event.stopPropagation();

            filters.page = criteria.page;
            saveFiltersOnCache(filters);

            if (event.ctrlKey) {
                window.open(history.location.pathname + '/details/' + id, '_blank');
                return;
            }
            navigateTo('details', id);
        }
    }

    const onPageChange = (page: number) => {
        criteria.page = page;
        getData();
    }

    useEffect(() => {
        loadFilters();
    }, [criteria]);

    const loadFilters = () => {
        const cachedFilters = localStorage.getItem('DRIVERSFILTER');
        if (cachedFilters != null) { onChangeFilters(JSON.parse(cachedFilters), true) }
        else { getData() }
    }

    const saveFiltersOnCache = (f: Filters) => {
        setFilters(f);
        localStorage.setItem('DRIVERSFILTER', JSON.stringify(f));
    }

    const onChangeFilters = (f: Filters, isOnLoad?: boolean) => {
        updateTotalFilters(f);

        criteria.driverId = f.driverId;
        criteria.minRefunds = f.refunds && f.refunds[0];
        criteria.maxRefunds = f.refunds && f.refunds[1];
        criteria.minFines = f.fines && f.fines[0];
        criteria.maxFines = f.fines && f.fines[1];
        criteria.minAccidents = f.accidents && f.accidents[0];
        criteria.maxAccidents = f.accidents && f.accidents[1];
        criteria.vehicleId = f.vehicleId;
        criteria.startDate = f.startDate;
        criteria.endDate = f.endDate;

        if (isOnLoad && f.page) {
            criteria.page = f.page
        } else {
            criteria.page = 1;
            f.page = 1;
        }
        saveFiltersOnCache(f);
        getData();
    }

    const updateTotalFilters = (f: Filters) => {
        let total = 0;

        if (f.startDate || f.endDate) {
            total++;
        }
        if (f.driverId) {
            total++;
        }
        if (f.vehicleId) {
            total++;
        }
        if (f.fines) {// && (filters.fines[0] >= 0 || filters.fines[1] < 1000)) {
            total++;
        }
        if (f.accidents) {// && (filters.accidents[0] > 0 || filters.accidents[1] < 1000)) {
            total++;
        }
        if (f.refunds) {//&& (filters.refunds[0] >= 0 || filters.refunds[1] < 1000)) {
            total++;
        }

        setFiltersTotal(total);
    }

    return (
        <ScreenTitle title={t('drivers.title')}>
            <ScreenContainer>
                <ScreenHeader title={t('drivers.title')}>
                    <Popover
                        contentContainerClassName={styles.filtersPopoverContainer}
                        containerStyle={{ boxShadow: '0 4px 14px rgba(0, 0, 0, 0.1)', backgroundColor: '#fff' }}
                        positions={['bottom', 'left']}
                        align={'end'}
                        onClickOutside={() => updateTotalFilters(filters)}
                        content={setIsPopoverOpen => <DriversFilters
                            filters={filters}
                            onFilter={f => { setIsPopoverOpen(false); onChangeFilters(f) }}
                            onChange={f => updateTotalFilters(f)}
                        />}>
                        {(isPopoverOpen, setIsPopoverOpen) => (
                            <ScreenHeaderButton icon={FiltersIcon} onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
                                {filtersTotal > 0 &&
                                    <div className={styles.counterList}> <div className={styles.counterNumber}>{filtersTotal}</div> </div>
                                }
                            </ScreenHeaderButton>
                        )}
                    </Popover>
                    {hasUsersWritePolicy && <ScreenHeaderButton icon={AddIcon} onClick={() => navigateTo('new')} />}
                </ScreenHeader>

                <FilterList filters={[
                    {
                        value: filters.driverName,
                        label: t('common.driver'),
                        onRemove: () => { filters.driverId = filters.driverName = undefined; onChangeFilters(filters) }
                    },
                    {
                        value: filters.vehicleName,
                        label: t('drivers.list.vehicle'),
                        onRemove: () => { filters.vehicleId = filters.vehicleName = undefined; onChangeFilters(filters) }
                    },
                    {
                        value: filters.startDate && filters.endDate ? <>{t('common.range.from')} <DateFormat value={filters.startDate} /> {t('common.range.to').toLowerCase()} <DateFormat value={filters.endDate} /></>
                            : filters.startDate ? <>{t('common.range.from')} <DateFormat value={filters.startDate} /></>
                                : filters.endDate ? <>{t('common.range.to')} <DateFormat value={filters.endDate} /></> : undefined,
                        label: t('maintenances.expected_date'),
                        onRemove: () => { filters.startDate = filters.endDate = undefined; onChangeFilters(filters) }
                    },
                    {
                        value: !filters.refunds || !filters.refunds.length || !filters.refunds.find(x => x !== undefined) ? undefined :
                            filters.refunds.filter(i => i !== undefined).length === 2 ? <>{t('common.between')} <MoneyFormat value={filters.refunds[0]} /> {t('common.and')} <MoneyFormat value={filters.refunds[1]} /></>
                                : filters.refunds[0] !== undefined ? <>{t('common.above')} <MoneyFormat value={filters.refunds[0]} /></>
                                    : <>{t('common.below')} <MoneyFormat value={filters.refunds[1]} /></>,
                        label: t('drivers.list.refunds'),
                        onRemove: () => { filters.refunds = undefined; onChangeFilters(filters) }
                    },
                    {
                        value: !filters.fines || !filters.fines.length || !filters.fines.find(x => x !== undefined) ? undefined :
                            filters.fines.filter(i => i !== undefined).length === 2 ? <>{t('common.between')} <MoneyFormat value={filters.fines[0]} suffix={''} /> {t('common.and')} <MoneyFormat suffix={''} value={filters.fines[1]} /></>
                                : filters.fines[0] !== undefined ? <>{t('common.above')} <MoneyFormat value={filters.fines[0]} suffix={''} /></>
                                    : <>{t('common.below')} <MoneyFormat value={filters.fines[1]} suffix={''} /></>,
                        label: t('drivers.filter.fines'),
                        onRemove: () => { filters.fines = undefined; onChangeFilters(filters) }
                    },
                    {
                        value: !filters.accidents || !filters.accidents.length || !filters.accidents.find(x => x !== undefined) ? undefined :
                            filters.accidents.filter(i => i !== undefined).length === 2 ? <>{t('common.between')} <MoneyFormat value={filters.accidents[0]} suffix={''} /> {t('common.and')} <MoneyFormat suffix={''} value={filters.accidents[1]} /></>
                                : filters.accidents[0] !== undefined ? <>{t('common.above')} <MoneyFormat value={filters.accidents[0]} suffix={''} /></>
                                    : <>{t('common.below')} <MoneyFormat value={filters.accidents[1]} suffix={''} /></>,
                        label: t('drivers.filter.accidents'),
                        onRemove: () => { filters.accidents = undefined; onChangeFilters(filters) }
                    },
                ]} />

                {totals && <CounterBoxDriver totals={totals} />}

                <Box className={styles.box}>
                    <ListingTable
                        columns={tableColumns}
                        rows={drivers}
                        onRowClick={(row, _, event) => onRowClick(event, row.id)}
                        allowHover={true}
                        onHref={row => `drivers/details/${row.id}`}
                        initialSearch={{ colField: 'u.real_name', isOrderAsc: true }}
                    />
                    <PaginationWithInfo
                        itemName={t('drivers.list.drivers')}
                        currentPage={criteria.page}
                        pageItems={drivers.length}
                        totalItems={totalItems}
                        onChange={onPageChange}
                    />
                </Box>
                <QuestionYesNo
                    onNo={onCancelRemove}
                    onYes={onRemove}
                    isVisible={showRemoveModal}
                    message={t('common.messages.remove_record_with_ident', { name: itemToRemove?.name ?? '' })} />

            </ScreenContainer>
        </ScreenTitle>
    );
}

export default DriversScreen;
