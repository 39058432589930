import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Logger from 'common/services/Logger';
import { useToasts } from 'react-toast-notifications';
import DashboardService from 'api/dashboard/DashboardService';
import { LOGGER_LOG_TYPE } from 'Config';
import FiltersButton from 'screens/dashboard/filters/FiltersButton';
import ChartsBoxes from 'screens/dashboard/chartsBoxes/ChartsBoxes';
import { FiltersFormModel, TimePeriod, timePeriodToDates } from 'screens/dashboard/filters/FiltersView';
import { useParams } from 'react-router-dom';
import styles from './CarDashboardTab.module.scss';
import { CountersDashboardDto } from 'api/dashboard/dtos/CountersDashboardDto';
import { ExpensesDashboardDto } from 'api/dashboard/dtos/ExpensesDashboardDto';
import { ConsumesDashboardDto } from 'api/dashboard/dtos/ConsumesDashboardDto';
import { MonthlyCostDashboardDto } from 'api/dashboard/dtos/MonthlyCostDashboardDto';
import { NextMaintenancesDashboardDto } from 'api/dashboard/dtos/NextMaintenancesDashboardDto';
import { MonthlyRoutesDashboardDto } from 'api/dashboard/dtos/MonthlyRouteDashboardDto';

const defaultTimePeriod = TimePeriod.CURRENT_MONTH;
const [defaultDateFrom, defaultDateTo] = timePeriodToDates(defaultTimePeriod);


type Props = {
    hasTrackingDeviceId?: boolean;
    tourReRender: () => void;
}

const CarDashboardTab = ({ hasTrackingDeviceId, tourReRender }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const { vehicleId } = useParams<{ vehicleId: string }>();
    const [counters, setCounters] = useState<CountersDashboardDto | null>(null);
    const [expenses, setExpenses] = useState<ExpensesDashboardDto | null>(null);
    const [consumes, setConsumes] = useState<ConsumesDashboardDto | null>(null);
    const [nextMaintenances, setNextMaintenances] = useState<NextMaintenancesDashboardDto | null>(null);
    const [monthlyCost, setMonthlyCost] = useState<MonthlyCostDashboardDto | null>(null);
    const [monthlyRoute, setMonthlyRoutes] = useState<MonthlyRoutesDashboardDto | null>(null);
    const [refresh, setRefresh] = useState<number>(1);
    const [filters, setFilters] = useState<FiltersFormModel>({
        dateFrom: defaultDateFrom,
        dateTo: defaultDateTo,
        vehicleId,
        timePeriod: defaultTimePeriod,
        inactives: true
    });

    const [isLoadingCounters, setIsLoadingCounters] = useState<boolean>(false);
    const [isLoadingExpenses, setIsLoadingExpenses] = useState<boolean>(false);
    const [isLoadingConsumes, setIsLoadingConsumes] = useState<boolean>(false);
    const [isLoadingNextMaintenances, setIsLoadingNextMaintenances] = useState<boolean>(false);
    const [isLoadingMonthlyCost, setIsLoadingMonthlyCost] = useState<boolean>(false);
    const [isLoadingMonthlyRoute, setIsLoadingMonthlyRoute] = useState<boolean>(false);

    const getCounters = async () => {
        try {
            setIsLoadingCounters(true);
            const dashboardData = await DashboardService.getCounters({ ...filters, vehicleId: vehicleId });
            setCounters(dashboardData);
            setRefresh(refresh + 1);
        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Logger.error(LOGGER_LOG_TYPE.DASHBOARD, 'error getting dashboard data', error);
        } finally {
            setIsLoadingCounters(false);
            tourReRender();
        }
    };

    const getExpenses = async () => {
        try {
            setIsLoadingExpenses(true);
            const dashboardData = await DashboardService.getExpenses({ ...filters, vehicleId: vehicleId });
            setExpenses(dashboardData);
            setRefresh(refresh + 1);
        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Logger.error(LOGGER_LOG_TYPE.DASHBOARD, 'error getting dashboard data', error);
        } finally {
            setIsLoadingExpenses(false);
            tourReRender();
        }
    };

    const getConsumes = async () => {
        try {
            setIsLoadingConsumes(true);
            const dashboardData = await DashboardService.getConsumes({ ...filters, vehicleId: vehicleId });
            setConsumes(dashboardData);
            setRefresh(refresh + 1);
        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Logger.error(LOGGER_LOG_TYPE.DASHBOARD, 'error getting dashboard data', error);
        } finally {
            setIsLoadingConsumes(false);
            tourReRender();
        }
    };

    const getNextMaintenances = async () => {
        try {
            setIsLoadingNextMaintenances(true);
            const dashboardData = await DashboardService.getNextMaintenances({ ...filters, vehicleId: vehicleId });
            setNextMaintenances(dashboardData);
            setRefresh(refresh + 1);
        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Logger.error(LOGGER_LOG_TYPE.DASHBOARD, 'error getting dashboard data', error);
        } finally {
            setIsLoadingNextMaintenances(false);
            tourReRender();
        }
    };

    const getMonthlyCost = async () => {
        try {
            setIsLoadingMonthlyCost(true);
            // para não mostrar o mês anterior no gráfico
            if (filters.dateFrom) {
                const newDate = new Date(filters.dateFrom);
                const offset = new Date().getTimezoneOffset();
                const dateInLocalTime = new Date(newDate.getTime() - (offset * 60000));

                filters.dateFrom = dateInLocalTime;
            }
            const dashboardData = await DashboardService.getMonthlyCost({ ...filters, vehicleId: vehicleId });
            setMonthlyCost(dashboardData);
            setRefresh(refresh + 1);
        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Logger.error(LOGGER_LOG_TYPE.DASHBOARD, 'error getting dashboard data', error);
        } finally {
            setIsLoadingMonthlyCost(false);
            tourReRender();
        }
    };

    const getMonthlyRoutes = async () => {
        try {
            setIsLoadingMonthlyRoute(true);            
            if (hasTrackingDeviceId != null ? Boolean(hasTrackingDeviceId) : false) {
                const dashboardData = await DashboardService.getMonthlyRoutes({ ...filters, vehicleId: vehicleId });                
                setMonthlyRoutes({ ...dashboardData, hasTrackingDeviceId: hasTrackingDeviceId != null ? Boolean(hasTrackingDeviceId) : null });
            } else {
                setMonthlyRoutes(null);
            }
            
            setRefresh(refresh + 1);
        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Logger.error(LOGGER_LOG_TYPE.DASHBOARD, 'error getting dashboard data', error);
        } finally {
            setIsLoadingMonthlyRoute(false);
            tourReRender();
        }
    };

    useEffect(() => {
        getCounters();
        getExpenses();
        getConsumes();
        getNextMaintenances();
        getMonthlyCost();
        getMonthlyRoutes();
    }, [filters]);

    return (
        <div id="second-step">
            <div className={styles.headerContainer}>
                <FiltersButton
                    onFilter={setFilters}
                    filters={filters}
                    canSelectVehicle={false}
                />
            </div>
            <ChartsBoxes counters={counters}
                expenses={expenses}
                consumes={consumes}
                nextMaintenances={nextMaintenances}
                monthlyCost={monthlyCost}
                monthlyRoute={monthlyRoute}
                key={refresh}
                isLoadingCounters={isLoadingCounters}
                isLoadingExpenses={isLoadingExpenses}
                isLoadingConsumes={isLoadingConsumes}
                isLoadingNextMaintenances={isLoadingNextMaintenances}
                isLoadingMonthlyCost={isLoadingMonthlyCost}
                isLoadingMonthlyRoute={isLoadingMonthlyRoute}
                showMonthlyRoute={hasTrackingDeviceId != null ? Boolean(hasTrackingDeviceId) : false} />
        </div>
    );
}

export default CarDashboardTab;