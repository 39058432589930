import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './AlertsFilters.module.scss';
import Button from 'common/components/button/Button';
import FormItem from 'common/components/formItem/FormItem';
import Label from 'common/components/label/Label';
import { Col, Row } from 'react-flexbox-grid';
import { SelectBooleanLabel, SelectValueLabel } from 'common/types/SelectValueLabel';
import Select from 'common/components/select/Select';
import { Controller, useForm } from 'react-hook-form';
import VehiclesService from 'api/vehicles/VehiclesService';
import Utils from 'common/services/Utils';
import { useParams } from 'react-router-dom';
import { AlertType } from 'api/alerts/enums/AlertType';
import { AnnotationStatusEnum } from 'api/carVerification/enums/AnnotationEnum';
import { UsersSelectItemDto } from 'api/users/models/UserDto';
import UsersService from 'api/users/UsersService';
import RangeInputPicker from 'common/components/rangeInputPicker/RangeInputPicker';
import moment from 'moment';
import Loading from 'common/services/Loading';
import Logger from 'common/services/Logger';
import { LOGGER_LOG_TYPE } from 'Config';
import { useToasts } from 'react-toast-notifications';

export interface Filters {
    vehicleId?: string;
    vehicleName?: string;
    type?: AlertType;
    typeName?: string;
    startDate?: Date | null;
    endDate?: Date | null;
    responsibleId?: string;
    responsibleName?: string;
    reading?: boolean | undefined;
    readingName?: string;
    page?: number;
}

type Props = {
    filters: Filters;
    onChange: (filters: Filters) => void;
    onFilter: (filters: Filters) => void;
    hideVehicleFilter?: boolean;
    isDriver?: boolean;
}

const AlertsFilters: React.FC<Props> = ({ filters, isDriver, onChange, onFilter, hideVehicleFilter }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts(); 
    const form = useForm<Filters>({ shouldUnregister: false, shouldFocusError: true, defaultValues: filters });
    const { setValue, watch, getValues, control } = form;
    const [vehiclesOptions, setVehicles] = useState<SelectValueLabel[]>([]);
    const [typesOptions, setTypesOptions] = useState<SelectValueLabel[]>([]);
    const [statusOptions, setStatusOptions] = useState<SelectValueLabel[]>([]);
    const [responsiblesOptions, setResponsibles] = useState<SelectValueLabel[]>([]);
    const [readingOptions, setReadingOptions] = useState<SelectBooleanLabel[]>([]);
    const { driverId } = useParams<{ driverId: string }>();

    const getData = async () => {
        try {
            Loading.show();

            if (!hideVehicleFilter) {
                const vehicles = await VehiclesService.catalog();
                setVehicles(vehicles);
            }
            setTypesOptions(
                Object.keys(AlertType).map(key => {
                    const e = (AlertType as any)[key];
                    return ({ label: t(('alerts.types.' + e as any)), value: e });
                })
            );
            setStatusOptions(
                Object.keys(AnnotationStatusEnum).map(key => {
                    const e = (AnnotationStatusEnum as any)[key];
                    return ({ label: t(('alerts.annotation_status.' + e as any).toLowerCase()), value: e });
                })
            );
            setReadingOptions([
                { label: t('alerts.list.all'), value: undefined },
                { label: t('alerts.list.reads'), value: true },
                { label: t('alerts.list.not_reads'), value: false }
            ]);

            const ro = await UsersService.getAllForCompany();
            setResponsibles((ro || []).map((x: UsersSelectItemDto) => ({ value: x.id || '', label: x.realName || '' })));
            
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.ALERTS, `Couldn't get alerts list filters`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        }
        finally{
            Loading.hide();
        }
    }

    useEffect(() => {
        getData();
    }, []);

    const clearFilters = () => {
        form.reset({
            startDate: undefined,
            endDate: undefined,
            vehicleId: undefined,
            vehicleName: undefined,
            type: undefined,
            typeName: undefined,
            responsibleId: isDriver && !Utils.isStringNullOrEmpty(driverId) ? driverId : undefined,
            responsibleName: undefined,
            reading: undefined,
            readingName: undefined
        });
        onInputChange();
        onSubmit(form.getValues());
    }

    const onSubmit = (f: Filters) => {
        onFilter(f);
    }

    const onInputChange = () => {
        onChange(form.getValues());
    }

    return (
        <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className={styles.formContent}>
                <div>
                    <FormItem>
                        <Row>
                            <Col xs={12}>
                                <RangeInputPicker
                                    label={t('alerts.list.filter_date')}
                                    onChange={(startValue: any, endValue: any) => {
                                        setValue('startDate', startValue ? moment(startValue).toDate() : undefined);
                                        setValue('endDate', endValue ? moment(endValue).toDate() : undefined);
                                        onInputChange();
                                    }}
                                    defaultStartValue={getValues('startDate')}
                                    defaultEndValue={getValues('endDate')}
                                    isDatePicker
                                />
                            </Col>
                            {!hideVehicleFilter && <Col xs={6}>
                                <FormItem>
                                    <Label className={styles.bold}>{t('alerts.list.vehicle')}</Label>
                                    <Controller
                                        render={({ onChange: onSelectChange, value }) => {
                                            return (
                                                <Select
                                                    options={vehiclesOptions}
                                                    isClearable
                                                    placeholder={t('alerts.list.vehicle')}
                                                    onChange={(data: SelectValueLabel) => {
                                                        onSelectChange(data?.value);
                                                        form.setValue('vehicleName', data?.label);
                                                        onInputChange();
                                                    }}
                                                    value={vehiclesOptions.find(x => x.value === value) ? { label: vehiclesOptions.find(x => x.value === value)?.label ?? '', value: value } : null}
                                                />
                                            );
                                        }}
                                        control={form.control}
                                        name="vehicleId"
                                        defaultValue={form.getValues('vehicleId')} />
                                </FormItem>
                            </Col>}
                            <Col xs={6}>
                                <FormItem>
                                    <Label className={styles.bold}>{t('alerts.list.alert_receiver')}</Label>
                                    <Controller
                                        render={({ onChange, value }) => {
                                            return (
                                                <Select
                                                    options={responsiblesOptions}
                                                    isClearable
                                                    placeholder={t('alerts.list.alert_receiver')}
                                                    onChange={(data: SelectValueLabel) => {
                                                        setValue('responsibleName', responsiblesOptions.find(x => x.value == data?.value)?.label);
                                                        onChange(data?.value);
                                                        onInputChange();
                                                    }}
                                                    value={responsiblesOptions.find(x => x.value === value) ? { label: responsiblesOptions.find(x => x.value === value)?.label ?? '', value: value } : null}
                                                />
                                            );
                                        }}
                                        control={control}
                                        name="responsibleId"
                                        defaultValue={getValues('responsibleId')} />
                                </FormItem>
                            </Col>

                            <Col xs={12} lg={6}>
                                <FormItem>
                                    <Label className={styles.bold}>{t('alerts.list.type')}</Label>
                                    <Controller
                                        render={({ onChange: onSelectChange, value }) => {
                                            return (
                                                <Select
                                                    options={typesOptions}
                                                    isClearable
                                                    placeholder={t('alerts.list.type')}
                                                    onChange={(data: SelectValueLabel) => {
                                                        onSelectChange(data?.value);
                                                        form.setValue('typeName', data?.label);
                                                        onInputChange();
                                                    }}
                                                    value={typesOptions.find(x => x.value === value) ? { label: typesOptions.find(x => x.value === value)?.label ?? '', value: value } : null}
                                                />
                                            );
                                        }}
                                        control={form.control}
                                        name="type"
                                        defaultValue={form.getValues('type')} />
                                </FormItem>
                            </Col>
                            <Col xs={12} lg={6}>
                                <FormItem>
                                    <Label className={styles.bold}>{t('alerts.list.reading')}</Label>
                                    <Controller
                                        render={({ onChange: onSelectChange, value }) => {
                                            return (
                                                <Select
                                                    options={readingOptions}
                                                    isClearable
                                                    placeholder={t('alerts.list.reading')}
                                                    onChange={(data: SelectBooleanLabel) => {
                                                        onSelectChange(data?.value);
                                                        form.setValue('readingName', data?.label);
                                                        onInputChange();
                                                    }}
                                                    value={readingOptions.find(x => x.value === value) ? { label: readingOptions.find(x => x.value === value)?.label ?? '', value: value } : null}
                                                />
                                            );
                                        }}
                                        control={form.control}
                                        name="reading"
                                        defaultValue={form.getValues('reading')} />
                                </FormItem>
                            </Col>
                        </Row>
                    </FormItem>
                </div>
                <div className={styles.buttonsFooter}>
                    <FormItem>
                        <Button
                            text={t('common.remove')}
                            size={'normal'}
                            preset={'secondary'}
                            onClick={clearFilters}
                            type='reset' />
                        <Button
                            type='submit'
                            text={t('common.apply')}
                            size={'normal'}
                        />
                    </FormItem>
                </div>
            </div>
        </form>
    );
}

export default memo(AlertsFilters);