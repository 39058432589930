import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { VehicleSegmentDto } from 'api/vehicleSegments/models/VehicleSegmentDto';
import Loading from 'common/services/Loading';
import VehicleSegmentsService from 'api/vehicleSegments/VehicleSegmentsService';
import Logger from 'common/services/Logger';
import Button from 'common/components/button/Button';
import styles from './VehicleSegmentsDetails.module.scss';
import InputError from 'common/components/inputError/InputError';
import Input from 'common/components/input/Input';
import Label from 'common/components/label/Label';
import FormItem from 'common/components/formItem/FormItem';
import { useForm } from 'react-hook-form';
import { DEFAULT_INPUT_RULES_WITH_REQUIRED, LOGGER_LOG_TYPE } from 'Config';
import GreyArrow from 'assets/svg/Arrow-grey.svg';
import { useEffect, useState } from 'react';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';

type Props = {
};

const VehicleSegmentsDetails: React.FC<Props> = ({ }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();

    const history = useHistory();
    // eslint-disable-next-line
    let { type, itemId } = useParams<{ type: string, itemId: string }>();

    const { register, handleSubmit, errors, reset } = useForm<VehicleSegmentDto>();

    const [vehicleSegment, setVehicleSegment] = useState<VehicleSegmentDto | null>(null);

    const [itemToRemove, setItemToRemove] = useState<VehicleSegmentDto>();
    const [dialogDeleteItemIsOpen, setDialogDeleteItemIsOpen] = React.useState(false);

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasVehicleSegmentsWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_VEHICLE_SEGMENTS_WRITE']);

    const onSubmit = async (formData: VehicleSegmentDto) => {
        try {
            if (!hasVehicleSegmentsWritePolicy) { return; }

            Loading.show();

            if (vehicleSegment != null) {
                vehicleSegment.name = formData.name;
                await VehicleSegmentsService.update(vehicleSegment);
            } else {
                itemId = await VehicleSegmentsService.create(formData);
                getData();
            }

            Loading.hide();
            addToast(t('common.messages.record_save_success'), {
                appearance: 'success',
            });

            history.push(`/settings/segments/details/${itemId}`);
        } catch (error) {
            Loading.hide();

            if (error?.response?.status === 409) {
                addToast(t('common.messages.name_already_exists'), { appearance: 'warning' });
                return;
            }

            addToast(t('common.messages.record_save_error'), {
                appearance: 'error',
            });

            Logger.error(
                LOGGER_LOG_TYPE.REQUEST,
                `Couldn't create or update vehicle segments`,
                error
            );
        }
    };

    const getData = async () => {
        if (itemId == null) {
            return;
        }

        Loading.show();
        try {
            const result = await VehicleSegmentsService.getById(itemId);
            setVehicleSegment(result);
            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't get vehicle segment`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    }

    const showRemoveItemDialog = (item: VehicleSegmentDto) => {
        setItemToRemove(item);
        setDialogDeleteItemIsOpen(true);
    };

    const removeItem = async () => {
        setDialogDeleteItemIsOpen(false);
        if (!!itemToRemove) {
            try {
                Loading.show();
                await VehicleSegmentsService.remove(itemToRemove);
                addToast(t('common.messages.record_delete_success'), { appearance: 'success' });
                history.push(`/settings/segments/list`);
                Loading.hide();
            } catch (error) {
                addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
                Loading.hide();
            }
        }
    };

    useEffect(() => {
        getData();
    }, []);

    const onGoBack = () => {
        if(vehicleSegment){
            reset(vehicleSegment);
        }
        history.goBack();
    }

    return (
        <div>
            <div className={styles.pageNameContainer}>
                <div className={styles.pageNameContent} onClick={() => history.push(`/settings/segments/list`)} >
                    <img className={styles.pageNameContentImage} src={GreyArrow} />
                    <span className={styles.pageNameContentText}>
                        {t('settings.vehicle_segments')}
                    </span>
                </div>
            </div>

            <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                <FormItem>
                    <Label className={styles.label}>
                        {t('vehicle_segments.name')} {type !== 'details' ? '*' : ''}
                    </Label>
                    <Input
                        name='name'
                        placeholder={t('vehicle_segments.name')}
                        defaultValue={vehicleSegment?.name}
                        ref={register({ ...DEFAULT_INPUT_RULES_WITH_REQUIRED })}
                        disabled={type === 'details'}
                    />
                    <InputError error={errors.name} maxLength={250} />
                </FormItem>

                <div className={styles.buttonContainer}>
                    <Button preset={'secondary'} type='button' text={t('common.cancel')} onClick={() => onGoBack()} />
                    {type === 'details' && hasVehicleSegmentsWritePolicy &&
                        <Button
                            type="button"
                            text={t('common.remove')}
                            preset={'danger'}
                            onClick={() => showRemoveItemDialog({ id: vehicleSegment?.id } as VehicleSegmentDto)} />
                    }
                    {type === 'details' && hasVehicleSegmentsWritePolicy && <Button type='button' text={t('common.edit')} onClick={() => history.push(`/settings/segments/edit/${itemId}`)} />}
                    {type !== 'details' && hasVehicleSegmentsWritePolicy && <Button type='submit' text={t('common.save')} />}

                    <QuestionYesNo message={t('common.messages.remove_vehicle_segment', { vehicleSegment: vehicleSegment?.name ?? '' })}
                        isVisible={dialogDeleteItemIsOpen}
                        onYes={() => removeItem()}
                        onNo={() => setDialogDeleteItemIsOpen(false)} />
                </div>
            </form>
        </div>
    );
};

export default VehicleSegmentsDetails;
