type Props = {
    children: any;
    href: any
}

const HReafWrap: React.FC<Props> = ({ children, href }: Props) => {
    return href ? <a href={href} style={{ textDecoration:'none',color:'inherit' }}>{children}</a> : children;
}

export default HReafWrap;
