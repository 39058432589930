import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './CarVerification.module.scss';
import GreyArrow from 'assets/svg/Arrow-grey.svg';
import { RemoveItemViewModel } from 'api/carVerification/models/CarVerificationDto';
import { useToasts } from 'react-toast-notifications';
import Loading from 'common/services/Loading';
import Button from 'common/components/button/Button';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import { useForm } from 'react-hook-form';
import UserService from 'api/users/UsersService'
import CarVerificationService from 'api/carVerification/CarVerificationService';
import CustomFile from 'common/models/CustomFile';
import VehiclesService from 'api/vehicles/VehiclesService';
import DiagnosticTypesService from 'api/diagnosticTypes/DiagnosticTypesService';
import { useDispatch, useSelector } from 'react-redux';
import { LOGGER_LOG_TYPE } from 'Config';
import { updateTotals } from 'store/alerts/action';
import Logger from 'common/services/Logger';
import InformationForm from './informationForm/InformationForm';
import AnnotationsForm from './annotationsForm/AnnotationsForm';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import { MediaItemDto } from 'api/common/models/MediaItemDto';
import { VerificationViewModel } from 'api/carVerification/models/VerificationViewModel';
import VehicleMaintenancesService from 'api/vehicleMaintenances/VehicleMaintenancesService';
import AddMaintenanceModal from './addMaintenanceModal/AddMaintenanceModal';
import { UpdateAnnotationsViewModel } from 'api/carVerification/models/AnnotationsViewModel';
import { AnnotationStatusEnum } from 'api/carVerification/enums/AnnotationEnum';
import DriversService from 'api/drivers/DriversService';
import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';
import { Reducers } from 'store/types';
import { VehicleDto } from 'api/vehicles/models/VehicleDto';
import { VerificationDto } from 'api/carVerification/models/VerificationDto';
import { useHistory } from 'react-router-dom';
import VerificationExistModal from './verificationExistModal/VerificationExistModal';
import { VerificationOverlapCriteria } from 'api/carVerification/models/CarVerificationSearchCriteria';
import { VerificationType } from 'api/carVerification/enums/VerificationType';
import Utils from 'common/services/Utils';

export type VerificationOverlap = {
    vehicleId: string;
    vehicleRegistrationNumber: string;
    verificationId: string;
    responsableId: string;
    driverId: string;
};

type Props = {
    goToEdit: (id: string) => void;
    genericPage?: boolean;
    type: 'details' | 'new' | 'edit' | 'list';
    vehicleId: string | null;
    verificationId: string | null; 
    editable: boolean;
};

const CarVerification = ({ goToEdit, genericPage = false, type, vehicleId: propVehicleId, verificationId, editable }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const dispatch = useDispatch();
    const history = useHistory();
    const isDetails = type === 'details';
    const form = useForm<VerificationViewModel>({ shouldUnregister: false, defaultValues: { annotations: [] } });
    const { setValue, reset } = form;
    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
    const [annotationTypes, setAnnotationTypes] = useState<SelectValueLabel[]>([]);
    const [responsables, setResponsables] = useState<SelectValueLabel[]>([]);
    const [drivers, setDrivers] = useState<SelectValueLabel[]>([]);
    const [maintenances, setMaintenances] = useState<SelectValueLabel[]>([]);
    const [vehicles, setVehicles] = useState<SelectValueLabel[]>([]);
    const [formReady, setFormReady] = useState(false);
    const [mediasIdsToRemove, setMediasIdsToRemove] = useState<string[]>([]);
    const [annotationsIdsToRemove, setAnnotationsIdsToRemove] = useState<string[]>([]);
    const [showAddMaintenanceModal, setShowAddMaintenanceModal] = useState<boolean>(false);
    const addMaintenanceAnnotationIndex = useRef<number | null>(null);
    const [hasAnyAnnotationDone, setHasAnyAnnotationDone] = useState(false);
    const [detailsFormTouched, setDetailsFormTouched] = useState(false);
    const [vehicleId, setVehicleId] = useState(propVehicleId);
    const [vehicle, setVehicle] = useState<VehicleDto>();
    //const [showKmWarning, setShowKmWarning] = useState<boolean>(false);
    const [verification, setVerification] = useState<VerificationDto | null>(null);
    const [disableButtonSubmit, setDisableButtonSubmit] = useState(false);

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasVerificationsWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_VERIFICATIONS_WRITE']);

    const [isVerificationExistModalOpen, setIsVerificationExistModalOpen] = useState<boolean>(false);
    const [verificationOverlap, setVerificationOverlap] = useState<VerificationOverlap | null>();

    const criteriaOverlap: VerificationOverlapCriteria = {
        companyId: userProfile?.companyId,
        vehicleId: vehicleId ?? undefined,
        driverId: (!verification || !verification.driverId) && userProfile ? userProfile.id : verification?.driverId ? verification.driverId : undefined,
        excludeItem: undefined
    };

    const goBack = () => history.goBack();

    const findOverlaps = async (excludeGuid: string | undefined = undefined) => {
        try {
            //Loading.show();
            criteriaOverlap.excludeItem = excludeGuid;
            await CarVerificationService.findOverlaps(criteriaOverlap);
            //Loading.hide();
            return true;
        } catch (error: any) {
            if (
                error?.response?.status === 409 &&
                error.response?.data?.error &&
                error.response?.data?.error === 'ERROR_VERIFICATION_OVERLAP'
            ) {
                setVerificationOverlap({
                    vehicleId: error.response?.data.vehicleId,
                    verificationId: error.response?.data.verificationId,
                    vehicleRegistrationNumber: error.response?.data.vehicleRegistrationNumber,
                    responsableId: error.response?.data.responsableId,
                    driverId: error.response?.data.driverId
                });
                setIsVerificationExistModalOpen(true);
                Loading.hide();
            } else {
                Logger.error(LOGGER_LOG_TYPE.VERIFICATIONS, `Couldn't get verification overlaps`, error);
                addToast(t('common.messages.error_load_info'), { appearance: 'error' });
                Loading.hide();
            }
        }
        return false;
    };

    const getData = async () => {
        try {
            Loading.show();

            setFormReady(false);
            setDetailsFormTouched(false);

            const [responsableList, annotationTypeList, driversList] = await Promise.all([
                UserService.catalog(),
                DiagnosticTypesService.catalog(),
                DriversService.catalog()
            ]);

            setResponsables(responsableList);
            setAnnotationTypes(annotationTypeList);
            setDrivers(driversList);

            let vehicleIdVerified = vehicleId ?? '';
            if (verificationId) {
                const result = await CarVerificationService.getById(verificationId);
                result.verificationDate = new Date(result.verificationDate);
                result.verificationFinishDate = !!result.verificationFinishDate ? new Date(result.verificationFinishDate) : result.verificationFinishDate;
                result.annotations = (result.annotations || []).map(annotation => ({
                    ...annotation,
                    doneDate: annotation.doneDate ? new Date(annotation.doneDate) : null,
                    annotationDate: annotation.annotationDate ? new Date(annotation.annotationDate) : null,
                    predictedDateEndImmobilization: annotation.predictedDateEndImmobilization ? new Date(annotation.predictedDateEndImmobilization) : null
                }));

                const maintenancesList = await VehicleMaintenancesService.catalogWithDate({ vehicleId: result.vehicleId });
                setMaintenances(maintenancesList);

                const anyAnnotationsDone = Boolean(result.annotations.find(a => a.status === AnnotationStatusEnum.DONE));
                if (anyAnnotationsDone && type === 'edit') {
                    Loading.hide();
                    goBack();
                    return;
                }
                setHasAnyAnnotationDone(anyAnnotationsDone);

                vehicleIdVerified = !vehicleIdVerified ? (result.vehicleId ?? '') : vehicleIdVerified;
                setVehicleId(result.vehicleId);

                if (type === 'edit') {
                    setDisableButtonSubmit(false);
                }

                form.reset(result);
                setVerification(result);
            }
            else {

                setValue('verificationDate', new Date());
                setValue('kilometers', vehicle?.currentKilometers);
            }

            if (genericPage) {
                const vehicleList = await VehiclesService.catalog();
                setVehicles(vehicleList);
            }
            
            if (!Utils.isStringNullOrEmpty(vehicleIdVerified)) {
                const _vehicle = await VehiclesService.getById(vehicleIdVerified!);
                setVehicle(_vehicle);
            }            

            setFormReady(true);

            Loading.hide();
        } catch (error: any) {
            Logger.error(LOGGER_LOG_TYPE.VERIFICATIONS, `Couldn't get verification informations`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    }

    const refreshMaintenances = async () => {
        try {
            Loading.show();

            const formVehicleId = form.getValues('vehicleId');
            const maintenanceList = await VehicleMaintenancesService.catalogWithDate({ vehicleId: formVehicleId });
            setMaintenances(maintenanceList);

            Loading.hide();
        } catch (error) {
            Loading.hide();
            Logger.error(LOGGER_LOG_TYPE.VERIFICATIONS, `Couldn't get verification informations`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        }
    };

    const onSubmit = async (model: VerificationViewModel) => {
        Loading.show();
       
        //Validação de kms no primeiro clique
        /*if (!showKmWarning && model.kilometers < (vehicle?.currentKilometers || 0)) {
            setShowKmWarning(true);
            addToast(t('car_verification.warning_km'), { appearance: 'warning' });
            return;
        }*/

        if (model.type == VerificationType.TRAVEL && model.kilometersFinish && model.kilometers > model.kilometersFinish) {
            addToast(t('car_verification.warning_km_start_and_finish'), { appearance: 'warning' });
            return;
        }

        setDisableButtonSubmit(true);
        try {
            // Update annotations
            if (model.id && isDetails) {
                const tempModel: UpdateAnnotationsViewModel = {
                    verificationId: model.id,
                    annotations: model.annotations.map(annotation => ({
                        annotationId: annotation.id || '',
                        annotationStatus: annotation.status ?? AnnotationStatusEnum.WAITING.toString(),
                        maintenanceIds: annotation.maintenanceIds,
                        doneDate: annotation.doneDate,
                    })),
                };
                await CarVerificationService.updateAnnotations(tempModel);
                await getData();
                setDisableButtonSubmit(false);
            } else {
                const tempModel = { ...model, mediasIdsToRemove, annotationsIdsToRemove };
                const medias: CustomFile[] = [];

                for (let i = 0; i < tempModel.annotations.length; i++) {
                    tempModel.annotations[i].index = i;

                    for (const media of tempModel.annotations[i].medias) {
                        if (media.tempFile) {
                            medias.push(media.tempFile);

                            media.tempFile = null;
                            media.url = '';
                        }
                    }
                }

                if (!genericPage && vehicleId) {
                    tempModel.vehicleId = vehicleId;
                }

                criteriaOverlap.vehicleId = tempModel.vehicleId ?? undefined;
                criteriaOverlap.driverId = tempModel.driverId ?? undefined;
                
                let canSave = false;
                if(form.getValues('type') == VerificationType.TRAVEL){
                    canSave = await findOverlaps(model.id);
                } else {
                    canSave = true;
                }
                
                if(canSave)
                {
                    // Update
                    if (model.id) {
                        await CarVerificationService.update(tempModel, medias);
                    }
                    // Create
                    else {
                        await CarVerificationService.create(tempModel, medias);
                    }
                    Loading.hide();
                    goBack()
                    dispatch(updateTotals());
                    addToast(t('common.messages.record_save_success'), { appearance: 'success' });
                }else{
                    Loading.hide();
                    setDisableButtonSubmit(false);
                }
            }
            
        } catch (error) {
            if (error?.response?.status === 409 && error.response?.data?.error && error.response?.data?.error == 'ERROR_VERIFICATION_OVERLAP') {
                addToast(t('car_verification.verification.ERROR_VERIFICATION_OVERLAP'), { appearance: 'warning' });
            } else {
                addToast(t('common.messages.record_save_error'), { appearance: 'error' });
            }
            Logger.error(LOGGER_LOG_TYPE.VERIFICATIONS, `Couldn't save verification informations`, error);
            setDisableButtonSubmit(false);
            Loading.hide();
        }
    };

    const removeItem = async () => {
        if (!verificationId) {
            return;
        }

        const model: RemoveItemViewModel = { id: verificationId };

        try {
            setShowRemoveModal(false);
            Loading.show();
            await CarVerificationService.remove(model);
            dispatch(updateTotals());
            Loading.hide();
            goBack();
        } catch (err) {
            Logger.error(LOGGER_LOG_TYPE.VERIFICATIONS, `Couldn't delete verification`, err);
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
            Loading.hide();
        }
    }

    const onError = async () => {
        addToast(t('common.errors.required_fields_empty'), { appearance: 'warning' });
    };

    const onRemoveMedia = (media: MediaItemDto) => {
        if (media.id) {
            setMediasIdsToRemove([...mediasIdsToRemove, media.id]);
        }
    }

    const onRemoveAnnotation = (id: string) => {
        if (id) {
            setAnnotationsIdsToRemove([...annotationsIdsToRemove, id]);
        }
    }

    const onClickAddMaintenance = (annotationIndex: number) => {
        addMaintenanceAnnotationIndex.current = annotationIndex;
        setShowAddMaintenanceModal(true);
    }

    const onSaveMaintenance = async (id: string) => {
        setShowAddMaintenanceModal(false);

        if (addMaintenanceAnnotationIndex.current !== null) {
            const name = `annotations[${addMaintenanceAnnotationIndex.current}].maintenanceIds`;
            form.setValue(name, [...form.getValues<string, string[]>(name), id]);
        }
        addMaintenanceAnnotationIndex.current = null;

        await refreshMaintenances();
    }

    const onCancelMaintenance = () => {
        setShowAddMaintenanceModal(false);
        addMaintenanceAnnotationIndex.current = null;
    }

    const goToVerification = (vehicleId?: string, verificationId?: string) => {
        history.push(`/vehicles/details/${vehicleId}/verifications/edit/${verificationId}`);
    }

    useEffect(() => {
        getData();
    }, [type, propVehicleId]);

    return (
        <div className={styles.rootContainer}>
            {<div className={styles.pageNameContainer}>
                <div className={styles.pageNameContent} onClick={() => { if (verification) reset(verification); goBack(); }} >
                    <img className={styles.pageNameContentImage} src={GreyArrow} />
                    <span className={styles.pageNameContentText}>
                        {type === 'new' && t('car_verification.titles.new')}
                        {type === 'details' && t('car_verification.titles.details')}
                        {type === 'edit' && t('car_verification.titles.edit')}
                        
                        {(Boolean(verification) && !verification?.active) &&
                                                <span className={styles.messageDeleted}>{' - ' + t('car_verification.verification_deleted')}</span>
                                            }
                    </span>
                    
                </div>
            </div>}

            {formReady && <form className={styles.form} onSubmit={form.handleSubmit(onSubmit, onError)}>

                <InformationForm
                    form={form}
                    genericPage={genericPage}
                    isDetails={isDetails}
                    type={type}
                    responsables={responsables}
                    drivers={drivers}
                    vehicles={vehicles}
                    vehicle={vehicle}
                    findOverlaps={findOverlaps}
                />

                <AnnotationsForm
                    form={form}
                    isDetails={isDetails}
                    annotationTypes={annotationTypes}
                    maintenances={maintenances}
                    onRemoveMedia={onRemoveMedia}
                    onRemoveAnnotation={onRemoveAnnotation}
                    onClickAddMaintenance={onClickAddMaintenance}
                    onTouch={setDetailsFormTouched}
                />

                <div className={styles.buttonContainer}>
                    <Button preset={'secondary'}
                        type='button'
                        text={t('common.cancel')}
                        onClick={() => { if (verification) reset(verification); goBack(); }}
                    />
                        {(isDetails && !hasAnyAnnotationDone) && hasVerificationsWritePolicy &&  editable && verification?.active &&
                        <Button
                            type="button"
                            text={t('common.remove')}
                            preset={'danger'}
                            onClick={() => setShowRemoveModal(true)}
                        />
                    }
                        {(isDetails && verificationId && !hasAnyAnnotationDone) && hasVerificationsWritePolicy && editable && verification?.active &&
                        <Button
                            type='button'
                            text={t('common.edit')}
                            onClick={() => goToEdit(verificationId)}
                        />
                    }
                    {(!isDetails || (isDetails && detailsFormTouched)) && hasVerificationsWritePolicy &&
                        <Button
                            type='submit'
                            preset={'success'}
                            text={t('common.save')}
                            disabled={disableButtonSubmit}
                        />
                    }
                </div>

            </form>}

            <QuestionYesNo message={t('common.messages.remove_record')}
                isVisible={showRemoveModal}
                onYes={() => removeItem()}
                onNo={() => { setShowRemoveModal(false); }}
            />

            {showAddMaintenanceModal && <AddMaintenanceModal
                isVisible={showAddMaintenanceModal}
                onSave={onSaveMaintenance}
                onCancel={onCancelMaintenance}
                vehicleId={vehicleId}
            />}

            <VerificationExistModal
                isVisible={isVerificationExistModalOpen}
                item={verificationOverlap}
                onFinish={() => {
                    setIsVerificationExistModalOpen(false);
                    goToVerification(verificationOverlap?.vehicleId, verificationOverlap?.verificationId);
                }}
                onCancel={() => {
                    setIsVerificationExistModalOpen(false);
                }}
            />

        </div>
    );
}

export default CarVerification;
